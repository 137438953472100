import type { Media } from '@/declarations/media';
import useModal from '@/hooks/useModal';
import {
  createWABAUploadSession,
  getTraceableMedias,
  uploadMedia,
  uploadTempMedia,
} from '@/services/luluchat/media';
import { useRequest } from 'umi';
import type { API } from './typings';

export default () => {
  const {
    openModal: openMediaLibraryModal,
    isModalVisible: isMediaLibraryModalVisible,
    closeModal: closeMediaLibraryModal,
  } = useModal();

  const {
    data: media = {} as Media,
    run: fetchUploadMedia,
    loading: isLoadingFetchUploadMedia,
  } = useRequest(uploadMedia, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const {
    data: tempMedia = {} as Media,
    run: fetchUploadTempMedia,
    loading: isLoadingFetchUploadTempMedia,
  } = useRequest(uploadTempMedia, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchCreateWABAUploadSession, loading: isLoadingFetchCreateWABAUploadSession } =
    useRequest(createWABAUploadSession, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const {
    data: traceableMedias,
    run: fetchGetTraceableMedias,
    loading: isLoadingFetchGetTraceableMedias,
  } = useRequest(getTraceableMedias, {
    manual: true,
    formatResult(res: API.GetTraceableMediasResult) {
      return res;
    },
  });

  return {
    traceableMedias,
    fetchGetTraceableMedias,
    isLoadingFetchGetTraceableMedias,
    media,
    fetchUploadMedia,
    isLoadingFetchUploadMedia,
    tempMedia,
    fetchUploadTempMedia,
    isLoadingFetchUploadTempMedia,
    fetchCreateWABAUploadSession,
    isLoadingFetchCreateWABAUploadSession,
    openMediaLibraryModal,
    isMediaLibraryModalVisible,
    closeMediaLibraryModal,
  };
};
