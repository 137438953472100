import type {
  ChannelAccumulativeTagReportData,
  ChannelTagReportData,
  ChannelTagWonReportData,
  ConversationDurationReportData,
  ConversationReportData,
  MessageReportData,
  NewUserReportData,
  NewUserTimeAnalysisReportData,
  OpenCloseReportData,
} from '@/declarations/report';
import {
  getChannelAccumulativeTagReport,
  getChannelTagReport,
  getChannelTagWonReport,
  getConversationDurationReport,
  getConversationReport,
  getMessageReport,
  getOpenCloseReport,
  getUserReport,
  getUserTimeAnalysisReport,
} from '@/services/luluchat/reports';
import { useRequest } from 'umi';

export default () => {
  const {
    data: channelAccumulativeTagReport = [] as ChannelAccumulativeTagReportData[],
    run: fetchGetChannelAccumulativeTagReport,
    loading: isLoadingFetchGetChannelAccumulativeTagReport,
  } = useRequest(getChannelAccumulativeTagReport, {
    manual: true,
  });

  const {
    data: messageReport = [] as MessageReportData[],
    run: fetchGetMessageReport,
    loading: isLoadingFetchGetMessageReport,
  } = useRequest(getMessageReport, {
    manual: true,
  });

  const {
    data: newUserReport = [] as NewUserReportData[],
    run: fetchGetUserReport,
    loading: isLoadingFetchGetUserReport,
  } = useRequest(getUserReport, {
    manual: true,
  });

  const {
    data: newUserTimeAnalysisReport = [] as NewUserTimeAnalysisReportData[],
    run: fetchGetUserTimeAnalysisReport,
    loading: isLoadingFetchGetUserTimeAnalysisReport,
  } = useRequest(getUserTimeAnalysisReport, {
    manual: true,
  });

  const {
    data: openCloseReport = [] as OpenCloseReportData[],
    run: fetchGetOpenCloseReport,
    loading: isLoadingFetchGetOpenCloseReport,
  } = useRequest(getOpenCloseReport, {
    manual: true,
  });

  const {
    data: conversationDurationReport = [] as ConversationDurationReportData[],
    run: fetchGetConversationDurationReport,
    loading: isLoadingFetchGetConversationDurationReport,
  } = useRequest(getConversationDurationReport, {
    manual: true,
  });

  const {
    data: channelTagReport = [] as ChannelTagReportData[],
    run: fetchGetChannelTagReport,
    loading: isLoadingFetchGetChannelTagReport,
  } = useRequest(getChannelTagReport, {
    manual: true,
  });

  const {
    data: channelTagWonReport = [] as ChannelTagWonReportData[],
    run: fetchGetChannelTagWonReport,
    loading: isLoadingFetchGetChannelTagWonReport,
  } = useRequest(getChannelTagWonReport, {
    manual: true,
  });

  const {
    data: conversationReport = [] as ConversationReportData[],
    run: fetchGetConversationReport,
    loading: isLoadingFetchGetConversationReport,
  } = useRequest(getConversationReport, {
    manual: true,
  });

  return {
    messageReport,
    isLoadingFetchGetMessageReport,
    fetchGetMessageReport,
    newUserReport,
    fetchGetUserReport,
    isLoadingFetchGetUserReport,
    newUserTimeAnalysisReport,
    fetchGetUserTimeAnalysisReport,
    isLoadingFetchGetUserTimeAnalysisReport,
    openCloseReport,
    fetchGetOpenCloseReport,
    isLoadingFetchGetOpenCloseReport,
    conversationDurationReport,
    fetchGetConversationDurationReport,
    isLoadingFetchGetConversationDurationReport,
    channelTagReport,
    fetchGetChannelTagReport,
    isLoadingFetchGetChannelTagReport,
    channelTagWonReport,
    fetchGetChannelTagWonReport,
    isLoadingFetchGetChannelTagWonReport,
    conversationReport,
    fetchGetConversationReport,
    isLoadingFetchGetConversationReport,
    channelAccumulativeTagReport,
    fetchGetChannelAccumulativeTagReport,
    isLoadingFetchGetChannelAccumulativeTagReport,
  };
};
