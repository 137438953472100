export const MESSAGE_LIMIT = 15;
export const TAG_COLORS = [
  '#fee11c', // luluchat brand color
  '#b3e5fc', // light blue
  '#c8e6c9', // light green
  '#ffecb3', // light amber
  '#e1bee7', // light purple
  '#ffcdd2', // light pink
  '#b2dfdb', // light teal
  '#ffe0b2', // light orange
  '#d1c4e9', // light indigo
  '#fff9c4', // light yellow
  '#d7ccc8', // light brown
  '#b0bec5', // light blue grey
  '#dcedc8', // light lime
  '#f8bbd0', // light rose
  '#f0f4c3', // light lemon chiffon
  '#c5cae9', // light slate blue
  '#ffccbc', // light salmon
  '#e6ee9c', // light olive
  '#f5f5dc', // beige
  '#ffe4b5', // moccasin
];
