// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function uploadMedia(body: API.UploadMediaParams, options?: { [key: string]: any }) {
  const formData = new FormData();
  formData.append('upload_file', body.upload_file);
  return request<API.UploadMediaResult>(`/upload-media`, {
    method: 'POST',
    data: formData,
  });
}

export async function uploadTempMedia(
  body: API.UploadMediaParams,
  options?: { [key: string]: any },
) {
  const formData = new FormData();
  formData.append('upload_file', body.upload_file);
  return request<API.UploadMediaResult>(`/upload-temp-media`, {
    method: 'POST',
    data: formData,
  });
}

export async function createWABAUploadSession(
  body: API.CreateWABAUploadSessionParams,
  options?: { [key: string]: any },
) {
  const formData = new FormData();
  formData.append('upload_file', body.upload_file);
  return request<API.CreateWABAUploadSessionResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/waba/create-upload-session`,
    {
      method: 'POST',
      data: formData,
    },
  );
}

export async function getTraceableMedias(
  body?: API.GetTraceableMediasParams,
  options?: { [key: string]: any },
) {
  return request<API.GetTraceableMediasResult>(`/users/traceable-medias`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function getTraceableMediasChatStorage(
  body?: API.GetTraceableMediasChatStorageParams,
  options?: { [key: string]: any },
) {
  return request<API.GetTraceableMediasChatStorageResult>(`/users/traceable-medias/chat-storage`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function deleteTraceableMedia(
  body: API.DeleteTraceableMediaParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteTraceableMediaResults>(`/users/traceable-medias/bulk-delete`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function deleteTraceableMediaByChatroom(
  { channel_uuid, wa_contact_id }: API.DeleteTraceableMediaByChatroomParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteTraceableMediaByChatroomResults>(
    `/users/traceable-medias/channel-${channel_uuid}/${wa_contact_id}`,
    {
      method: 'DELETE',
      ...(options || {}),
    },
  );
}
