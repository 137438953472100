// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getShopifyAccessToken(
  body: API.GetShopifyAccessTokenParams,
  options?: { [key: string]: any },
) {
  const { shop, authorization_code } = body;
  return request<API.GetShopifyAccessTokenResult>(`/users/integrations/shopify/get-access-token`, {
    method: 'POST',
    data: {
      shop,
      code: authorization_code,
    },
    ...(options || {}),
  });
}
