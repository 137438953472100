import type { GrowthTool } from '@/declarations/growthTool';
import {
  createGrowthTool,
  getGrowthTool,
  getGrowthTools,
  updateGrowthTool,
} from '@/services/luluchat/growthTools';
import type { API } from '@/services/luluchat/typings.d';
import { useRequest } from 'umi';

export default () => {
  const {
    data: growthTools = {
      data: [] as GrowthTool[],
    } as API.Response<GrowthTool[]>,
    run: fetchGetGrowthTools,
    loading: isLoadingFetchGetGrowthTools,
  } = useRequest(getGrowthTools, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchCreateGrowthTool, loading: isLoadingFetchCreateGrowthTool } = useRequest(
    createGrowthTool,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateGrowthTool, loading: isLoadingFetchUpdateGrowthTool } = useRequest(
    updateGrowthTool,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const {
    data: growthTool = {} as GrowthTool,
    run: fetchGetGrowthTool,
    loading: isLoadingFetchGetGrowthTool,
  } = useRequest(getGrowthTool, {
    manual: true,
  });

  return {
    growthTools,
    fetchGetGrowthTools,
    isLoadingFetchGetGrowthTools,
    fetchCreateGrowthTool,
    isLoadingFetchCreateGrowthTool,
    growthTool,
    fetchGetGrowthTool,
    isLoadingFetchGetGrowthTool,
    fetchUpdateGrowthTool,
    isLoadingFetchUpdateGrowthTool,
  };
};
