import type { QuickReply } from '@/declarations/quickReply';
import {
  createQuickReply,
  deleteQuickReply,
  getQuickReplies,
  getQuickReply,
  updateQuickReply,
} from '@/services/luluchat/quickReplies';
import { useRequest } from 'umi';
export default () => {
  const {
    data: quickReplies = [] as QuickReply[],
    run: fetchGetQuickReplies,
    loading: isLoadingFetchGetQuickReplies,
  } = useRequest(getQuickReplies, { manual: true });

  const { run: fetchCreateQuickReply, loading: isLoadingFetchCreateQuickReply } = useRequest(
    createQuickReply,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const {
    data: quickReply = {} as QuickReply,
    run: fetchGetQuickReply,
    loading: isLoadingFetchGetQuickReply,
  } = useRequest(getQuickReply, {
    manual: true,
  });

  const { run: fetchUpdateQuickReply, loading: isLoadingFetchUpdateQuickReply } = useRequest(
    updateQuickReply,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchDeleteQuickReply, loading: isLoadingFetchDeleteQuickReply } = useRequest(
    deleteQuickReply,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  return {
    quickReplies,
    fetchGetQuickReplies,
    isLoadingFetchGetQuickReplies,
    fetchCreateQuickReply,
    isLoadingFetchCreateQuickReply,
    quickReply,
    fetchGetQuickReply,
    isLoadingFetchGetQuickReply,
    fetchUpdateQuickReply,
    isLoadingFetchUpdateQuickReply,
    fetchDeleteQuickReply,
    isLoadingFetchDeleteQuickReply,
  };
};
