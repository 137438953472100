import type { Workflow } from '@/declarations/workflow';
import type { API } from '@/services/luluchat/typings.d';
import {
  activateWorkflow,
  createWorkflow,
  deactivateWorkflow,
  getWorkflow,
  getWorkflows,
  updateWorkflow,
} from '@/services/luluchat/workflows';
import { useRequest } from 'umi';

export default () => {
  const {
    data: workflows = {
      data: [] as Workflow[],
    } as API.Response<Workflow[]>,
    run: fetchGetWorkflows,
    loading: isLoadingFetchGetWorkflows,
  } = useRequest(getWorkflows, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchCreateWorkflow, loading: isLoadingFetchCreateWorkflow } = useRequest(
    createWorkflow,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const {
    data: workflow = {} as Workflow,
    run: fetchGetWorkflow,
    loading: isLoadingFetchGetWorkflow,
  } = useRequest(getWorkflow, { manual: true });

  const { run: fetchActivateWorkflow, loading: isLoadingFetchActivateWorkflow } = useRequest(
    activateWorkflow,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateWorkflow, loading: isLoadingFetchUpdateWorkflow } = useRequest(
    updateWorkflow,
    {
      manual: true,
    },
  );

  const { run: fetchDeactivateWorkflow, loading: isLoadingFetchDeactivateWorkflow } = useRequest(
    deactivateWorkflow,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  return {
    workflow,
    fetchGetWorkflow,
    isLoadingFetchGetWorkflow,
    workflows,
    fetchGetWorkflows,
    isLoadingFetchGetWorkflows,
    fetchCreateWorkflow,
    isLoadingFetchCreateWorkflow,
    fetchUpdateWorkflow,
    isLoadingFetchUpdateWorkflow,
    fetchActivateWorkflow,
    isLoadingFetchActivateWorkflow,
    fetchDeactivateWorkflow,
    isLoadingFetchDeactivateWorkflow,
  };
};
