import type { Reminder } from '@/declarations/reminder';
import { List, message, Modal, Space, Tooltip, Typography } from 'antd';

import dayjs from 'dayjs';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import { useModel } from 'umi';
import styles from './index.less';

export type ReminderListProps = {
  showViewMore?: boolean;
  style?: React.CSSProperties;
  viewMoreText?: string;
  list: Reminder[];
  onViewMore?: (e: any) => void;
  canMarkAsDone?: boolean;
  canEdit?: boolean;
  onSuccessDeleteReminder?: () => void;
  onSuccessMarkReminderAsDone?: () => void;
  isLoading?: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  emptyText: React.ReactNode;
};
const ReminderList: React.FC<ReminderListProps> = ({
  list = [],
  onViewMore,
  viewMoreText,
  showViewMore = false,
  canMarkAsDone = false,
  onSuccessDeleteReminder,
  isLoading = false,
  onSuccessMarkReminderAsDone,
  setVisible,
  emptyText,
}) => {
  const {
    fetchDeleteReminder,
    fetchMarkReminderAsDone,
    openReminderFormModal,
    setCurrentReminder,
  } = useModel('reminder');
  const { initialState } = useModel('@@initialState');
  const currentUser = initialState?.currentUser;

  const deleteReminder = (reminderId: number) => {
    if (!reminderId) return;
    Modal.confirm({
      title: 'Delete Reminder',
      content: <>Are you sure want to delete this Reminder ?</>,
      okText: 'Yes',
      cancelText: 'No',
      zIndex: 5000,
      onCancel: (close) => {
        setVisible(true);
        close();
      },
      onOk: async () => {
        setVisible(true);
        const res = await fetchDeleteReminder({ id: reminderId });
        if (res?.status) {
          message.success('The reminder successfully removed from your list.');
          if (typeof onSuccessDeleteReminder === 'function') {
            onSuccessDeleteReminder();
          }
        }
      },
    });
  };

  const onClickEdit = (item: Reminder) => {
    setVisible(false);
    openReminderFormModal();
    setCurrentReminder(item);
  };

  const markAsDone = (reminderId: number) => {
    if (!reminderId) return;
    Modal.confirm({
      title: 'Mark Reminder as Done',
      content: <>Are you sure want to mark this Reminder as done?</>,
      okText: 'Yes',
      cancelText: 'No',
      zIndex: 5000,
      onCancel: (close) => {
        setVisible(true);
        close();
      },
      onOk: async () => {
        setVisible(true);
        const res = await fetchMarkReminderAsDone({
          ids: [reminderId],
        });
        if (res?.status) {
          message.success('Excellent work! The reminder has been marked as done.');
          if (typeof onSuccessMarkReminderAsDone === 'function') {
            onSuccessMarkReminderAsDone();
          }
        }
      },
    });
  };

  if ((!list || list.length === 0) && !isLoading) {
    return (
      <div className={styles.notFound}>
        <img
          src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
          alt="not found"
        />
        <Typography.Paragraph type="secondary">{emptyText}</Typography.Paragraph>
      </div>
    );
  }

  return (
    <div>
      <List<Reminder>
        className={styles.list}
        dataSource={list}
        loading={isLoading}
        renderItem={(item, i) => {
          let isCreatedFromMe = false;
          if (item.meta?.created_by_id === currentUser?.id) {
            isCreatedFromMe = true;
          }
          return (
            <List.Item
              className={styles.item}
              key={item.id || i}
              onClick={() => {
                onClickEdit(item);
              }}
            >
              <List.Item.Meta
                className={styles.meta}
                title={<div className={styles.title}>{item.title} at {dayjs(item.meta.remind).format('YYYY-MM-DD HH:mm:ss')}.</div>}
                description={
                  <div>
                    <Typography.Paragraph type="secondary" ellipsis={{ rows: 2, expandable: true }}>
                      <small>{item.description}</small>
                    </Typography.Paragraph>
                    <div className={styles.footerContainer}>
                      <div className={styles.actionsContainer}>
                        <Space size="middle">
                          {isCreatedFromMe && (
                            <Tooltip title="Delete">
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteReminder(item.id);
                                }}
                                src='/images/img/delete.png'
                                style={{ cursor: 'pointer' }}
                                alt="Delete"
                                width={16}
                              />
                            </Tooltip>
                          )}
                          {isCreatedFromMe && (
                            <Tooltip title="Edit">
                              <img
                                src='/images/img/edit.png'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onClickEdit(item);
                                }}
                                style={{ cursor: 'pointer' }}
                                alt="Edit"
                                width={16}
                              />
                            </Tooltip>
                          )}
                          {canMarkAsDone && (
                            <Tooltip title="Mark as Done">
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  markAsDone(item.id);
                                }}
                                style={{ cursor: 'pointer' }}
                                src='/images/img/tick-inside-circle.png'
                                alt="Mark as Done"
                                width={16}
                              />
                            </Tooltip>
                          )}
                        </Space>
                      </div>
                    </div>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      {showViewMore ? (
        <div className={styles.bottomBar}>
          <div
            onClick={(e) => {
              if (onViewMore) {
                onViewMore(e);
              }
            }}
          >
            {viewMoreText}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReminderList;
