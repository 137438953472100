// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getInboxTabs(body?: API.GetInboxTabsParams, options?: { [key: string]: any }) {
  return request<API.GetInboxTabsResult>(`/users/inbox-tabs/my-tabs`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createInboxTab(
  body: API.CreateInboxTabParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateInboxTabResult>(`/users/inbox-tabs`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function getInboxTab(
  { id, ...body }: API.GetInboxTabParams,
  options?: { [key: string]: any },
) {
  return request<API.GetInboxTabResult>(`/users/inbox-tabs/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function updateInboxTab(
  { id, ...body }: API.UpdateInboxTabParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateInboxTabResult>(`/users/inbox-tabs/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function deleteInboxTab(
  { id }: API.DeleteInboxTabParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteInboxTabResult>(`/users/inbox-tabs/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}

export async function bulkUpdateInboxTab(
  body: API.BulkUpdateInboxTabParams,
  options?: { [key: string]: any },
) {
  return request<API.BulkUpdateInboxTabResult>(`/users/inbox-tabs/bulk-update`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function addContactsToTab(
  { id, ...body }: API.AddContactsToTabParams,
  options?: { [key: string]: any },
) {
  return request<API.AddContactsToTabResult>(`/users/inbox-tabs/${id}/add-contacts`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function removeContactsFromTab(
  { id, ...body }: API.RemoveContactsFromTabParams,
  options?: { [key: string]: any },
) {
  return request<API.RemoveContactsFromTabResult>(`/users/inbox-tabs/${id}/remove-contacts`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function closeConversation(
  { contact_id, ...body }: API.CloseConversationParams,
  options?: { [key: string]: any },
) {
  return request<API.CloseConversationResult>(
    `/users/inbox-tabs/${contact_id}/close-conversation`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}
