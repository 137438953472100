import type { CartBuilder, Plan } from '@/declarations/plan';
import type { AddOn, CheckoutItem } from '@/declarations/plan.d';
import { AdditionalModule, AddOnType, SubscriptionPlanType } from '@/declarations/plan.d';
import type { Billing } from '@/declarations/team';
import { checkoutPlan, getPlans } from '@/services/luluchat/plans';
import type { API } from '@/services/luluchat/typings.d';
import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useRequest } from 'umi';

enum PLAN_ID {
  STARTER = 2,
  PRO = 3,
  BUSINESS = 4,
}

export default () => {
  const {
    data: plans = {
      data: [] as Plan[],
    },
    run: fetchGetPlans,
    loading: isLoadingFetchGetPlans,
  } = useRequest(getPlans, {
    manual: true,
    formatResult(res): API.Response<Plan[]> {
      return res;
    },
  });
  const [cart, setCart] = useState<CartBuilder>({
    subscriptionPeriod: 'year',
    subscriptionPlanType: SubscriptionPlanType.PRO,
    additionalModules: [],
    addOns: [],
  });
  const [messagePlans, setMessagePlans] = useState<Plan[]>([]);
  const [channelPlan, setChannelPlan] = useState<Plan>();
  const [promocode, setPromocode] = useState<string>();

  useEffect(() => {
    if (plans?.data?.length > 0) {
      setMessagePlans(plans.data.filter((plan) => plan.type === 'message'));
      const _channelPlan = plans.data.filter((plan) => plan.type === 'channel');
      if (_channelPlan.length > 0) {
        setChannelPlan(_channelPlan[0]);
      }
    }
  }, [plans]);

  const getSubscriptionPlanIdByPlanType = (planType: SubscriptionPlanType) => {
    switch (planType) {
      case SubscriptionPlanType.PRO:
        return PLAN_ID.PRO;
      case SubscriptionPlanType.STARTER:
        return PLAN_ID.STARTER;
      case SubscriptionPlanType.BUSINESS:
        return PLAN_ID.BUSINESS;
    }
  };

  const getSubscriptionPlanTypeById = (planId: PLAN_ID) => {
    switch (planId) {
      case PLAN_ID.STARTER:
        return SubscriptionPlanType.STARTER;
      case PLAN_ID.PRO:
        return SubscriptionPlanType.PRO;
      case PLAN_ID.BUSINESS:
        return SubscriptionPlanType.BUSINESS;
    }
  };

  const addOns: Plan[] = useMemo(() => {
    if (!plans?.data || plans?.data.length === 0) return [];
    const addOnPlans = plans?.data.filter(
      (plan) => plan.type === AddOnType.USER || plan.type === AddOnType.CHANNEL || plan.type === AddOnType.CALENDAR,
    );
    return addOnPlans?.map((p) => {
      if (p.type === AddOnType.USER) {
        return {
          ...p,
          description: 'Add additional team user(s) to help manage your luluchat account',
        };
      }
      if (p.type === AddOnType.CHANNEL) {
        return {
          ...p,
          description: 'Add additional channel(s) to handle different business profile',
        };
      }
      if (p.type === AddOnType.CALENDAR) {
        return {
          ...p,
          description: 'Add additional calendar(s) to handle different store',
        };
      }
      return p;
    });
  }, [plans]);

  const subscriptionPlans: Plan[] = useMemo(() => {
    if (!plans?.data || plans?.data.length === 0) return [];
    const _subscriptionPlans = plans?.data.filter((plan) => plan.type === 'subscription');
    return _subscriptionPlans?.map((p) => {
      let description = '';
      switch (p.id) {
        case PLAN_ID.STARTER:
          description = 'For small businesses with a medium customer flow.';
        case PLAN_ID.PRO:
          description = 'For small businesses with a huge customer flow.';
        case PLAN_ID.BUSINESS:
          description = 'For enterprises who need to get the most from the platform.';
      }
      return {
        ...p,
        description,
      };
    });
  }, [plans]);

  const additionalModules: Plan[] = useMemo(() => {
    if (!plans?.data || plans?.data.length === 0) return [];
    const planId = getSubscriptionPlanIdByPlanType(cart.subscriptionPlanType);
    if (!planId) return [];
    const selectedPlan = plans.data.find(({ id }) => id === planId);
    if (!selectedPlan) return [];
    const _additionalModules = plans?.data.filter((plan) => {
      if (plan.type === AdditionalModule.FORM) {
        if (selectedPlan.has_form === false) {
          return true;
        }
        return false;
      } else if (plan.type === AdditionalModule.BROADCAST) {
        if (selectedPlan.has_broadcast === false) {
          return true;
        }
        return false;
      } else if (plan.type === AdditionalModule.APP) {
        if (selectedPlan.has_app === false) {
          return true;
        }
        return false;
      } else if (plan.type === AdditionalModule.STORAGE) {
        if (!!selectedPlan.has_storage === false) {
          return true;
        }
        return false;
      } else if (plan.type === AdditionalModule.BOOKING) {
        if (!!selectedPlan.has_storage === false) {
          return true;
        }
        return false;
      }
      return false;
    });

    return _additionalModules?.map((p) => {
      if (p.type === AdditionalModule.FORM) {
        return {
          ...p,
          description:
            "Build forms without code & get the data directly from the customer's WhatsApp number",
        };
      } else if (p.type === AdditionalModule.BROADCAST) {
        return {
          ...p,
          description: 'Broadcast your customised Flow to your existing Contacts',
        };
      } else if (p.type === AdditionalModule.APP) {
        return {
          ...p,
          description: 'Connect third party application and unlock all kinds of functionality',
        };
      } else if (p.type === AdditionalModule.STORAGE) {
        return {
          ...p,
          description:
            'Unlock the power to upload files in our Form module with a 3 months storage duration',
        };
      } else if (p.type === AdditionalModule.BOOKING) {
        return {
          ...p,
          description:
            'Manage your appointment more easy with luluchat system',
        };
      }
      return p;
    });
  }, [cart.subscriptionPlanType, plans]);

  const { run: fetchCheckoutPlan, loading: isLoadingFetchCheckoutPlan } = useRequest(checkoutPlan, {
    manual: true,
    formatResult(res): API.CheckoutPlanResult {
      return res;
    },
  });

  const onSelectSubscriptionPeriod = (sp: 'quarter' | 'year') => {
    setCart((prevCart) => {
      return {
        ...prevCart,
        subscriptionPeriod: sp,
      };
    });
  };

  const onSelectSubscriptionPlanType = (spt: SubscriptionPlanType) => {
    setCart((prevCart) => {
      return {
        ...prevCart,
        subscriptionPlanType: spt,
        additionalModules: [],
        addOns: [],
      };
    });
  };

  const onSelectAdditionalModules = (additionalModule: Plan) => {
    setCart((prevCart) => {
      const newCart = {
        ...prevCart,
      };
      if (newCart.additionalModules.length === 0) {
        newCart.additionalModules = [
          { plan_id: additionalModule?.id, plan_type: additionalModule?.type },
        ];
        return newCart;
      }
      // remove existing storage plan
      if (additionalModule?.type === 'storage') {
        const existingStoragePlanIndex = newCart.additionalModules.findIndex(
          (x) => x?.plan_type === 'storage',
        );
        if (existingStoragePlanIndex > -1) {
          newCart.additionalModules.splice(existingStoragePlanIndex, 1);
        }
      }

      const additionalModuleIndex = newCart.additionalModules.findIndex(
        (x) => x?.plan_id === additionalModule?.id,
      );
      if (additionalModuleIndex > -1) {
        newCart.additionalModules.splice(additionalModuleIndex, 1);
      } else {
        newCart.additionalModules.push({
          plan_id: additionalModule?.id,
          plan_type: additionalModule?.type,
        });
      }
      return newCart;
    });
  };

  const onSelectAddOns = (addOn: AddOn) => {
    setCart((prevCart) => {
      const newCart = {
        ...prevCart,
      };
      if (newCart.addOns.length === 0) {
        newCart.addOns = [addOn];
        return newCart;
      }
      const addOnIndex = newCart.addOns.findIndex(({ type }) => {
        return type === addOn.type;
      });
      if (addOnIndex > -1) {
        newCart.addOns.splice(addOnIndex, 1);
      } else {
        newCart.addOns.push(addOn);
      }
      return newCart;
    });
  };

  const onChangeAddOnQuantity = (addOn: AddOn) => {
    setCart((prevCart) => {
      const newCart = {
        ...prevCart,
      };
      if (newCart.addOns.length === 0) {
        newCart.addOns = [addOn];
        return newCart;
      }
      const addOnIndex = newCart.addOns.findIndex(({ type }) => {
        return type === addOn.type;
      });
      if (addOnIndex > -1) {
        newCart.addOns[addOnIndex].quantity = addOn.quantity;
      } else {
        newCart.addOns.push(addOn);
      }
      return newCart;
    });
  };

  const checkoutItems: CheckoutItem[] =
    useMemo(() => {
      const items: CheckoutItem[] = [];
      const planId = getSubscriptionPlanIdByPlanType(cart.subscriptionPlanType);
      if (!planId) return console.error('Cannot assign planId');
      // add plan
      plans.data.forEach((plan) => {
        if (plan.id === planId) {
          plan.prices.forEach((price) => {
            if (price.duration_type === cart.subscriptionPeriod) {
              items.push({
                plan_name: plan.name,
                plan_type: plan.type,
                price_id: price.id,
                price_per_month: price.price_per_month,
                quantity: 1,
              });
            }
          });
        }
      });

      // add module
      if (cart.additionalModules && cart.additionalModules.length > 0) {
        cart.additionalModules.map((additionalModule) => {
          plans.data.forEach((plan) => {
            if (plan.id === additionalModule?.plan_id) {
              plan.prices.forEach((price) => {
                if (price.duration_type === cart.subscriptionPeriod) {
                  items.push({
                    plan_name: plan.name,
                    plan_type: plan.type,
                    price_id: price.id,
                    price_per_month: price.price_per_month,
                    quantity: 1,
                  });
                }
              });
            }
          });
        });
      }

      // add addon
      if (cart.addOns && cart.addOns.length > 0) {
        cart.addOns.map((addOn) => {
          plans.data.forEach((plan) => {
            if (plan.type === addOn.type) {
              plan.prices.forEach((price) => {
                if (price.duration_type === cart.subscriptionPeriod) {
                  items.push({
                    plan_name: plan.name,
                    plan_type: plan.type,
                    price_id: price.id,
                    price_per_month: price.price_per_month * addOn.quantity,
                    quantity: addOn.quantity,
                  });
                }
              });
            }
          });
        });
      }
      return items;
    }, [cart, plans]) || [];

  const onCheckout = async () => {
    if (isLoadingFetchCheckoutPlan || checkoutItems.length === 0) return;
    const res = await fetchCheckoutPlan({
      lines: checkoutItems,
      promocode,
    });
    if (res.status) {
      if (res.data?.url && res.data.type === 'immediate') {
        message.success('Redirecting to payment gateway...');
        window.location.href = res.data?.url;
      } else {
        if (res.data.type === 'upgrade') {
          window.location.href = '/result/in-progress';
        }
        if (res.data.type === 'downgrade') {
          window.location.href = '/result/in-progress';
        }
      }
    }
  };

  const initCart = (billing: Billing) => {
    const subscriptionPlanType = getSubscriptionPlanTypeById(billing.plan.id);
    if (!subscriptionPlanType) return;
    const _additionalModules = billing.items.filter(
      (item) =>
        (item.product.type === AdditionalModule.BROADCAST ||
          item.product.type === AdditionalModule.BOOKING ||
          item.product.type === AdditionalModule.FORM ||
          item.product.type === AdditionalModule.APP ||
          item.product.type === AdditionalModule.STORAGE) &&
        item.row_total > -1,
    );

    const _addOns = billing.items.filter(
      (item) =>
        (item.product.type === AddOnType.CHANNEL || item.product.type === AddOnType.USER || item.product.type === AddOnType.CALENDAR) &&
        item.row_total > -1,
    );
    setCart({
      subscriptionPeriod: billing.price.duration_type,
      subscriptionPlanType,
      additionalModules:
        _additionalModules.length > 0
          ? _additionalModules.map((_additionalModule) => {
              return {
                plan_id: _additionalModule?.product?.id,
                plan_type: _additionalModule?.product?.type,
              };
            })
          : [],
      addOns:
        _addOns.length > 0
          ? _addOns.map((addOn) => {
              return {
                type: addOn.product.type,
                quantity: addOn.quantity,
              } as AddOn;
            })
          : [],
    });
  };

  return {
    addOns,
    additionalModules,
    checkoutItems,
    channelPlan,
    messagePlans,
    subscriptionPlans,
    plans,
    fetchGetPlans,
    isLoadingFetchGetPlans,
    fetchCheckoutPlan,
    isLoadingFetchCheckoutPlan,
    cart,
    initCart,
    onSelectSubscriptionPeriod,
    onSelectSubscriptionPlanType,
    onSelectAdditionalModules,
    onSelectAddOns,
    onChangeAddOnQuantity,
    onCheckout,
    promocode,
    setPromocode,
  };
};
