import { loginOut } from '@/components/RightContent/AvatarDropdown';
import * as Sentry from '@sentry/react';
import { message, Modal } from 'antd';
import Cookies from 'js-cookie';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { history } from 'umi';
import type { ResponseError } from 'umi-request';
import { extend } from 'umi-request';

const umiRequest = extend({
  prefix: API_END_POINT,
});

const setAuthorizationToHeader = () => {
  const headers = {
    Authorization: Cookies.get('access_token') ? `Bearer ${Cookies.get('access_token')}` : '',
    Accept: 'application/json',
  } as any;
  if (localStorage.getItem('x-team-id')) {
    headers['x-team-id'] = localStorage.getItem('x-team-id');
  }
  if (localStorage.getItem('x-channel-id')) {
    headers['x-channel-id'] = localStorage.getItem('x-channel-id');
  }
  return umiRequest.extendOptions({
    headers,
  });
};

export const getErrorMessages = (errors: {} = {}): string => {
  let errorMessages = '';
  _map(errors, (error: string[] = []) => {
    errorMessages += error?.join(' ');
  });
  return errorMessages;
};

export default function request<T>(
  url: string,
  options: any = {
    method: 'GET',
    data: null,
    external: false,
    headers: {
      'Content-Type': 'application/json',
    },
  },
): Promise<T> {
  if (!options?.external) {
    setAuthorizationToHeader();
  } else {
    umiRequest.extendOptions({
      prefix: '',
    });
  }
  if (_get(options, 'headers.Content-Type') === 'multipart/form-data') {
    options.requestType = 'form';
  } else {
    options.requestType = 'json';
    umiRequest.extendOptions({
      headers: {
        Accept: 'application/json',
      },
    });
  }
  if (options.method === 'GET') {
    options.params = {
      ...options.data,
    };
  }

  return umiRequest(url, options)
    .then((res: any) => res)
    .catch(async (e: ResponseError) => {
      const status = e.response?.status;
      const errorMessages = getErrorMessages(_get(e, 'data.errors')) || _get(e, 'data.message');
      if (status === 422 || status === 400 || status === 429 || status === 500 || status === 404) {
        if (status === 500 || status === 429) {
          Sentry.setExtras({ request: options, response: e?.data });
          Sentry.setTag('http_code', status);
          Sentry.captureException(e);
        }
        if (_get(e, 'data.data.to_upgrade', false)) {
          Modal.confirm({
            title: 'Reached Plan Limit',
            content:
              "Your plan has reached to it's limit, you need to upgrade your plan before this action can be completed.",
            okText: 'Go to Plans Page',
            cancelText: 'Cancel',
            onOk: () => {
              history.push('/plans');
            },
          });
        } else if (errorMessages) {
          message.error({
            content: errorMessages,
            key: `global-error-${status}`,
          });
        }
        return _get(e, 'data');
      }
      if (status === 401) {
        // Sentry.setExtras({ request: options, response: e?.data });
        // Sentry.setTag('http_code', 401);
        // Sentry.captureException(e);
        if (errorMessages) {
          message.error({
            content: errorMessages,
            key: `global-error-${status}`,
          });
        }
        loginOut();
        return _get(e, 'data');
      }
      return e;
    });
}
