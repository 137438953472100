// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getCustomers(body: API.GetCustomersParams, options?: { [key: string]: any }) {
  return request<API.GetCustomersResult>(`/users/customers`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createCustomer(
  body: API.CreateCustomerParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateCustomerResult>(`/users/customers`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function getCustomer(
  { id, ...body }: API.GetCustomerParams,
  options?: { [key: string]: any },
) {
  if (!id) return;
  return request<API.GetCustomerResult>(`/users/customers/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function updateCustomer(
  { id, ...body }: Partial<API.UpdateCustomerParams>,
  options?: { [key: string]: any },
) {
  return request<API.UpdateCustomerResult>(`/users/customers/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

// export async function deleteCustomer(
//   { id }: API.DeleteCustomerParams,
//   options?: { [key: string]: any },
// ) {
//   return request<API.DeleteCustomerResult>(`/users/customers/${id}`, {
//     method: 'DELETE',
//     ...(options || {}),
//   });
// }

// export async function importCustomer(
//   body: API.ImportCustomerParams,
//   options?: { [key: string]: any },
// ) {
//   return request<API.ImportCustomerResult>(
//     `/users/contacts/import`,
//     {
//       method: 'POST',
//       data: body,
//       ...(options || {}),
//     },
//   );
// }
