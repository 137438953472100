// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getContacts(body: API.GetContactsParams, options?: { [key: string]: any }) {
  return request<API.GetContactsResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/contacts`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function createContact(
  body: API.CreateContactParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateContactResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/contacts`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getContact(
  { id, ...body }: API.GetContactParams,
  options?: { [key: string]: any },
) {
  if (!id) return;
  return request<API.GetContactResult>(
    `/users/channels/${localStorage.getItem(
      'x-channel-id',
    )}/contacts/${id}?includes=tags,tabs,assignee,custom_attributes,collaborators`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function updateContact(
  { id, ...body }: Partial<API.UpdateContactParams>,
  options?: { [key: string]: any },
) {
  return request<API.UpdateContactResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/contacts/${id}`,
    {
      method: 'PUT',
      data: body,
      ...(options || {}),
    },
  );
}

export async function deleteContact(
  { id }: API.DeleteContactParams,
  options?: { [key: string]: any }
) {
  return request<API.DeleteContactResult>(`/users/channels/${localStorage.getItem('x-channel-id')}/contacts/${id}`, {
    method: "DELETE",
    ...(options || {}),
  });
}


export async function importContact(
  body: API.ImportContactParams,
  options?: { [key: string]: any },
) {
  return request<API.ImportContactResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/contacts/import`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getContactInfo(
  { id, ...body }: API.GetContactInfoParams,
  options?: { [key: string]: any },
) {
  return request<API.GetContactInfoResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/contacts/${id}/info`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function assignAttributes(
  { id, ...body }: API.AssignAttributesParams,
  options?: { [key: string]: any },
) {
  return request<API.AssignAttributesResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/contacts/${id}/assign-attributes`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getContactActiveFlow(
  { wa_contact_id }: API.GetContactActivateFlowParams,
  options?: { [key: string]: any },
) {
  return request<API.GetContactActivateFlowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/contacts/${wa_contact_id}/active-flows`,
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}
export async function stopFlow(
  { type, id, ...body }: API.StopFlowParams,
  options?: { [key: string]: any },
) {
  return request<API.StopFlowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/${type}-step-${id}/stop`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}