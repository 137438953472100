import type { AbsentFlow, Flow, FlowKeyword } from '@/declarations/flow';
import {
  activateFlow,
  copyFlow,
  createAbsentFlow,
  createFlow,
  deactivateFlow,
  getFlow,
  getFlows,
  getKeywords,
  getOtherFlows,
  resetFlowStats,
  updateFlow,
} from '@/services/luluchat/flows';
import type { API } from '@/services/luluchat/typings.d';
import { useRequest } from 'umi';

export default () => {
  const {
    data: flows = {
      data: [] as Flow[],
    } as API.Response<Flow[]>,
    run: fetchGetFlows,
    loading: isLoadingFetchGetFlows,
  } = useRequest(getFlows, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const {
    data: otherFlows = {
      data: {} as {
        away: null | Flow;
        default: null | Flow;
        absent: null | AbsentFlow;
        'opt-in': null | Flow;
        'opt-out': null | Flow;
      },
    } as API.GetOtherFlowsResult,
    run: fetchGetOtherFlows,
    loading: isLoadingFetchGetOtherFlows,
  } = useRequest(getOtherFlows, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const {
    data: keywords = {
      data: [] as FlowKeyword[],
    } as API.Response<FlowKeyword[]>,
    run: fetchGetKeywords,
    loading: isLoadingFetchGetKeywords,
  } = useRequest(getKeywords, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchCreateFlow, loading: isLoadingFetchCreateFlow } = useRequest(createFlow, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchCreateAbsentFlow, loading: isLoadingFetchCreateAbsentFlow } = useRequest(
    createAbsentFlow,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchResetFlowStats, loading: isLoadingFetchResetFlowStats } = useRequest(
    resetFlowStats,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateFlow, loading: isLoadingFetchUpdateFlow } = useRequest(updateFlow, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const {
    data: flow = {} as Flow,
    run: fetchGetFlow,
    loading: isLoadingFetchGetFlow,
  } = useRequest(getFlow, { manual: true });

  const { run: fetchActivateFlow, loading: isLoadingFetchActivateFlow } = useRequest(activateFlow, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchDeactivateFlow, loading: isLoadingFetchDeactivateFlow } = useRequest(
    deactivateFlow,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchCopyFlow, loading: isLoadingFetchCopyFlow } = useRequest(copyFlow, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  return {
    otherFlows,
    isLoadingFetchGetOtherFlows,
    fetchGetOtherFlows,
    flow,
    fetchGetFlow,
    isLoadingFetchGetFlow,
    flows,
    fetchGetFlows,
    isLoadingFetchGetFlows,
    fetchCreateFlow,
    isLoadingFetchCreateFlow,
    fetchUpdateFlow,
    isLoadingFetchUpdateFlow,
    keywords,
    fetchGetKeywords,
    isLoadingFetchGetKeywords,
    fetchActivateFlow,
    isLoadingFetchActivateFlow,
    fetchDeactivateFlow,
    isLoadingFetchDeactivateFlow,
    fetchCopyFlow,
    isLoadingFetchCopyFlow,
    fetchCreateAbsentFlow,
    isLoadingFetchCreateAbsentFlow,
    fetchResetFlowStats,
    isLoadingFetchResetFlowStats,
  };
};
