// @ts-ignore
/* eslint-disable */
import request from "./request";
import { API } from "./typings";

/** Logs user into the system GET /user/login */
export async function login(
  body: API.LoginParams,
  options?: { [key: string]: any }
) {
  return request<API.LoginResult>("/users/auth/login", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

export async function adminLogin(
  body: API.AdminLoginParams,
  options?: { [key: string]: any }
) {
  return request<API.AdminLoginResult>("/users/auth/admin-login", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

export async function register(
  body: API.RegisterParams,
  options?: { [key: string]: any }
) {
  return request<API.RegisterResult>("/users/auth/register", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

export async function forgetPassword(
  body: API.ForgotPasswordParams,
  options?: { [key: string]: any }
) {
  return request<API.ForgetPasswordResult>("/users/auth/forget-password", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

export async function verifyOTP(
  body: API.VerifyOTPParams,
  options?: { [key: string]: any }
) {
  return request<API.VerifyOTPResult>("/users/auth/verify-otp", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

export async function resendOTP(
  body: API.ResendOTPParams,
  options?: { [key: string]: any }
) {
  return request<API.ResendOTPResult>("/users/auth/resend-otp", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

export async function requestRegisterOTP(
  body: API.RequestRegisterOTPParams,
  options?: { [key: string]: any }
) {
  return request<API.RequestRegisterOTPResult>("/users/auth/request-register-otp", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

export async function changePassword(
  body: API.ChangePasswordParams,
  options?: { [key: string]: any }
) {
  return request<API.ChangePasswordResult>("/users/auth/change-password", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

/** Logs out current logged in user session GET /user/logout */
export async function logoutUser(options?: { [key: string]: any }) {
  return request<any>("/user/logout", {
    method: "GET",
    ...(options || {}),
  });
}
