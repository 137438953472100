import { getIconByType } from '@/components/StepSelector';
import type { Flow, FlowCondition } from '@/declarations/flow';
import { FlowType } from '@/declarations/flow.d';
import { ContentFactory } from '@/factories/ContentFactory';
import { StepFactory } from '@/factories/StepFactory';
import { saveDraft } from '@/services/luluchat/flows';
import { message, notification } from 'antd';
import _debounce from 'lodash/debounce';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useRef, useState } from 'react';
import type {
  Edge,
  Node,
  ReactFlowInstance,
  SetCenterOptions,
  Viewport,
  XYPosition,
} from 'reactflow';
import { addEdge, MarkerType, useEdgesState, useNodesState } from 'reactflow';
import { useModel } from 'umi';
import { v4 as uuidv4 } from 'uuid';
import type {
  ActionType,
  IContent,
  IMessageButton,
  StartFlowType,
  Step,
  StepNode,
  StepType,
} from './diagram.d';
import { ContentType, TriggerType } from './diagram.d';

const save = _debounce(
  ({
    title,
    // deleted_nodes,
    drawing_data,
    conditions,
    callback,
    id,
  }: {
    title: string;
    deleted_nodes: string[];
    conditions: FlowCondition[];
    drawing_data: {
      edges: Edge[];
      nodes: Node<Step>[];
      viewport: Viewport;
    };
    id: number;
    callback: () => void;
  }) => {
    if (id) {
      saveDraft({
        title,
        id,
        conditions,
        // deleted_nodes,
        drawing_data,
      }).then(() => {
        callback();
      });
    }
  },
  1000,
);

export default () => {
  const ref = useRef(null);
  const isReadyForEdit = useRef(false);
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance>();
  const [currentSelectedStep, setCurrentSelectedStep] = useState<Node<Step>>();
  const [isShowStepDrawer, setIsShowStepDrawer] = useState(false);
  const [flow, setFlow] = useState<Flow | null>(null);
  const [mode, setMode] = useState<'published' | 'draft' | 'preview'>('published');
  const [isConnectingEdge, setIsConnectingEdge] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [steps, setSteps, onNodesChange] = useNodesState<Step>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [deletedNodes, setDeletedNodes] = useState<string[]>([]);
  const [flowTitle, setFlowTitle] = useState('');
  const [flowConditions, setFlowConditions] = useState<FlowCondition[]>([]);
  const { flow: currentFlow, fetchUpdateFlow } = useModel('flow');
  const { initialState } = useModel('@@initialState');

  useEffect(() => {
    setFlow(currentFlow);
  }, [currentFlow]);

  const getNextAvailableStepName = (type: StepType, prefix = '', counter = 1): string => {
    const proposeName = `${prefix} ${counter}`;
    const hasFound = steps.find((step) => step.data.title === proposeName);
    if (!hasFound) return proposeName;
    return getNextAvailableStepName(type, prefix, counter + 1);
  };

  const onAddStep = (type: StepType, nodePosition?: XYPosition, title?: string) => {
    let newStep: StepNode | null = null;
    const pane = ref.current.getBoundingClientRect();
    const viewPort = rfInstance?.getViewport();
    const x = (viewPort?.x / viewPort?.zoom) * -1 + pane?.width / viewPort?.zoom / 2;
    const y = (viewPort?.y / viewPort?.zoom) * -1 + pane?.height / viewPort?.zoom / 3;
    const centerY = y + pane?.height / viewPort?.zoom / 3;
    rfInstance?.setCenter(x, centerY, {
      duration: 1500,
      zoom: viewPort?.zoom,
    });
    const position = nodePosition
      ? nodePosition
      : {
          x,
          y,
        };

    switch (type) {
      case 'action':
        newStep = StepFactory({
          type,
          position,
          initialValues: {
            title: title ? `${title} Action` : getNextAvailableStepName(type, 'Action'),
          },
        });
        break;
      case 'template':
        newStep = StepFactory({
          type,
          position,
          initialValues: {
            title: title
              ? `${title} Message Template`
              : getNextAvailableStepName(type, 'Send Message Template'),
          },
        });
        break;
      case 'message':
        newStep = StepFactory({
          type,
          position,
          initialValues: {
            title: title ? `${title} Message` : getNextAvailableStepName(type, 'Send Message'),
          },
        });
        break;
      case 'start-flow':
        newStep = StepFactory({
          type,
          position,
          initialValues: {
            title: title ? `${title} Flow` : getNextAvailableStepName(type, 'Start Flow'),
            contents: [ContentFactory({ type: 'add-flow' })],
          },
        });
        break;
      case 'send-form':
        newStep = StepFactory({
          type,
          position,
          initialValues: {
            title: title ? `${title} Form` : getNextAvailableStepName(type, 'Form'),
            contents: [ContentFactory({ type: 'send-form' })],
          },
        });
        break;
      case 'round-robin':
        newStep = StepFactory({
          type,
          position,
          initialValues: {
            title: title || getNextAvailableStepName(type, 'Round Robin'),
          },
        });
        break;
      case 'smart-delay':
        newStep = StepFactory({
          type,
          position,
          initialValues: {
            title: title || getNextAvailableStepName(type, 'Smart Delay'),
          },
        });
        break;
      case 'condition':
        newStep = StepFactory({
          type,
          position,
          initialValues: {
            title: title || getNextAvailableStepName(type, 'Condition'),
          },
        });
        break;
      case 'randomizer':
        newStep = StepFactory({
          type,
          position,
          initialValues: {
            title: title || getNextAvailableStepName(type, 'Randomizer'),
          },
        });
        break;
      default:
        break;
    }

    if (newStep) {
      setSteps([...steps, newStep]);
    }

    return newStep;
  };

  const onDuplicateStep = (step: StepNode) => {
    setSteps((prevSteps) => {
      const newUuid = uuidv4();
      const contents = step?.data.contents?.map((content) => {
        let final = {
          ...content,
          id: uuidv4(),
        };
        if (content?.type === 'text' && content?.data?.buttons?.length > 0) {
          const buttons = content?.data?.buttons.map((b) => {
            return {
              ...b,
              id: uuidv4(),
              clicked: 0,
            };
          });
          final = {
            ...final,
            data: {
              ...final.data,
              buttons,
            },
          };
        }
        if (
          (content?.type === 'webhook' ||
            content?.type === 'send-form' ||
            content?.type === 'smart-delay' ||
            content?.type === 'wait-reply' ||
            content?.type === 'condition') &&
          content?.data?.response?.length > 0
        ) {
          const _response = content?.data?.response.map((b) => {
            return {
              ...b,
              id: uuidv4(),
            };
          });
          final = {
            ...final,
            data: {
              ...final.data,
              response: _response,
            },
          };
        }
        if (content?.type === 'round-robin-steps' && content?.data?.steps?.length > 0) {
          const _steps = content?.data?.steps.map((b) => {
            return {
              ...b,
              id: uuidv4(),
            };
          });
          final = {
            ...final,
            data: {
              ...final.data,
              steps: _steps,
            },
          };
        }
        return final;
      });
      const newStep: StepNode = {
        id: newUuid,
        type: step?.type,
        position: {
          x: step?.xPos + 400,
          y: step?.yPos,
        },
        data: {
          ...step?.data,
          id: newUuid,
          contents,
        },
      };
      return [...prevSteps, newStep];
    });
  };

  const toggleStepDrawer = (state: boolean) => {
    setIsShowStepDrawer(state);
  };

  const onSetCurrentSelectedStep = (step: Node<Step>) => {
    setCurrentSelectedStep(step);
  };

  const onUpdateStep = ({
    stepId,
    title,
    contents,
  }: {
    stepId?: string;
    title?: string;
    contents?: IContent[];
  }) => {
    let _step = null;
    if (stepId) {
      _step = steps.find(({ id }) => id === stepId);
    } else {
      _step = currentSelectedStep;
    }

    const newCurrentStep: Node<Step> = {
      ..._step,
    };
    if (contents) {
      newCurrentStep.data.contents = contents;
    }
    if (title) {
      newCurrentStep.data.title = title;
    }
    setCurrentSelectedStep(newCurrentStep);
    setSteps((prevSteps) => {
      const newSteps: StepNode[] = prevSteps.slice();
      const foundIndex = _findIndex(steps, { id: _step.data.id });
      if (foundIndex > -1) {
        const newStep: StepNode = {
          ...newSteps[foundIndex],
          position: {
            x: _step?.position.x || 100,
            y: _step?.position.y || 150,
          },
        };
        if (contents) {
          newSteps[foundIndex].data.contents = contents;
        }
        if (title) {
          newSteps[foundIndex].data.title = title;
        }
        newSteps[foundIndex] = newStep;
      }
      return newSteps;
    });
  };

  const onShowStep = (step: Node<Step>) => {
    setCurrentSelectedStep(step);
    setIsShowStepDrawer(true);
  };

  const onDeleteStep = (step: Node<Step>) => {
    const foundIndex = _findIndex(steps, { id: step?.data.id });
    if (foundIndex > -1) {
      setSteps((prevSteps) => {
        const newSteps: StepNode[] = prevSteps.slice();
        newSteps.splice(foundIndex, 1);
        return newSteps;
      });
      const deletedNode: string = _get(steps, `${foundIndex}.data.id`, '');
      if (deletedNode) {
        onDeleteLinkBySource(deletedNode);
        onDeleteLinkByTarget(deletedNode);
        setDeletedNodes((prevDeletedNodes) => {
          return [...prevDeletedNodes, `node.${deletedNode}`];
        });
      }
    }
  };

  const onDeleteLinkByTarget = (target: string) => {
    const deletedEdge = edges.filter((data) => data.target === target);
    const deletedNode: string[] = deletedEdge?.map((edge) => `edge.${edge.id}`);
    if (deletedNode) {
      setEdges((prevEdges) => {
        const newEdges = prevEdges.filter((data) => data.target !== target);
        return newEdges;
      });
      setDeletedNodes((prevDeletedNodes) => {
        return [...prevDeletedNodes, `edge.${deletedNode}`];
      });
    }
  };

  const onDeleteLinkBySource = (source: string) => {
    const deletedEdge = edges.filter((data) => data.source === source);
    const deletedNode: string[] = deletedEdge?.map((edge) => `edge.${edge.id}`);
    if (deletedNode) {
      setEdges((prevEdges) => {
        const newEdges = prevEdges.filter((data) => data.source !== source);
        return newEdges;
      });
      setDeletedNodes((prevDeletedNodes) => {
        return [...prevDeletedNodes, ...deletedNode];
      });
    }
  };

  const onDeleteLinkBySourceHandle = (sourceHandle: string) => {
    const deletedEdge = edges.filter((data) => data.sourceHandle === sourceHandle);
    const deletedNode: string[] = deletedEdge?.map((edge) => `edge.${edge.id}`);
    if (deletedNode) {
      setEdges((prevEdges) => {
        const newEdges = prevEdges.filter((data) => data.sourceHandle !== sourceHandle);
        return newEdges;
      });
      setDeletedNodes((prevDeletedNodes) => {
        return [...prevDeletedNodes, `edge.${deletedNode}`];
      });
    }
  };

  const onUpdateFlowConditions = async ({ conditions = [] }: { conditions: FlowCondition[] }) => {
    setFlowConditions(conditions);
  };

  const onNodeValidation = (
    nodes: Node<Step>[],
  ): {
    status: boolean;
    errors: string[];
    errorPosition: null | { x: number; y: number; options: SetCenterOptions };
  } => {
    if (nodes.length === 0)
      return { status: false, errors: ['No content found in the flow'], errorPosition: null };
    const errors: string[] = [];
    let errorPosition: null | { x: number; y: number; options: SetCenterOptions } = null;
    nodes.forEach((node) => {
      if (node.type !== 'starting' && node.data.contents?.length === 0) {
        errors.push(`Node "${node.data.title}" has no content.`);
      }
      switch (node.type) {
        case 'starting':
          if (node.data.contents && node.data.contents?.length > 0) {
            node.data.contents?.forEach((content) => {
              if (content.type === 'keyword') {
                if (content.data.keywords?.length === 0) {
                  errors.push(`In Starting Step, the Keyword Trigger is missing the some keyword.`);
                }
              }
              if (content.type === 'whatsapp-link') {
                if (!content.data.message) {
                  errors.push(
                    `In Starting Step, the WhatsApp Link Trigger is missing the some message.`,
                  );
                }
              }
            });
          }
          return true;
        case 'start-flow':
          node.data.contents?.forEach((content) => {
            if (content.type === 'add-flow') {
              if (content.data.flow?.length === 0) {
                errors.push(`The Start Flow Node "${node.data.title}" is missing the next flow.`);
              }
            }
          });
          return true;
        case 'round-robin':
          node.data.contents?.forEach((content) => {
            if (content.type === 'round-robin-steps') {
              if (content.data.steps.length === 0) {
                errors.push(
                  `In Round Robin Node "${node.data.title}", please define the missing steps.`,
                );
              } else {
                content.data.steps?.forEach((step, j) => {
                  const nextStep = step?.id ? getLinkedTargetBySourceHandle(step.id) : null;
                  if (!nextStep) {
                    errors.push(
                      `In Round Robin Node "${node.data.title}", Round ${
                        j + 1
                      } is missing the next action.`,
                    );
                  }
                });
              }
            }
          });
          break;
        case 'message':
          node.data.contents?.forEach((content) => {
            if (content.type === 'text') {
              if (!content.data.text) {
                errors.push(
                  `In Message Node "${node.data.title}", the Content Block "Text" is missing some text.`,
                );
              }
            }
            if (content.type === 'image') {
              if (!content.data.url) {
                errors.push(
                  `In Message Node "${node.data.title}", the Content Block "Image" is missing some image.`,
                );
              }
            }
            if (content.type === 'document') {
              if (!content.data.url) {
                errors.push(
                  `In Message Node "${node.data.title}", the Content Block "File" is missing some file.`,
                );
              }
            }
            if (content.type === 'video') {
              if (!content.data.url) {
                errors.push(
                  `In Message Node "${node.data.title}", the Content Block "Video" is missing a video file.`,
                );
              }
            }
            if (content.type === 'audio') {
              if (!content.data.url) {
                errors.push(
                  `In Message Node "${node.data.title}", the Content Block "Audio" is missing an audio file.`,
                );
              }
            }
          });
          break;
        case 'action':
          node.data.contents?.forEach((content) => {
            if (content.type === 'assign-inbox-tab') {
              if (content.data.tab?.length === 0) {
                errors.push(
                  `In Action Node "${node.data.title}", the Content Block "Inbox Tab Assignment" is missing some inbox tab.`,
                );
              }
            }
            if (content.type === 'webhook') {
              if (!content.data.url || !content.data.method) {
                errors.push(
                  `In Action Node "${node.data.title}", the Content Block "Webhook" is missing the Webhook URL.`,
                );
              }
            }
            if (content.type === 'add-tag') {
              if (content.data.tags?.length === 0) {
                errors.push(
                  `In Action Node "${node.data.title}", the Content Block "Add Tag" is missing some tag.`,
                );
              }
            }
            if (content.type === 'add-assignee') {
              if (content.data.assignee?.length === 0) {
                errors.push(
                  `In Action Node "${node.data.title}", the Content Block "Add Assignee" is missing some assignee.`,
                );
              }
            }
            if (content.type === 'add-collaborator') {
              if (content.data.collaborators?.length === 0) {
                errors.push(
                  `In Action Node "${node.data.title}", the Content Block "Add Collaborator" is missing some collaborator.`,
                );
              }
            }
            if (content.type === 'add-attribute') {
              if (content.data.attributes?.length === 0) {
                errors.push(
                  `In Action Node "${node.data.title}", the Content Block "Save to Attribute"  is missing some attribute.`,
                );
              }
            }
          });
          break;
        default:
      }
      if (errors?.length > 0 && errorPosition === null) {
        errorPosition = {
          x: node.position.x + 150,
          y: node.position.y + 135,
          options: { zoom: 1, duration: 1000 },
        };
      }
      return true;
    });
    return {
      status: errors?.length > 0 ? false : true,
      errors,
      errorPosition,
    };
  };

  const onRemoveInvalidNodes = (_nodes: Node<Step>[]) => {
    const finalNodes: Node<Step>[] = [];
    if (!_nodes || _nodes?.length === 0) return [];
    _nodes.forEach((node) => {
      if (node?.type === 'message') {
        const _node = {
          ...node,
          data: {
            ...node?.data,
            contents: node?.data?.contents?.filter((c) => c?.type !== 'smart-delay'),
          },
        };
        finalNodes.push(_node);
      } else {
        finalNodes.push(node);
      }
    });
    return finalNodes;
  };

  const onGoToErrorStep = (nodes: Node<Step>[], parent_id: string) => {
    const foundIndex = nodes.findIndex((node) => node.id === parent_id);
    const node = nodes[foundIndex];
    const errorPosition = {
      x: node.position.x + 150,
      y: node.position.y + 135,
      options: { zoom: 1, duration: 1000 },
    };
    message.error(`Please remove "${node?.data?.title}" node and recreate it.`);
    rfInstance?.setCenter(errorPosition.x, errorPosition.y, errorPosition.options);
  };

  const onPublish = async () => {
    if (rfInstance && flow) {
      const _flow = rfInstance?.toObject();
      if (_flow) {
        if (flow.type === 'away') {
          if (!flow.conditions || flow.conditions.length === 0) {
            message.error('Please configure your working hours');
            setIsSaving(false);
            return;
          }
        }
        const newSteps = onRemoveInvalidNodes(steps);
        const validation = onNodeValidation(newSteps);
        if (validation?.status) {
          const res = await fetchUpdateFlow({
            id: flow.id,
            title: flowTitle,
            conditions: flowConditions,
            deleted_nodes: deletedNodes,
            drawing_data: {
              edges,
              nodes: newSteps,
              viewport: _flow.viewport,
            },
          });
          if (res.status) {
            message.success('Flow updated.');
            window.location.href = `/automations/flow/details/${flow.id}`;
          } else {
            if (res?.message?.startsWith('Duplicated content ID found:')) {
              onGoToErrorStep(newSteps, res?.data?.parent_id);
            }
          }
        } else {
          if (validation?.errorPosition) {
            rfInstance?.setCenter(
              validation.errorPosition.x,
              validation.errorPosition.y,
              validation.errorPosition.options,
            );
          }
          return validation;
        }
      }
    }
  };

  // auto save for draft mode
  useEffect(() => {
    if (mode === 'draft' && isReadyForEdit?.current === true) {
      if (steps.length > 0 && flow && flow.id && rfInstance) {
        setIsSaving(true);
        save({
          title: flowTitle,
          deleted_nodes: deletedNodes,
          conditions: flowConditions,
          drawing_data: {
            edges,
            nodes: steps,
            viewport: rfInstance.toObject().viewport,
          },
          id: flow.id,
          callback: () => {
            setIsSaving(false);
          },
        });
      }
    }
  }, [mode, edges, steps, rfInstance, flow, flowTitle, deletedNodes, flowConditions]);

  const initOptInStep = () => {
    const startingStep = StepFactory({
      type: 'starting',
      initialValues: {
        title: `Starting Step`,
        contents: [
          ContentFactory({
            type: TriggerType.KEYWORD,
            initialValues: {
              condition: 'is',
              keywords: ['OPT_IN'],
            },
          }),
        ],
      },
    });
    const actionStep = StepFactory({
      type: 'action',
      position: {
        x: 625,
        y: 150,
      },
      initialValues: {
        title: `Opt In`,
        contents: [
          ContentFactory({
            type: 'opt-in',
          }),
        ],
      },
    });
    const messageStep = StepFactory({
      type: 'message',
      position: {
        x: 1150,
        y: 150,
      },
      initialValues: {
        title: `Send Message 1`,
        contents: [
          ContentFactory({
            type: 'text',
            initialValues: {
              text: "Thank you for joining our community! You are now subscribed to receive our latest updates, exclusive offers, and more. Stay tuned for exciting news and promotions straight to your inbox. If you ever wish to opt-out, simply reply with 'OPT_OUT'. We're thrilled to have you onboard!",
            },
          }),
        ],
      },
    });
    setSteps([startingStep, actionStep, messageStep]);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onConnect({
      source: _get(startingStep, 'id'),
      sourceHandle: 'next',
      target: _get(actionStep, 'id'),
    });
    onConnect({
      source: _get(actionStep, 'id'),
      sourceHandle: 'next',
      target: _get(messageStep, 'id'),
    });
  };

  const initOptOutStep = () => {
    const startingStep = StepFactory({
      type: 'starting',
      initialValues: {
        title: `Starting Step`,
        contents: [
          ContentFactory({
            type: TriggerType.KEYWORD,
            initialValues: {
              condition: 'is',
              keywords: ['OPT_OUT'],
            },
          }),
        ],
      },
    });
    const messageStep = StepFactory({
      type: 'message',
      position: {
        x: 1150,
        y: 150,
      },
      initialValues: {
        title: `Send Message 1`,
        contents: [
          ContentFactory({
            type: 'text',
            initialValues: {
              text: "We're sorry to see you go. You have been successfully unsubscribed from our updates. Should you change your mind in the future, feel free to opt back in by sending us a message with the keyword 'OPT_IN'. Thank you for being a part of our community!",
            },
          }),
        ],
      },
    });
    const actionStep = StepFactory({
      type: 'action',
      position: {
        x: 625,
        y: 150,
      },
      initialValues: {
        title: `Opt Out`,
        contents: [
          ContentFactory({
            type: 'opt-out',
          }),
        ],
      },
    });
    setSteps([startingStep, actionStep, messageStep]);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onConnect({
      source: _get(startingStep, 'id'),
      sourceHandle: 'next',
      target: _get(actionStep, 'id'),
    });
    onConnect({
      source: _get(actionStep, 'id'),
      sourceHandle: 'next',
      target: _get(messageStep, 'id'),
    });
  };

  const onRestore = useCallback(
    ({ drawing_data, title, type, conditions }) => {
      setFlowTitle(title);
      setFlowConditions(conditions);
      const _steps = _get(drawing_data, 'nodes', []);
      if (_steps.length > 0) {
        const _edges = _get(drawing_data, 'edges', []);
        const _deleted_nodes = _get(drawing_data, 'deleted_nodes', []);
        // const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setSteps(_steps);
        setEdges(_edges);
        setDeletedNodes(_deleted_nodes);
      } else {
        if (type === FlowType.OPT_IN) {
          initOptInStep();
        } else if (type === FlowType.OPT_OUT) {
          initOptOutStep();
        } else {
          let defaultMessage = '';
          if (type === FlowType.AWAY) {
            defaultMessage =
              "Thank you for your message. We're unavailable right now, but will respond as soon as possible.";
          }
          if (type === FlowType.DEFAULT) {
            defaultMessage =
              "Hello {{Full Name}} 👋! Good to see you. Please tell us how we can help you and we'll get in touch shortly.";
          }
          const startingStep = StepFactory({
            type: 'starting',
          });
          const messageStep = StepFactory({
            type: 'message',
            position: {
              x: 625,
              y: 150,
            },
            initialValues: {
              title: `Send Message 1`,
              contents: [
                ContentFactory({
                  type: 'text',
                  initialValues: {
                    text: defaultMessage,
                  },
                }),
              ],
            },
          });
          setSteps([startingStep, messageStep]);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          onConnect({
            source: _get(startingStep, 'id'),
            sourceHandle: 'next',
            target: _get(messageStep, 'id'),
          });
        }
      }
      setTimeout(() => (isReadyForEdit.current = true), 1000);
    },
    [setSteps, setEdges],
  );

  const onAddStartFlowAndLinkToIt = ({ buttonId, title }: { buttonId: string; title?: string }) => {
    const _step = onAddStep(
      'start-flow',
      {
        x: (currentSelectedStep?.position?.x || 0) + 450,
        y: currentSelectedStep?.position.y || 0,
      },
      title,
    );
    if (_step) {
      setCurrentSelectedStep(_step);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onConnect({
        source: _get(currentSelectedStep, 'id'),
        sourceHandle: buttonId,
        target: _get(_step, 'id'),
      });
    }
  };

  const onAddMessageAndLinkToIt = ({ buttonId, title }: { buttonId: string; title?: string }) => {
    const _step = onAddStep(
      'message',
      {
        x: (currentSelectedStep?.position?.x || 0) + 450,
        y: currentSelectedStep?.position.y || 0,
      },
      title,
    );
    if (_step) {
      setCurrentSelectedStep(_step);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onConnect({
        source: _get(currentSelectedStep, 'id'),
        sourceHandle: buttonId,
        target: _get(_step, 'id'),
      });
    }
  };

  const onAddActionAndLinkToIt = ({ buttonId, title }: { buttonId: string; title?: string }) => {
    const _step = onAddStep(
      'action',
      {
        x: (currentSelectedStep?.position?.x || 0) + 450,
        y: currentSelectedStep?.position.y || 0,
      },
      title,
    );
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onConnect({
      source: _get(currentSelectedStep, 'id'),
      sourceHandle: buttonId,
      target: _get(_step, 'id'),
    });
  };

  const onAddTrigger = (triggerType: TriggerType) => {
    let initialSetup = {};
    const contents = _get(currentSelectedStep, 'data.contents', []);
    switch (triggerType) {
      case TriggerType.KEYWORD:
        initialSetup = ContentFactory({ type: TriggerType.KEYWORD });
        break;
      case TriggerType.WEBHOOK:
        initialSetup = ContentFactory({ type: TriggerType.WEBHOOK });
        break;
      case TriggerType.EVENT_TRIGGER:
        initialSetup = ContentFactory({ type: TriggerType.EVENT_TRIGGER });
        break;
      case TriggerType.WHATSAPP_LINK:
        const foundGrowthTool = contents.filter(({ type = '' }) => type === 'whatsapp-link');
        const label = `Growth Tool #${foundGrowthTool.length + 1}`;
        initialSetup = ContentFactory({
          type: TriggerType.WHATSAPP_LINK,
          initialValues: { label },
        });
        break;
    }
    if (currentSelectedStep?.data?.contents && currentSelectedStep?.data?.contents?.length > 0) {
      let hasDuplicatedType = false;
      currentSelectedStep?.data?.contents?.forEach(({ type }) => {
        if (type === TriggerType.WEBHOOK) {
          hasDuplicatedType = true;
        }
      });
      if (triggerType === TriggerType.WEBHOOK && hasDuplicatedType) {
        message.error('You can only have one Webhook per Flow.');
        return false;
      }
    }
    onUpdateStep({ contents: [...contents, initialSetup] });
  };

  const onAddAction = (actionType: ActionType | StartFlowType) => {
    if (actionType !== 'send-message') {
      if (currentSelectedStep?.data?.contents && currentSelectedStep?.data?.contents?.length > 0) {
        let hasDuplicatedType = false;
        let hasWaitForReplyNode = false;
        currentSelectedStep?.data?.contents?.forEach(({ type }) => {
          if (actionType === type) {
            hasDuplicatedType = true;
          }
          if (type === 'wait-reply' || type === 'add-attribute') {
            hasWaitForReplyNode = true;
          }
        });
        if (hasDuplicatedType) {
          message.error('This action already exist in current Action Node.');
          return false;
        }
        if (actionType === 'add-attribute' && hasWaitForReplyNode) {
          message.error('Wait for Reply and Save to Attribute cannot coexist in the same node.');
          return false;
        }
      }
    }

    const initialSetup = ContentFactory({ type: actionType });
    const contents = _get(currentSelectedStep, 'data.contents', []);
    onUpdateStep({ contents: [...contents, initialSetup] });
  };

  const onAddContent = (contentType: ContentType) => {
    let initialSetup = {};
    const contents = _get(currentSelectedStep, 'data.contents', []);
    switch (contentType) {
      case ContentType.IMAGE:
        initialSetup = ContentFactory({ type: ContentType.IMAGE });
        break;
      case ContentType.TEXT:
        initialSetup = ContentFactory({ type: ContentType.TEXT });
        break;
      case ContentType.DOCUMENT:
        initialSetup = ContentFactory({ type: ContentType.DOCUMENT });
        break;
      case ContentType.VIDEO:
        initialSetup = ContentFactory({ type: ContentType.VIDEO });
        break;
      case ContentType.AUDIO:
        initialSetup = ContentFactory({ type: ContentType.AUDIO });
        break;
      default:
        break;
    }
    onUpdateStep({ contents: [...contents, initialSetup] });
  };

  const onDeleteContent = ({ contentId }: { contentId: string }) => {
    const prevContents = _get(currentSelectedStep, 'data.contents', []);
    const contents = prevContents.filter(({ id }: { id: string }) => id !== contentId);
    const deletedNodeArr = prevContents.filter(({ id }: { id: string }) => id === contentId);
    const deletedNode = _get(deletedNodeArr, '0.id');
    const buttons = _get(deletedNodeArr, '0.data.buttons', []);
    if (deletedNode) {
      if (buttons?.length > 0) {
        buttons.forEach((button) => {
          if (button?.id) {
            onDeleteLinkBySourceHandle(button?.id);
          }
        });
      }
      setDeletedNodes((prevDeletedNodes) => {
        return [...prevDeletedNodes, `content.${deletedNode}`];
      });
    }
    const _steps = _get(deletedNodeArr, '0.data.steps', []);
    if (deletedNode) {
      if (_steps?.length > 0) {
        _steps.forEach((step) => {
          if (step?.id) {
            onDeleteLinkBySourceHandle(step?.id);
          }
        });
      }
      setDeletedNodes((prevDeletedNodes) => {
        return [...prevDeletedNodes, `content.${deletedNode}`];
      });
    }
    onUpdateStep({ contents });
  };

  const moveContentUp = ({ contentId }: { contentId: string }) => {
    const prevContents = _get(currentSelectedStep, 'data.contents', []);
    const contentIndex = prevContents.findIndex(({ id }: { id: string }) => id === contentId);
    if (contentIndex > 0) {
      const newIndex = contentIndex - 1;
      let tempContent = null;
      tempContent = prevContents[newIndex];
      prevContents[newIndex] = prevContents[contentIndex];
      prevContents[contentIndex] = tempContent;
    }
    onUpdateStep({ contents: prevContents });
  };

  const moveContentDown = ({ contentId }: { contentId: string }) => {
    const prevContents = _get(currentSelectedStep, 'data.contents', []);
    const contentIndex = prevContents.findIndex(({ id }: { id: string }) => id === contentId);
    if (contentIndex < prevContents.length - 1) {
      const newIndex = contentIndex + 1;
      let tempContent = null;
      tempContent = prevContents[newIndex];
      prevContents[newIndex] = prevContents[contentIndex];
      prevContents[contentIndex] = tempContent;
    }
    onUpdateStep({ contents: prevContents });
  };

  const onUpdateContent = ({ contentId, data }: { contentId: string; data: any }) => {
    const contents: IContent[] = _get(currentSelectedStep, 'data.contents', []);
    const index = _findIndex(contents, ({ id }) => id === contentId);
    if (index > -1) {
      contents[index].data = {
        ...contents[index].data,
        ...data,
      };
      onUpdateStep({ contents });
    }
  };

  const onAddRandomizerStep = () => {
    const contents: IContent[] = _get(currentSelectedStep, 'data.contents', []);
    // hardcode to assign to first index, because we expect only have one content
    if (contents?.length > 0) {
      const newType = uuidv4().split('-')[0];
      contents[0].data.chances = [
        ...contents[0].data.chances,
        {
          action_type: newType,
          probability: 0,
        },
      ];
      contents[0].data.response = [
        ...contents[0].data.response,
        {
          type: newType,
          id: uuidv4(),
        },
      ];
    }
    onUpdateStep({
      contents,
    });
  };

  const onAddRoundRobinStep = () => {
    const contents: IContent[] = _get(currentSelectedStep, 'data.contents', []);
    // hardcode to assign to first index, because we expect only have one content
    if (contents?.length > 0) {
      if (!contents[0].data.steps) {
        contents[0].data.steps = [];
      }
      contents[0].data.steps.push({
        id: uuidv4(),
        type: null,
      });
    }
    onUpdateStep({
      contents,
    });
  };

  const onAddContentButton = ({
    contentId,
    contentIndex,
    buttonLimit,
  }: {
    contentId: string;
    contentIndex: number;
    buttonLimit: number;
  }) => {
    const contents: IContent[] = _get(currentSelectedStep, 'data.contents', []);

    const index = _findIndex(contents, ({ id }) => id === contentId);
    if (index > -1) {
      const buttons = _get(contents, `${index}.data.buttons`, []);
      const buttonId = uuidv4();
      let buttonCounter = 1;
      let portType = `${contentIndex}-button-1`;
      if (buttons.length > 0) {
        const hasButtonExist: Record<string, boolean> = {};
        for (let i = 0; i < buttonLimit; i++) {
          hasButtonExist[`${contentIndex}-button-${i + 1}`] = false;
        }
        contents[index].data.buttons?.map((button) => {
          hasButtonExist[button.portType] = true;
        });
        let counter = 0;
        for (const key in hasButtonExist) {
          counter++;
          if (hasButtonExist[key] === false) {
            portType = key;
            buttonCounter = counter;
            break;
          }
        }
        if (portType) {
          contents[index].data.buttons?.push({
            id: buttonId,
            title:
              initialState?.currentChannel?.type === 'waba'
                ? `New button #${buttonCounter}`
                : `New reply #${buttonCounter}`,
            type: '',
            portType,
          });
        }
      } else {
        contents[index] = {
          ...contents[index],
          data: {
            ...contents[index]?.data,
            buttons: [
              {
                id: buttonId,
                title:
                  initialState?.currentChannel?.type === 'waba' ? `New button #1` : `New reply #1`,
                type: '',
                portType,
              },
            ],
          },
        };
      }
      onUpdateStep({ contents });
    }
  };

  const onDeleteContentButton = ({
    contentId,
    buttonId,
  }: {
    contentId: string;
    buttonId: string;
  }) => {
    const contents: IContent[] = _get(currentSelectedStep, 'data.contents', []);
    const foundIndex = _findIndex(contents, ({ id }) => id === contentId);
    if (foundIndex > -1) {
      const buttons = _get(contents, `${foundIndex}.data.buttons`, []);
      const buttonIndex = _findIndex(buttons, ({ id }) => id === buttonId);
      const deletedNode = _get(buttons, `${buttonIndex}.id`);
      buttons.splice(buttonIndex, 1);
      contents[foundIndex].data.buttons = buttons;
      if (deletedNode) {
        onDeleteLinkBySourceHandle(deletedNode);
        setDeletedNodes((prevDeletedNodes) => {
          return [...prevDeletedNodes, `content.${deletedNode}`];
        });
      }
      onUpdateStep({ contents });
    }
  };

  const onUpdateContentButton = ({
    stepId,
    contentId,
    button,
  }: {
    stepId?: string;
    contentId: string;
    button: IMessageButton;
  }) => {
    let _step = null;
    if (stepId) {
      _step = steps.find(({ id }) => id === stepId);
    } else {
      _step = currentSelectedStep;
    }
    if (!_step) return;
    const contents: IContent[] = _get(_step, 'data.contents', []);
    const index = _findIndex(contents, ({ id }) => id === contentId);
    if (index > -1) {
      const buttons = _get(contents, `${index}.data.buttons`, []);
      if (buttons.length > 0) {
        const buttonIndex = _findIndex(buttons, ({ id }) => id === button.id);
        if (buttonIndex > -1) {
          if (!_isEmpty(buttons[buttonIndex])) {
            contents[index].data.buttons[buttonIndex] = button;
            onUpdateStep({ stepId: _step?.id, contents });
          }
        }
      }
    }
  };

  const onDeleteRandomizerRoute = ({
    contentId,
    actionType,
  }: {
    contentId: string;
    actionType: string;
  }) => {
    const contents: IContent[] = _get(currentSelectedStep, 'data.contents', []);
    const foundIndex = _findIndex(contents, ({ id }) => id === contentId);
    if (foundIndex > -1) {
      const _chances = _get(contents, `${foundIndex}.data.chances`, []);
      const chanceIndex = _findIndex(_chances, ({ action_type }) => action_type === actionType);
      _chances.splice(chanceIndex, 1);
      contents[foundIndex].data.chances = _chances;

      const _responses = _get(contents, `${foundIndex}.data.response`, []);
      const responseIndex = _findIndex(_responses, ({ type }) => type === actionType);
      const deletedNode = _get(_responses, `${responseIndex}.id`);
      _responses.splice(responseIndex, 1);
      contents[foundIndex].data.response = _responses;

      if (deletedNode) {
        onDeleteLinkBySourceHandle(deletedNode);
        setDeletedNodes((prevDeletedNodes) => {
          return [...prevDeletedNodes, `content.${deletedNode}`];
        });
      }
      onUpdateStep({ contents });
    }
  };

  const onChangeRandomizerProbability = ({
    contentId,
    actionType,
    value,
  }: {
    contentId: string;
    actionType: string;
    value: number;
  }) => {
    const contents: IContent[] = _get(currentSelectedStep, 'data.contents', []);
    const foundIndex = _findIndex(contents, ({ id }) => id === contentId);
    if (foundIndex > -1) {
      const _chances = _get(contents, `${foundIndex}.data.chances`, []);
      const chanceIndex = _findIndex(_chances, ({ action_type }) => action_type === actionType);
      _chances[chanceIndex] = {
        ..._chances[chanceIndex],
        probability: value,
      };
      contents[foundIndex].data.chances = _chances;
      onUpdateStep({ contents });
    }
  };

  const onDeleteContentStep = ({ contentId, stepId }: { contentId: string; stepId: string }) => {
    const contents: IContent[] = _get(currentSelectedStep, 'data.contents', []);
    const foundIndex = _findIndex(contents, ({ id }) => id === contentId);
    if (foundIndex > -1) {
      const _steps = _get(contents, `${foundIndex}.data.steps`, []);
      const stepIndex = _findIndex(_steps, ({ id }) => id === stepId);
      const deletedNode = _get(_steps, `${stepIndex}.id`);
      _steps.splice(stepIndex, 1);
      contents[foundIndex].data.steps = _steps;
      if (deletedNode) {
        onDeleteLinkBySourceHandle(deletedNode);
        setDeletedNodes((prevDeletedNodes) => {
          return [...prevDeletedNodes, `content.${deletedNode}`];
        });
      }
      onUpdateStep({ contents });
    }
  };

  const onUpdateContentSteps = ({
    stepId,
    contentId,
    step,
  }: {
    stepId?: string;
    contentId: string;
    step: IMessageButton;
  }) => {
    let _step = null;
    if (stepId) {
      _step = steps.find(({ id }) => id === stepId);
    } else {
      _step = currentSelectedStep;
    }
    if (!_step) return;
    const contents: IContent[] = _get(_step, 'data.contents', []);
    const index = _findIndex(contents, ({ id }) => id === contentId);
    if (index > -1) {
      const _steps = _get(contents, `${index}.data.steps`, []);
      if (_steps.length > 0) {
        const stepIndex = _findIndex(_steps, ({ id }) => id === step.id);
        if (stepIndex > -1) {
          if (!_isEmpty(steps[stepIndex])) {
            contents[index].data.steps[stepIndex] = step;
            onUpdateStep({ contents });
          }
        }
      }
    }
  };

  const updateDeletedEdgeToStepData = (deletedEdge: Edge) => {
    // update content
    if (deletedEdge.sourceHandle === 'next') {
      // is a step handle
    } else {
      // is a message button handle
      const foundStep = steps.find((step) => step.id === deletedEdge.source);
      if (foundStep) {
        const foundStepContents = _get(foundStep, 'data.contents', []);
        let foundContentId = null;
        let foundStepButton = {};
        if (foundStepContents.length > 0) {
          foundStepContents.forEach((content) => {
            const buttons = _get(content, 'data.buttons', []);
            if (buttons.length > 0) {
              const foundButton = buttons.find((button) => button.id === deletedEdge.sourceHandle);
              if (foundButton) {
                foundStepButton = foundButton;
                foundContentId = _get(content, 'id');
              }
            }
          });
        }
        if (foundContentId && foundStepButton) {
          const newButton = { ...foundStepButton };
          onUpdateContentButton({
            stepId: foundStep.id,
            contentId: foundContentId,
            button: newButton,
          });
        }
      }
    }
  };

  const onDeleteLink = (edgeId: string) => {
    setEdges((prevEdges) => {
      const deletedEdge = prevEdges.find((data) => data.id === edgeId);
      const deletedEdgeId: string = _get(deletedEdge, `id`, '');
      if (deletedEdgeId) {
        updateDeletedEdgeToStepData(deletedEdge);
        const newEdges = prevEdges.filter((data) => data.id !== edgeId);
        setDeletedNodes((prevDeletedNodes) => {
          return [...prevDeletedNodes, `edge.${deletedEdgeId}`];
        });
        return newEdges;
      } else {
        return prevEdges;
      }
    });
  };

  const onGetAllSteps = () => {
    const currentStepId = _get(currentSelectedStep, 'data.id');
    return steps.filter(({ type, data }) => type !== 'starting' && data.id !== currentStepId);
  };

  const onCheckAllowAddButtonToMessage = () => {
    if (edges?.length === 0 || !edges) return true;
    const targetEdge = edges.find(
      ({ source, sourceHandle }) => source === currentSelectedStep?.id && sourceHandle === 'next',
    );
    if (!targetEdge) return true;
    const targetStep = steps.find(({ id }) => id === targetEdge?.target);
    if (!targetStep || targetStep?.type === 'smart-delay') {
      return true;
    } else {
      setEdges((prevEdges) => {
        const newEdges = prevEdges.filter(
          (data) => data?.source === currentSelectedStep?.id && data.sourceHandle !== 'next',
        );
        return newEdges;
      });
      return false;
    }
  };

  const onConnect = useCallback(
    (connection) => {
      if (connection?.source === connection?.target) {
        message.error('You should not connect back to the same node');
        return;
      }
      if (connection?.sourceHandle === 'next') {
        const sourceStep = steps.find(({ id }) => id === connection?.source);
        if (sourceStep?.type === 'message') {
          let hasButtons = false;
          const messages = sourceStep?.data?.contents || [];
          if (messages && messages?.length > 0) {
            messages.forEach((message) => {
              if (message?.data?.buttons && message?.data?.buttons?.length > 0) {
                hasButtons = true;
              }
            });
          }
          const targetStep = steps.find(({ id }) => id === connection?.target);
          if (targetStep?.type !== 'smart-delay' && hasButtons) {
            if (targetStep?.type === 'action') {
              notification.error({
                message: 'Failed to link',
                description:
                  'You cannot link this node because your message contains reply or button options, and we expect the user to respond to them. The next step can only link to Smart Delay. If you need to add an action, please do so before this message node.',
                duration: 0,
              });
            } else {
              notification.error({
                message: 'Failed to link',
                description:
                  'You cannot link this node because your message contains reply or button options, and we expect the user to respond to them. The next step can only link to Smart Delay.',
                duration: 0,
              });
            }
            return;
          }
        }
      }

      // target cannot from the same node
      setEdges((eds) => {
        const newEdge = {
          ...connection,
          type: 'defaultEdge',
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 35,
            height: 35,
            color: '#FEE11C',
          },
        };
        const newEdges = eds.slice();
        const foundIndex = eds.findIndex(
          ({ source, sourceHandle }) =>
            source === connection.source && sourceHandle === connection.sourceHandle,
        );
        if (foundIndex > -1) {
          newEdges.splice(foundIndex, 1);
          newEdge.id = _get(eds, `${foundIndex}.id`);
        } else {
          newEdge.id = uuidv4();
        }
        setIsConnectingEdge(false);
        return addEdge(newEdge, newEdges);
      });
    },
    [setEdges, steps],
  );

  const onConnectToStep = ({
    contentId,
    buttonId,
    target,
  }: {
    contentId?: string;
    buttonId?: string;
    target: string;
  }) => {
    const selectedStep = steps.find(({ data }) => data.id === target);
    if (!selectedStep) return;
    if (contentId && buttonId) {
      const contents: IContent[] = _get(currentSelectedStep, 'data.contents', []);
      const index = _findIndex(contents, ({ id }) => id === contentId);
      if (index > -1) {
        onConnect({
          source: _get(currentSelectedStep, 'id'),
          sourceHandle: buttonId,
          target,
        });
      }
    } else {
      onConnect({
        source: _get(currentSelectedStep, 'id'),
        sourceHandle: 'next',
        target,
      });
    }
  };

  const onReset = () => {
    setEdges([]);
    setSteps([]);
    setFlow(null);
    isReadyForEdit.current = false;
    setIsShowStepDrawer(false);
  };

  const onChangeDiagramTitle = (e: string) => {
    setFlowTitle(e);
  };

  const onShowStartingStep = () => {
    if (steps.length === 0) return;
    const startingStep = steps.find((step) => step.type === 'starting');
    if (startingStep) {
      onShowStep(startingStep);
    }
  };

  const getLinkedTargetBySourceHandle = (selectedSourceHandle: string) => {
    const found = edges?.find(({ sourceHandle }) => sourceHandle == selectedSourceHandle);
    if (found && found?.target && steps?.length > 0) {
      const result = steps?.find(({ id }) => id === found?.target);
      return result;
    }
    return null;
  };

  const getIconBySourceHandle = (selectedSourceHandle: string) => {
    const found = edges?.find(({ sourceHandle }) => sourceHandle == selectedSourceHandle);
    if (found && found?.target && steps?.length > 0) {
      const result = steps?.find(({ id }) => id === found?.target);
      return result?.data?.type ? getIconByType(result?.data?.type) : '';
    }
    return '';
  };

  return {
    ref,
    flow,
    mode,
    setMode,
    flowTitle,
    flowConditions,
    setFlowConditions,
    onConnect,
    onConnectToStep,
    onNodesChange,
    onEdgesChange,
    rfInstance,
    setRfInstance,
    isSaving,
    setIsSaving,
    steps,
    setSteps,
    edges,
    setEdges,
    currentSelectedStep,
    isShowStepDrawer,
    onSetCurrentSelectedStep,
    toggleStepDrawer,
    onPublish,
    onGetAllSteps,
    onAddStep,
    onDuplicateStep,
    onShowStep,
    onShowStartingStep,
    onUpdateStep,
    onDeleteStep,
    onAddAction,
    onAddContent,
    onUpdateContent,
    onDeleteContent,
    onDeleteLink,
    onDeleteLinkBySourceHandle,
    onAddRoundRobinStep,
    onAddContentButton,
    onUpdateContentButton,
    onDeleteContentButton,
    onAddTrigger,
    onRestore,
    isConnectingEdge,
    setIsConnectingEdge,
    onReset,
    onChangeDiagramTitle,
    onAddMessageAndLinkToIt,
    onAddActionAndLinkToIt,
    onAddStartFlowAndLinkToIt,
    moveContentUp,
    moveContentDown,
    onUpdateContentSteps,
    onDeleteContentStep,
    onUpdateFlowConditions,
    getLinkedTargetBySourceHandle,
    getIconBySourceHandle,
    onAddRandomizerStep,
    onDeleteRandomizerRoute,
    onChangeRandomizerProbability,
    onCheckAllowAddButtonToMessage,
  };
};
