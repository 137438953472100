import type { Reminder } from '@/declarations/reminder';
import useModal from '@/hooks/useModal';
import {
  createReminder,
  deleteReminder,
  getReminder,
  getReminders,
  markReminderAsDone,
  updateReminder,
} from '@/services/luluchat/reminders';
import type { API } from '@/services/luluchat/typings.d';
import { useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const [currentReminder, setCurrentReminder] = useState<Reminder | null>(null);

  const {
    isModalVisible: isReminderFormOpen,
    openModal: openReminderFormModal,
    closeModal: closeReminderFormModal,
  } = useModal();

  const {
    data: reminders = {
      data: [] as Reminder[],
    } as API.GetRemindersResult,
    run: fetchGetReminders,
    loading: isLoadingFetchGetReminders,
  } = useRequest(getReminders, {
    manual: true,
    formatResult(res): API.GetRemindersResult {
      return res;
    },
  });

  const { run: fetchCreateReminder, loading: isLoadingFetchCreateReminder } = useRequest(
    createReminder,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const {
    data: reminder = {} as Reminder,
    run: fetchGetReminder,
    loading: isLoadingFetchGetReminder,
  } = useRequest(getReminder, {
    manual: true,
  });

  const { run: fetchUpdateReminder, loading: isLoadingFetchUpdateReminder } = useRequest(
    updateReminder,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchDeleteReminder, loading: isLoadingFetchDeleteReminder } = useRequest(
    deleteReminder,
    {
      manual: true,
      formatResult(res): API.DeleteReminderResult {
        return res;
      },
    },
  );

  const { run: fetchMarkReminderAsDone, loading: isLoadingFetchMarkReminderAsDone } = useRequest(
    markReminderAsDone,
    {
      manual: true,
      formatResult(res): API.MarkReminderAsDoneResult {
        return res;
      },
    },
  );

  return {
    reminders,
    fetchGetReminders,
    isLoadingFetchGetReminders,
    fetchCreateReminder,
    isLoadingFetchCreateReminder,
    reminder,
    fetchGetReminder,
    isLoadingFetchGetReminder,
    fetchUpdateReminder,
    isLoadingFetchUpdateReminder,
    fetchDeleteReminder,
    isLoadingFetchDeleteReminder,
    fetchMarkReminderAsDone,
    isLoadingFetchMarkReminderAsDone,
    currentReminder,
    setCurrentReminder,
    isReminderFormOpen,
    openReminderFormModal,
    closeReminderFormModal,
  };
};
