import { getShopifyAccessToken } from '@/services/luluchat/shopify';
import { useRequest } from 'umi';
export default () => {
  const { run: fetchGetShopifyAccessToken, loading: isLoadingFetchGetShopifyAccessToken } =
    useRequest(getShopifyAccessToken, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });
  return {
    fetchGetShopifyAccessToken,
    isLoadingFetchGetShopifyAccessToken,
  };
};
