import type { MessageTemplate } from '@/declarations/messageTemplate';
import {
  createMessageTemplate,
  deleteMessageTemplate,
  getMessageTemplate,
  getMessageTemplates,
  syncMessageTemplate,
  updateMessageTemplate,
} from '@/services/luluchat/messageTemplates';
import { useRequest } from 'umi';
export default () => {
  const {
    data: messageTemplates = [] as MessageTemplate[],
    run: fetchGetMessageTemplates,
    loading: isLoadingFetchGetMessageTemplates,
  } = useRequest(getMessageTemplates, { manual: true });

  const { run: fetchCreateMessageTemplate, loading: isLoadingFetchCreateMessageTemplate } =
    useRequest(createMessageTemplate, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const {
    data: messageTemplate = {} as MessageTemplate,
    run: fetchGetMessageTemplate,
    loading: isLoadingFetchGetMessageTemplate,
  } = useRequest(getMessageTemplate, {
    manual: true,
  });

  const { run: fetchSyncMessageTemplate, loading: isLoadingFetchSyncMessageTemplate } = useRequest(
    syncMessageTemplate,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateMessageTemplate, loading: isLoadingFetchUpdateMessageTemplate } =
    useRequest(updateMessageTemplate, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const { run: fetchDeleteMessageTemplate, loading: isLoadingFetchDeleteMessageTemplate } =
    useRequest(deleteMessageTemplate, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  return {
    messageTemplates,
    fetchGetMessageTemplates,
    isLoadingFetchGetMessageTemplates,
    fetchCreateMessageTemplate,
    isLoadingFetchCreateMessageTemplate,
    messageTemplate,
    fetchGetMessageTemplate,
    isLoadingFetchGetMessageTemplate,
    fetchUpdateMessageTemplate,
    isLoadingFetchUpdateMessageTemplate,
    fetchDeleteMessageTemplate,
    isLoadingFetchDeleteMessageTemplate,
    fetchSyncMessageTemplate,
    isLoadingFetchSyncMessageTemplate,
  };
};
