import type { Settings as LayoutSettings } from '@ant-design/pro-layout';

const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string;
  logoIcon?: string;
  brandColor?: string;
} = {
  navTheme: 'light',
  primaryColor: '#00BBCB',
  brandColor: '#FEE11C',
  layout: 'side',
  headerHeight: 63,
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: '',
  pwa: true,
  logo: '/logo.svg',
  logoIcon: '/logo_icon.svg',
  iconfontUrl: '/iconfont.js',
};

export default Settings;
