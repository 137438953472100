import type {
  Channel,
  Chatroom,
  ChatUpdate,
  ChatUpdateType,
  MessageUpdate,
  Notes,
} from '@/declarations/channel.d';
import { MessageStatus } from '@/declarations/channel.d';
import type { Tag } from '@/declarations/tag';
import {
  connectWABAChannel,
  createChannel,
  deleteNote,
  disconnect,
  editNote,
  getChannels,
  getNotes,
  instantiateChannel,
  refreshToken,
} from '@/services/luluchat/channels';
import type { API } from '@/services/luluchat/typings.d';
import { AblySubscription } from '@/utils/ably';
import { Sound } from '@/utils/sound';
import { message } from 'antd';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useModel, useRequest } from 'umi';

const soundInstance = Sound.getInstance();

function removeDuplicateReadMessages(messages: MessageUpdate[]) {
  const seenIds: Record<string, boolean> = {};
  let filteredMessages: MessageUpdate[] = [];

  messages.forEach((m) => {
    if (!seenIds.hasOwnProperty(m.id)) {
      seenIds[m.id] = m.status === MessageStatus.READ;
      filteredMessages.push(m);
    } else {
      if (m.status === MessageStatus.READ) {
        filteredMessages = filteredMessages.filter((a) => a.id !== m.id);
        filteredMessages.push(m);
        seenIds[m.id] = true;
      }
    }
  });

  return filteredMessages;
}

export default () => {
  const { setActiveTab, fetchGetInboxTabs } = useModel('inboxTab');
  const { setContact, fetchGetContact } = useModel('contact');
  const { initialState, fetchUserChannels } = useModel('@@initialState');
  const {
    setChats,
    updateWithLatestChat,
    setSelectedChat,
    reloadFetchGetChatroom,
    setIsLiveUpdateChatlist,
    setIsShowNewUpdatesOnChatList,
    setGlobalSearchKeyword,
    onReloadSearchChatroom,
  } = useModel('inbox');
  const {
    setHasNewMessageDuringDisableState,
    setIsDisableLiveMessages,
    setReactedMessages,
    setMessages,
  } = useModel('messenger');

  const { run: fetchDisconnect, loading: isLoadingFetchDisconnect } = useRequest(disconnect, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });
  const {
    data: channels = [] as Channel[],
    run: fetchGetChannels,
    loading: isLoadingFetchGetChannels,
  } = useRequest(getChannels, { manual: true });

  const {
    data: notes = [] as Notes[],
    run: fetchGetNotes,
    loading: isLoadingFetchGetNotes,
  } = useRequest(getNotes, { manual: true });

  const { run: fetchDeleteNote, loading: isLoadingDeleteNote } = useRequest(deleteNote, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchEditNote, loading: isLoadingEditNote } = useRequest(editNote, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchCreateChannel, loading: isLoadingFetchCreateChannel } = useRequest(
    createChannel,
    {
      manual: true,
      formatResult(res): API.Response<Channel> {
        return res;
      },
    },
  );
  const { run: fetchConnectWABAChannel, loading: isLoadingFetchConnectWABAChannel } = useRequest(
    connectWABAChannel,
    {
      manual: true,
      formatResult(res): API.Response<Channel> {
        return res;
      },
    },
  );

  const { run: fetchRefreshToken, loading: isLoadingFetchRefreshToken } = useRequest(refreshToken, {
    manual: true,
    formatResult(res): API.Response<Channel> {
      return res;
    },
  });

  const [currentChannel, setCurrentChannel] = useState<Channel>();
  const [qrCode, setQrCode] = useState<string>();

  useEffect(() => {
    if (initialState) {
      setCurrentChannel(initialState.currentChannel);
    }
  }, [initialState]);

  const fetchInstantiateChannel = async () => {
    if (_isEmpty(currentChannel) || !currentChannel?.uuid) return;
    return await instantiateChannel({ channel_uuid: currentChannel?.uuid });
  };

  const removeChatsFromCurrentChats = (removeFromCurrentTabs: string[]) => {
    setChats((prevChats) => {
      const nextChats = prevChats.filter((item) => !removeFromCurrentTabs.includes(item.id));
      return nextChats;
    });
  };

  const handleNewChatsUpdates = () => {
    setGlobalSearchKeyword((globalSearchKeyword) => {
      if (!!globalSearchKeyword) {
        // reload search result
        onReloadSearchChatroom();
      } else {
        // caveat: when a new chats add to chat list, we dont know the position.
        setIsLiveUpdateChatlist((isLiveUpdateChatlist) => {
          // hence if live update in UI is allowed, we reload the list to sync with server
          if (isLiveUpdateChatlist) {
            setIsShowNewUpdatesOnChatList(false);
            reloadFetchGetChatroom(17);
          } else {
            // else live update in UI is not allowed, we notify user there is a update
            // in the chats, and ask user reload manually or auto reload when user scrolled to top
            setIsShowNewUpdatesOnChatList(true);
          }
          return isLiveUpdateChatlist;
        });
      }
      return globalSearchKeyword;
    });
  };

  const getCurrentTabAffectedChats = (data: { type: ChatUpdateType; updates: ChatUpdate[] }) => {
    const removeFromCurrentTab: string[] = [];
    const addedToCurrentTab: string[] = [];
    setActiveTab((activeTab) => {
      data?.updates.forEach((update) => {
        const removeFromList = update?.meta?.removed_from || [];
        if (removeFromList && removeFromList?.length > 0) {
          removeFromList?.forEach((remove: { name: string; tab_id: number }) => {
            if (remove?.tab_id === activeTab?.id) {
              removeFromCurrentTab.push(update?.room);
            }
          });
        }
        const addedToList = update?.meta?.added_to || [];
        if (addedToList && addedToList?.length > 0) {
          addedToList?.forEach((added: { name: string; tab_id: number }) => {
            if (added?.tab_id === activeTab?.id) {
              addedToCurrentTab.push(update?.room);
            }
          });
        }
      });
      return activeTab;
    });
    return {
      addedToCurrentTab,
      removeFromCurrentTab,
    };
  };

  const updateChatsTotalUnread = (data: { type: ChatUpdateType; updates: ChatUpdate[] }) => {
    setChats((prevChats) => {
      const _chats = prevChats.slice();
      data?.updates.forEach((update) => {
        const index = prevChats.findIndex((chat) => update.room === chat.id);
        if (index > -1) {
          _chats[index].total_unread = update?.status === false ? -1 : 0;
        }
      });
      return _chats;
    });
  };

  const reloadChatroomIfIsCurrentSelectedChat = (roomId: string) => {
    // if current viewing chat is affected, then call update to get latest state from server
    setSelectedChat((selectedChat) => {
      if (roomId === selectedChat?.id && selectedChat.contact_id) {
        fetchGetContact({
          id: selectedChat.contact_id,
        });
      }
      return selectedChat;
    });
  };

  const updateChatsLastMessageText = (data: { type: ChatUpdateType; updates: ChatUpdate[] }) => {
    setChats((prevChats) => {
      const newChats: Chatroom[] = [...prevChats];
      data?.updates.forEach((update) => {
        const foundIndex = newChats.findIndex((x) => x.id === update.room);
        if (foundIndex > -1) {
          newChats[foundIndex] = {
            ...newChats[foundIndex],
            last_message: {
              ...newChats[foundIndex].last_message,
              text: update?.meta?.message,
            },
          };
        }
      });
      return newChats;
    });
  };

  const updateChatsTags = (data: { type: ChatUpdateType; updates: ChatUpdate[] }) => {
    setChats((prevChats) => {
      const updatedChats = new Map(prevChats.map((chat) => [chat.id, chat]));
      data?.updates.forEach((update) => {
        const chat: Chatroom | undefined = updatedChats.get(update.room);
        if (chat) {
          const { added = [], removed = [] } = update?.meta || {};
          // Add new tags
          added.forEach(
            ({ name, tag_id, color }: { name: string; tag_id: number; color: string }) => {
              chat.tags = [...chat.tags, { name, color, id: tag_id } as Tag];
            },
          );

          // Remove old tags
          chat.tags = chat.tags.filter(
            (tag) =>
              !removed.some((removedTag: { tag_id: number }) => removedTag.tag_id === tag.id),
          );
        }
        reloadChatroomIfIsCurrentSelectedChat(update.room);
      });
      return Array.from(updatedChats.values());
    });
  };

  const updateChatsAssignee = (
    data: { type: ChatUpdateType; updates: ChatUpdate[] },
    isLimitedAccessUser = false,
  ) => {
    let noOfNewChatsAssigned = 0;
    setChats((prevChats) => {
      let newChats = [...prevChats];
      data?.updates.forEach((update) => {
        const foundIndex = newChats.findIndex((x) => x.id === update.room);
        if (foundIndex > -1) {
          // add assignee
          if (update?.status) {
            noOfNewChatsAssigned = noOfNewChatsAssigned + 1;
            newChats[foundIndex] = {
              ...newChats[foundIndex],
              assignee: {
                id: update?.meta?.assignee_id,
                fullname: update?.meta?.name,
              },
            };
          } else {
            if (isLimitedAccessUser) {
              newChats = newChats.filter((chat) => chat.id !== update.room);
            } else {
              // remove assignee
              newChats[foundIndex] = {
                ...newChats[foundIndex],
                assignee: null,
              };
            }
          }
        } else {
          if (update?.status) {
            noOfNewChatsAssigned = noOfNewChatsAssigned + 1;
          }
        }
        // if current viewing chat is affected, then call update to get latest state from server
        reloadChatroomIfIsCurrentSelectedChat(update.room);
      });
      return newChats;
    });
    return { noOfNewChatsAssigned };
  };

  const initAbly = async (channel_uuid: string) => {
    const ablyInstance = await AblySubscription.getInstance();
    if (!ablyInstance) return;
    ablyInstance.subscribeMessage({
      channel_uuid,
      user_uuid: initialState?.currentUser?.uuid || '',
      isLimited: !!initialState?.currentUser?.modules.includes('inbox:assignee'),
      onMessageReceived: async (data) => {
        setSelectedChat((prev) => {
          if (prev?.id === data?.chatId) {
            if (data?.type === 'reaction') {
              setReactedMessages((prevReactedMessages) => {
                return { ...prevReactedMessages, [data?.quoted?.messageId]: data?.text };
              });
            } else {
              setMessages((prevMessages) => {
                let nextMessages = [...prevMessages];
                setIsDisableLiveMessages((prevIsDisableLiveMessages) => {
                  if (prevIsDisableLiveMessages) {
                    setHasNewMessageDuringDisableState(true);
                  } else {
                    let foundIndex = -1;
                    if (!!data.randomId) {
                      foundIndex = prevMessages.findIndex((m) => m.randomId === data.randomId);
                    }
                    if (foundIndex > -1) {
                      nextMessages[foundIndex] = data;
                    } else {
                      if (soundInstance && localStorage.getItem('is-mute-inbox-sound') !== 'true') {
                        soundInstance.playNewMessageAudio();
                      }
                      const lastMessage = prevMessages[prevMessages.length - 1];
                      if (lastMessage && lastMessage.sortKey.localeCompare(data.sortKey) < 0) {
                        // If the new message is later, just append it
                        nextMessages = [...nextMessages, data];
                      } else {
                        // Find the correct insertion point (going backward)
                        let insertIndex = nextMessages.length - 1;
                        while (
                          insertIndex >= 0 &&
                          nextMessages[insertIndex].sortKey.localeCompare(data.sortKey) > 0
                        ) {
                          insertIndex--;
                        }
                        // Insert the message at the found position
                        nextMessages = [
                          ...nextMessages.slice(0, insertIndex + 1),
                          data,
                          ...nextMessages.slice(insertIndex + 1),
                        ];
                      }
                    }
                  }
                  return prevIsDisableLiveMessages;
                });
                return nextMessages;
              });
            }
          }
          return prev;
        });
        // @todo: not sure if very old message suddenly text in, will it need to
        // trigger this line, otherwise, we can remove it.
        updateWithLatestChat(data);
      },
    });
    ablyInstance.subscribeChannel({
      channel_uuid,
      onTabUpdate: () => {
        fetchGetInboxTabs();
      },
      onChatUpdate: (data) => {
        switch (data.type) {
          case 'tab-assignment':
            if (data?.updates && data?.updates?.length > 0) {
              const { addedToCurrentTab, removeFromCurrentTab } = getCurrentTabAffectedChats(data);
              if (addedToCurrentTab?.length > 0) {
                handleNewChatsUpdates();
              }
              if (removeFromCurrentTab?.length > 0) {
                removeChatsFromCurrentChats(removeFromCurrentTab);
              }
            }
            break;
          case 'read-status-update':
            if (data?.updates && data?.updates?.length > 0) {
              updateChatsTotalUnread(data);
            }
            break;
          case 'assignment-update':
            if (data?.updates && data?.updates?.length > 0) {
              const isLimitedAccess =
                !!initialState?.currentUser?.modules.includes('inbox:assignee');
              if (isLimitedAccess) {
                const { noOfNewChatsAssigned } = updateChatsAssignee(data, isLimitedAccess);
                if (noOfNewChatsAssigned > 0) {
                  handleNewChatsUpdates();
                }
              } else {
                updateChatsAssignee(data);
              }
            }
            break;
          case 'collaborator-update':
            if (data?.updates && data?.updates?.length > 0) {
              const isLimitedAccess =
                !!initialState?.currentUser?.modules.includes('inbox:assignee');
              if (isLimitedAccess) {
                //@todo: revisit, might need to add or remove chat from chats
                //problem here is whenever collaborator update either add or
                //remove it will also trigger reload
                handleNewChatsUpdates();
              }
              data?.updates.forEach((update) => {
                reloadChatroomIfIsCurrentSelectedChat(update.room);
              });
            }
            break;
          case 'tag-update':
            if (data?.updates && data?.updates?.length > 0) {
              updateChatsTags(data);
            }
            break;
          case 'last-message-update':
            if (data?.updates && data?.updates?.length > 0) {
              updateChatsLastMessageText(data);
            }
            break;
          case 'chat-closed':
            setActiveTab((activeTab) => {
              const isInClosedTab =
                activeTab?.action_key === 'closed' && activeTab?.type === 'default';
              if (isInClosedTab) {
                //@todo: revisit
                //in close tab always reload
                handleNewChatsUpdates();
              } else {
                const removeFromCurrentTab: string[] = [];
                if (data?.updates && data?.updates?.length > 0) {
                  data?.updates.forEach((update) => {
                    removeFromCurrentTab.push(update?.room);
                  });
                }
                if (removeFromCurrentTab?.length > 0) {
                  removeChatsFromCurrentChats(removeFromCurrentTab);
                }
              }
              return activeTab;
            });
            break;
          case 'archive-update':
          case 'chat-reopened':
          case 'pin-update':
            handleNewChatsUpdates();
            break;
          default:
            break;
        }
      },
      onQrChanged: (data) => {
        setQrCode(_get(data, 'url'));
        fetchUserChannels();
      },
      onMessageUpdate: (data) => {
        const updates = removeDuplicateReadMessages(data?.updates);
        setSelectedChat((prev) => {
          setMessages((prevMessages) => {
            const nextMessages = [...prevMessages];
            updates.forEach(async (update) => {
              if (update?.room === prev?.id) {
                const foundIndex = nextMessages.findIndex(
                  ({ id, randomId }) =>
                    (update.randomId && update.randomId === randomId) ||
                    (update.id && update.id === id),
                );
                if (foundIndex > -1) {
                  switch (update.status) {
                    case 'edit-note':
                      nextMessages[foundIndex].text = update?.text || '';
                      break;
                    case 'edited':
                      nextMessages[foundIndex].text = update?.text || '';
                      nextMessages[foundIndex].isEdited = true;
                      break;
                    case 'delete-note':
                      nextMessages.splice(foundIndex, 1);
                      break;
                    case 'delete':
                      nextMessages[foundIndex].type = 'deleted';
                      break;
                    case 'failed':
                      nextMessages[foundIndex].status = MessageStatus.FAILED;
                      nextMessages[foundIndex].error = update.error;
                      break;
                    case MessageStatus.SERVER_ACK:
                      if (
                        nextMessages[foundIndex].status !== MessageStatus.DELIVERY_ACK &&
                        nextMessages[foundIndex].status !== MessageStatus.READ
                      ) {
                        nextMessages[foundIndex].status = MessageStatus.SERVER_ACK;
                      }
                      break;
                    case MessageStatus.DELIVERY_ACK:
                      if (nextMessages[foundIndex].status !== MessageStatus.READ) {
                        nextMessages[foundIndex].status = MessageStatus.DELIVERY_ACK;
                      }
                      break;
                    case MessageStatus.READ:
                      nextMessages[foundIndex].status = MessageStatus.READ;
                      break;
                    default:
                      break;
                  }
                }
              }
            });
            return nextMessages;
          });
          return prev;
        });
      },
      onStatusChanged: (status) => {
        setCurrentChannel((prev) => ({
          ...prev,
          status,
        }));
        if (status == 'ready') {
          message.success(
            'Your WhatsApp has been connected with luluchat, page is refreshing in 5 seconds...',
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
        if (status == 'disconnected') {
          message.warning(
            'Your WhatsApp has been disconnected from luluchat, page is refreshing in 3 seconds...',
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      },
      onWindowRefreshed: async (data) => {
        setSelectedChat((prev) => {
          if (data?.chat_id === prev?.id) {
            setContact((prevContact) => {
              if (data?.chat_id === prevContact?.wa_contact_id) {
                return {
                  ...prevContact,
                  meta: {
                    ...prevContact?.meta,
                    last_user_replied: data?.last_reply,
                  },
                };
              }
              return prevContact;
            });
            return {
              ...prev,
              last_user_replied: data?.last_reply,
            };
          }
          return prev;
        });
      },
    });
    return ablyInstance;
  };

  const initChannel = () => {
    if (_isEmpty(currentChannel) || !currentChannel?.uuid) {
      console.error('channel not detected', currentChannel);
      return false;
    }
    const instance = initAbly(currentChannel?.uuid);
    return instance;
  };

  return {
    currentChannel,
    qrCode,
    channels,
    fetchGetChannels,
    isLoadingFetchGetChannels,
    initChannel,
    notes,
    fetchGetNotes,
    isLoadingFetchGetNotes,
    fetchCreateChannel,
    isLoadingFetchCreateChannel,
    fetchDeleteNote,
    isLoadingDeleteNote,
    fetchConnectWABAChannel,
    isLoadingFetchConnectWABAChannel,
    fetchInstantiateChannel,
    isLoadingEditNote,
    fetchEditNote,
    isLoadingFetchRefreshToken,
    fetchRefreshToken,
    isLoadingFetchDisconnect,
    fetchDisconnect,
  };
};
