// @ts-ignore
/* eslint-disable */
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { message } from 'antd';
import Cookies from 'js-cookie';
import _get from 'lodash/get';
import { API } from './typings';

export async function editInput(body: API.openAIEditInput) {
  const apiKey = Cookies.get('app.chatgpt.api-key');
  if (!apiKey) return;
  return fetch('https://api.openai.com/v1/edits', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
    },
    body: JSON.stringify({
      model: 'gpt-4o-mini',
      ...body,
    }),
  }).then(async (response) => {
    const status = response.status;
    const res = await response.json();
    if (status === 404 || status === 400 || status === 401 || res?.error?.message) {
      const errorMessage = _get(res, 'error.message');
      message.error(`Error from ChatGPT: ${errorMessage}`);
    }
    return res;
  });
}

export async function completions(body: API.openAICompletions) {
  const apiKey = Cookies.get('app.chatgpt.api-key');
  if (!apiKey) return;
  return fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
    },
    body: JSON.stringify({
      model: 'gpt-4o-mini',
      max_tokens: 300,
      temperature: 0,
      ...body,
    }),
  }).then(async (response) => {
    const status = response.status;
    const res = await response.json();
    if (status === 404 || status === 400 || status === 401 || res?.error?.message) {
      const errorMessage = _get(res, 'error.message');
      message.error(`Error from ChatGPT: ${errorMessage}`);
    }
    return res;
  });
}

const convertBlobToMP3 = async (audioBlob) => {
  const ffmpeg = createFFmpeg({
    mainName: 'main',
    corePath: 'https://unpkg.com/@ffmpeg/core-st@0.11.1/dist/ffmpeg-core.js',
    log: true,
  });
  await ffmpeg.load();

  ffmpeg.FS('writeFile', 'audio.webm', await fetchFile(audioBlob));
  await ffmpeg.run('-i', 'audio.webm', '-c:a', 'libmp3lame', '-qscale:a', '2', 'output.mp3');

  const outputData = ffmpeg.FS('readFile', 'output.mp3');

  return new Blob([outputData.buffer], { type: 'audio/mp3' });
};

export async function speechToText(body: API.openAISpeechToText) {
  const apiKey = Cookies.get('app.chatgpt.api-key');
  if (!apiKey) return;
  const form = new FormData();
  const file = await convertBlobToMP3(body?.audioBlobFile);
  form.append('file', file, 'speech.mp3');
  form.append('model', 'whisper-1');
  return fetch('https://api.openai.com/v1/audio/transcriptions', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${apiKey}`,
    },
    body: form,
  }).then(async (response) => {
    const status = response.status;
    const res = await response.json();
    if (status === 404 || status === 400 || status === 401 || res?.error?.message) {
      const errorMessage = _get(res, 'error.message');
      message.error(`Error from ChatGPT: ${errorMessage}`);
    }
    return res;
  });
}
