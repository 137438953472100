import { useIntl } from 'umi';
import { DefaultFooter } from '@ant-design/pro-layout';
import { Space } from "antd";

export default () => {
  const intl = useIntl();
  const defaultMessage = intl.formatMessage({
    id: 'app.copyright.produced',
    defaultMessage: '蚂蚁集团体验技术部出品',
  });

  const currentYear = new Date().getFullYear();

  return (
    <DefaultFooter
      copyright={`${currentYear} ${defaultMessage}`}
      links={[
        {
          key: 'luluchat',
          title: <Space><img src="/images/svg/logo_icon.svg" height={30}/>luluchat</Space>,
          href: 'https://www.luluchat.io/',
          blankTarget: true,
        },
      ]}
    />
  );
};
