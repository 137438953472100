// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getStaffServiceProviders(
  body?: API.GetStaffServiceProvidersParams,
  options?: { [key: string]: any },
) {
  return request<API.GetStaffServiceProvidersResult>(
    `/users/staff-service-providers`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function createStaffServiceProvider(
  body: API.CreateStaffServiceProviderParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateStaffServiceProviderResult>(
    `/users/staff-service-providers`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getStaffServiceProvider(
  { id, ...body }: API.GetStaffServiceProviderParams,
  options?: { [key: string]: any },
) {
  return request<API.GetStaffServiceProviderResult>(
    `/users/staff-service-providers/${id}`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function updateStaffServiceProvider(
  { id, ...body }: API.UpdateStaffServiceProviderParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateStaffServiceProviderResult>(
    `/users/staff-service-providers/${id}`,
    {
      method: 'PUT',
      data: body,
      ...(options || {}),
    },
  );
}

export async function deleteStaffServiceProvider(
  { id }: API.DeleteStaffServiceProviderParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteStaffServiceProviderResult>(
    `/users/staff-service-providers/${id}`,
    {
      method: 'DELETE',
      ...(options || {}),
    },
  );
}
