// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getChannels(body?: API.GetFlowsParams, options?: { [key: string]: any }) {
  return request<API.GetChannelsResult>('/users/channels', {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createChannel(options?: { [key: string]: any }) {
  return request<API.CreateChannelResult>(`/users/channels`, {
    method: 'POST',
    ...(options || {}),
  });
}

export async function sendMessage(body: API.SendMessageParams, options?: { [key: string]: any }) {
  return request<API.SendMessageResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/send-message`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function editMessage(body: API.EditMessageParams, options?: { [key: string]: any }) {
  return request<API.EditMessageResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/edit-message`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function deleteMessage(
  { wa_contact_id, message_id }: API.DeleteMessageParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteMessageResult>(
    `/users/channels/${localStorage.getItem(
      'x-channel-id',
    )}/chatrooms/${wa_contact_id}/messages/${message_id}`,
    {
      method: 'DELETE',
      ...(options || {}),
    },
  );
}

export async function getChatrooms(
  body?: API.GetChatroomsParams,
  options?: { [key: string]: any },
) {
  return request<API.GetChatroomsResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/chatrooms?includes=tag`,
    {
      method: 'GET',
      data: {
        randomId: localStorage.getItem('randomId'),
        ...body,
      },
      ...(options || {}),
    },
  );
}

export async function getMessages(
  { wa_contact_id, ...body }: API.GetMessagesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetMessagesResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/chatrooms/${wa_contact_id}/messages`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function searchGlobalMessages(
  body: API.SearchGlobalMessagesParams,
  options?: { [key: string]: any },
) {
  return request<API.SearchGlobalMessagesResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/chatrooms/all/messages`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function instantiateChannel(
  body: API.InstantiateChannelParams,
  options?: { [key: string]: any },
) {
  return request<API.InstantiateChannelResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/instantiate`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getNotes(
  { wa_contact_id, ...body }: API.GetNotesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetNotesResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/chatrooms/${wa_contact_id}/notes`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function editNote(
  { wa_contact_id, message, note_id }: API.EditNoteParams,
  options?: { [key: string]: any },
) {
  return request<API.EditNoteResult>(
    `/users/channels/${localStorage.getItem(
      'x-channel-id',
    )}/chatrooms/${wa_contact_id}/notes/${note_id}`,
    {
      method: 'PUT',
      data: {
        message,
      },
      ...(options || {}),
    },
  );
}

export async function deleteNote(
  { wa_contact_id, note_id }: API.DeleteNoteParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteNoteResult>(
    `/users/channels/${localStorage.getItem(
      'x-channel-id',
    )}/chatrooms/${wa_contact_id}/notes/${note_id}`,
    {
      method: 'DELETE',
      ...(options || {}),
    },
  );
}

export async function markChatroomAsRead(
  { wa_contact_id, ...body }: API.MarkChatroomAsReadParams,
  options?: { [key: string]: any },
) {
  return request<API.MarkChatroomAsReadResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/chatrooms/${wa_contact_id}/mark-read`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function markChatroomAsUnRead(
  { wa_contact_id }: API.MarkChatroomAsUnReadParams,
  options?: { [key: string]: any },
) {
  return request<API.MarkChatroomAsUnReadResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/chatrooms/mark-read-status`,
    {
      method: 'POST',
      data: {
        ids: [wa_contact_id],
        status: 0,
      },
      ...(options || {}),
    },
  );
}

export async function markChatroomArchiveStatus(
  { wa_contact_id, status }: API.MarkChatroomAsUnReadParams,
  options?: { [key: string]: any },
) {
  return request<API.MarkChatroomAsUnReadResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/chatrooms/mark-archive-status`,
    {
      method: 'POST',
      data: {
        ids: [wa_contact_id],
        status,
      },
      ...(options || {}),
    },
  );
}

export async function markChatroomAsPin(
  { wa_contact_id, status }: API.MarkChatroomAsPinParams,
  options?: { [key: string]: any },
) {
  return request<API.MarkChatroomAsPinResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/chatrooms/mark-pin-status`,
    {
      method: 'POST',
      data: {
        ids: [wa_contact_id],
        status,
      },
      ...(options || {}),
    },
  );
}

export async function getUnsentScheduleMessages(
  body: API.GetUnsentScheduleMessagesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetUnsentScheduleMessagesResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/schedule-messages`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function deleteScheduleMessages(
  { schedule_message_id }: API.DeleteScheduleMessagesParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteScheduleMessagesResult>(
    `/users/channels/${localStorage.getItem(
      'x-channel-id',
    )}/schedule-messages/${schedule_message_id}`,
    {
      method: 'DELETE',
      ...(options || {}),
    },
  );
}

export async function connectWABAChannel(
  body: API.ConnectWABAChannelParams,
  options?: { [key: string]: any },
) {
  return request<API.ConnectWABAChannelResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/connect`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function refreshToken(body: API.RefreshTokenParams, options?: { [key: string]: any }) {
  return request<API.RefreshTokenResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/refresh-token`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function disconnect(
  { channel_uuid, type }: { channel_uuid: string; type: 'clear' | 'disconnect' },
  options?: { [key: string]: any },
) {
  return request<API.DisconnectResult>(`/users/channels/${channel_uuid}/disconnect`, {
    method: 'POST',
    data: { type },
    ...(options || {}),
  });
}

export async function getContactStatus(
  { phone_number }: API.GetContactStatusParams,
  options?: { [key: string]: any },
) {
  return request<API.GetContactStatusResults>(
    `/users/channels/${localStorage.getItem(
      'x-channel-id',
    )}/chatrooms/${phone_number}/contact-status`,
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}
