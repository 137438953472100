export const Sound = (function () {
  const newMessageSound = new Audio('/new-message-sound.mp3');
  const sendMessageSound = new Audio('/send-message-sound.mp3');

  function init() {
    const playNewMessageAudio = async () => {
      const key = 'newMessageNotification';
      if (!localStorage.getItem(key)) {
        localStorage.setItem(key, 'true');
        newMessageSound.play().catch((error) => {
          console.log(error);
        });
        clearNotificationFlag(key);
      }
    };

    const playSendMessageAudio = async () => {
      sendMessageSound.play().catch((error) => {
        console.log(error);
      });
    };

    // Clear the notification flag after some time to allow future notifications
    function clearNotificationFlag(key: string) {
      setTimeout(() => {
        localStorage.removeItem(key);
      }, 1000); // Adjust the timeout as needed
    }

    return {
      playNewMessageAudio,
      playSendMessageAudio,
    };
  }

  let instance: null | {
    playNewMessageAudio: () => void;
    playSendMessageAudio: () => void;
  } = null;

  // Listen for storage events to synchronize across tabs
  window.addEventListener('storage', function (event) {
    if (event.key === 'newMessageNotification' && event.newValue === 'true') {
      instance?.playNewMessageAudio();
    }
  });

  return {
    getInstance: () => {
      if (instance === null) {
        instance = init();
      }
      return instance;
    },
  };
})();
