import type { Widget } from '@/declarations/widget';
import { getWidget, updateWidget } from '@/services/luluchat/widget';
import { useRequest } from 'umi';

export default () => {
  const {
    data: widget = {} as Widget,
    run: fetchGetWidget,
    loading: isLoadingFetchGetWidget,
  } = useRequest(getWidget, {
    manual: true,
    formatResult(res) {
      if (res.status) {
        return res.data?.settings
      }
      return res.data?.settings;
    },
  });

  const { run: fetchUpdateWidget, loading: isLoadingFetchUpdateWidget } = useRequest(updateWidget, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  return {
    widget,
    fetchGetWidget,
    isLoadingFetchGetWidget,
    fetchUpdateWidget,
    isLoadingFetchUpdateWidget,
  };
};
