import type { Channel, ChannelStatus } from '@/declarations/channel';
import useModal from '@/hooks/useModal';
import { db } from '@/services/indexedDB/db';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useModel } from 'umi';
import styles from './style.less';

export default () => {
  const {
    channels,
    fetchGetChannels,
    fetchCreateChannel,
    isLoadingFetchGetChannels,
    fetchDisconnect,
  } = useModel('channel');
  const { isModalVisible, closeModal } = useModal();
  const { initialState } = useModel('@@initialState');
  const [isRemoveChatHistory, setIsRemoveChatHistory] = useState(false);
  const { currentChannel, currentTeam } = initialState || {};
  const [channel] = useState<Channel>();

  useEffect(() => {
    fetchGetChannels();
  }, []);

  const redirectToChannel = async (uuid: string) => {
    localStorage.setItem('x-channel-id', uuid);
    message.success(`Redirecting to another Channel in 3 seconds...`);
    try {
      await db.messages.clear().catch((e) => {
        console.error('Error: ' + (e?.stack || e));
      });
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      window.location.href = '/inbox/list';
    }, 3000);
  };

  const switchToChannel = async (uuid: string) => {
    localStorage.setItem('x-channel-id', uuid);
    message.success(`Redirecting to another Channel in 3 seconds...`);
    try {
      await db.messages.clear().catch((e) => {
        console.error('Error: ' + (e?.stack || e));
      });
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      window.location.href = '/dashboard';
    }, 3000);
  };

  const renderButton = ({ status, uuid }: { status: ChannelStatus; uuid: string }) => {
    if (status === 'ready') {
      if (uuid === currentChannel?.uuid) {
        return (
          <Button
            size="small"
            type="primary"
            onClick={() => (window.location.href = '/inbox/list')}
          >
            Current Selected
          </Button>
        );
      } else {
        return (
          <Button size="small" type="primary" onClick={() => switchToChannel(uuid)}>
            Switch
          </Button>
        );
      }
    } else {
      if (uuid === currentChannel?.uuid) {
        return (
          <Button size="small" type="primary" href="/inbox/list">
            Connect Now
          </Button>
        );
      } else {
        return (
          <Button size="small" type="primary" onClick={() => redirectToChannel(uuid)}>
            Switch
          </Button>
        );
      }
    }
  };

  const addMoreChannels = async () => {
    const res = await fetchCreateChannel();
    if (res.status) {
      message.success('New Channel created, please scan and connect to your WhatsApp now.');
      fetchGetChannels();
    }
  };

  const onClickDisconnect = async (channel_uuid: string) => {
    const res = await fetchDisconnect({
      channel_uuid,
      type: isRemoveChatHistory ? 'clear' : 'disconnect',
    });
    if (res.status) {
      message.success(`Channel Disconnect.`);
      fetchGetChannels();
    }
  };

  return (
    <>
      <Typography.Text type="secondary">
        All your channels are in following list, you can switch to different channel to process your
        work or add more channels to fit your business needs.
      </Typography.Text>
      <br />
      <br />
      <Typography.Text type="secondary">
        <b>{channels.length}</b> out of <b>{currentTeam?.limit?.limits?.channel || 0}</b> Channels
        has been used.
      </Typography.Text>
      <Row gutter={[16, 16]} className={styles.row}>
        {isLoadingFetchGetChannels ? (
          <Spin />
        ) : (
          channels.map((c) => {
            const { whatsapp, status, identity_key, uuid, type } = c;
            return (
              <Col span={24} key={uuid}>
                <div
                  className={cx(styles.itemContainer, {
                    [styles.selectedItemContainer]: uuid === currentChannel?.uuid,
                  })}
                >
                  <div className={styles.nameContainer}>
                    <Typography className={styles.name}>
                      <b>{whatsapp?.name}</b>
                    </Typography>
                    <Tag color={status !== 'ready' ? 'red' : 'green'} className={styles.status}>
                      {status !== 'ready' ? 'Not Connected' : 'Connected'}
                    </Tag>
                  </div>
                  <Typography className={styles.identity_key}>
                    {identity_key
                      ? `Phone Number: ${identity_key?.split(':')?.[0]}`
                      : 'Channel not connected'}
                  </Typography>
                  <Typography>{type ? `Channel Type: ${type}` : ''}</Typography>
                  <Typography.Text type="secondary" className={styles.uuid}>
                    <small>ID: {uuid}</small>
                  </Typography.Text>
                  <Divider style={{ margin: '16px 0' }} />
                  <Row justify="end">
                    <Col>
                      <Space>
                        {status !== 'ready' ? (
                          ''
                        ) : (
                          <Popconfirm
                            title={
                              <>
                                Are you sure you want to disconnect this channel? Check the box
                                <br />
                                below if you also want to delete the chat history.
                                <div style={{ margin: '8px 0' }}>
                                  <Space>
                                    <Checkbox onChange={(x) => setIsRemoveChatHistory(x)} />
                                    Remove chat history
                                  </Space>
                                </div>
                              </>
                            }
                            onConfirm={() => onClickDisconnect(uuid)}
                            okText="Yes, proceed"
                          >
                            <Button size="small" danger>
                              Disconnect
                            </Button>
                          </Popconfirm>
                        )}
                        {/* {type === 'waba' ? (
                          <Button
                            size="small"
                            onClick={() => {
                              openModal();
                              setChannel(c);
                            }}
                          >
                            More Info
                          </Button>
                        ) : (
                          ''
                        )} */}
                        {renderButton({ status, uuid })}
                      </Space>
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          })
        )}
      </Row>
      <br />
      <br />
      <Space>
        <Button type="primary" onClick={addMoreChannels}>
          Add Channel
        </Button>
        <Button
          type="link"
          onClick={() => {
            window.location.href = '/plans';
          }}
        >
          Need More Channel?
        </Button>
      </Space>
      <Modal
        title="Channel Details"
        open={isModalVisible}
        onCancel={() => closeModal()}
        onOk={() => closeModal()}
        footer={null}
        destroyOnClose
      >
        <Typography>
          <b>WhatsApp Number</b>
        </Typography>
        <Typography.Paragraph>{channel?.contact_number}</Typography.Paragraph>
        <Typography>
          <b>Type</b>
        </Typography>
        <Typography.Paragraph>{channel?.type}</Typography.Paragraph>
        <Typography>
          <b>Webhook Call URL</b>
        </Typography>
        <Typography.Paragraph
          copyable={{
            text: `https://${
              API_END_POINT.startsWith('https://dev') ? 'dev-' : ''
            }webhook.luluchat.io/waba/cloud/${channel?.uuid}`,
          }}
        >
          https://{API_END_POINT.startsWith('https://dev') ? 'dev-' : ''}
          webhook.luluchat.io/waba/cloud/{channel?.uuid}
        </Typography.Paragraph>
        <Typography>
          <b>Webhook Verify Token</b>
        </Typography>
        <Typography.Paragraph copyable style={{ marginBottom: 0 }}>
          lulu_webhook_token
        </Typography.Paragraph>
      </Modal>
    </>
  );
};
