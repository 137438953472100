import type { Customer } from '@/declarations/customer';
import {
  createCustomer,
  getCustomer,
  getCustomers,
  updateCustomer,
} from '@/services/luluchat/customers';
import { useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const {
    data: customers,
    run: fetchGetCustomers,
    loading: isLoadingFetchGetCustomers,
  } = useRequest(getCustomers, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchGetCustomer, loading: isLoadingFetchGetCustomer } = useRequest(getCustomer, {
    manual: true,
    formatResult(res) {
      setCustomer(res?.data);
      return res?.data;
    },
  });

  const { run: fetchCreateCustomer, loading: isLoadingFetchCreateCustomer } = useRequest(
    createCustomer,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateCustomer, loading: isLoadingFetchUpdateCustomer } = useRequest(
    updateCustomer,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  return {
    customer,
    fetchGetCustomer,
    isLoadingFetchGetCustomer,
    customers,
    fetchGetCustomers,
    isLoadingFetchGetCustomers,
    fetchCreateCustomer,
    isLoadingFetchCreateCustomer,
    fetchUpdateCustomer,
    isLoadingFetchUpdateCustomer,
  };
};
