// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getQuickReplies(
  body?: API.GetQuickRepliesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetQuickRepliesResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/quick-replies`,
    {
      method: 'GET',
      data: {
        sort_field: 'priority',
        sort_direction: 'desc',
        ...body,
      },
      ...(options || {}),
    },
  );
}

export async function createQuickReply(
  body: API.CreateQuickReplyParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateQuickReplyResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/quick-replies`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getQuickReply(
  { id, ...body }: API.GetQuickReplyParams,
  options?: { [key: string]: any },
) {
  return request<API.GetQuickReplyResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/quick-replies/${id}`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function updateQuickReply(
  { id, ...body }: API.UpdateQuickReplyParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateQuickReplyResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/quick-replies/${id}`,
    {
      method: 'PUT',
      data: body,
      ...(options || {}),
    },
  );
}

export async function deleteQuickReply(
  { id }: API.DeleteQuickReplyParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteQuickReplyResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/quick-replies/${id}`,
    {
      method: 'DELETE',
      ...(options || {}),
    },
  );
}
