import { Button, Modal, Space, Typography } from 'antd';
import _get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useModel } from 'umi';

interface ISelectStepsModal {
  isModalVisible: boolean;
  onSelectStep: (selectedStep: string) => void;
  onCancel: () => void;
}
export default ({ isModalVisible = false, onSelectStep, onCancel }: ISelectStepsModal) => {
  const { onGetAllSteps } = useModel('diagram');
  const [allSteps, setAllSteps] = useState<any[]>([]);

  useEffect(() => {
    if (isModalVisible) {
      const newAllSteps: any[] = onGetAllSteps();
      setAllSteps(newAllSteps);
    }
  }, [isModalVisible]);

  const connectButtonToStep = (selectedStepId: string) => {
    if (typeof onSelectStep === 'function') {
      onSelectStep(selectedStepId);
    }
  };

  return (
    <Modal
      title="Select a Step"
      open={isModalVisible}
      onCancel={onCancel}
      footer={null}
      zIndex={1030}
    >
      {allSteps.length > 0 ? (
        <Space direction="vertical" style={{ width: '100%' }}>
          {allSteps.map((step) => {
            const title = _get(step, 'data.title');
            return (
              <Button
                key={_get(step, 'data.id')}
                block
                onClick={connectButtonToStep.bind(this, _get(step, 'data.id'))}
              >
                {title}
              </Button>
            );
          })}
        </Space>
      ) : (
        <Typography>Nothing here</Typography>
      )}
    </Modal>
  );
};
