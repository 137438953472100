import type { Billing } from '@/declarations/team';
import { getBillings } from '@/services/luluchat/teams';
import type { API } from '@/services/luluchat/typings.d';
import { useRequest } from 'umi';

export default () => {
  const {
    data: billings = {
      data: [] as Billing[],
    },
    run: fetchGetBillings,
    loading: isLoadingFetchGetBillings,
  } = useRequest(getBillings, {
    manual: true,
    formatResult(res): API.GetBillingsResult {
      return res;
    },
  });
  return {
    billings,
    isLoadingFetchGetBillings,
    fetchGetBillings,
  };
};
