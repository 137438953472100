// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getMessageTemplates(
  body?: API.GetMessageTemplatesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetMessageTemplatesResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/waba/message-templates`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function createMessageTemplate(
  body: API.CreateMessageTemplateParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateMessageTemplateResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/waba/message-templates`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getMessageTemplate(
  { id, ...body }: API.GetMessageTemplateParams,
  options?: { [key: string]: any },
) {
  return request<API.GetMessageTemplateResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/waba/message-templates/${id}`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function updateMessageTemplate(
  { id, ...body }: API.UpdateMessageTemplateParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateMessageTemplateResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/waba/message-templates/${id}`,
    {
      method: 'PUT',
      data: body,
      ...(options || {}),
    },
  );
}

export async function deleteMessageTemplate(
  { id }: API.DeleteMessageTemplateParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteMessageTemplateResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/waba/message-templates/${id}`,
    {
      method: 'DELETE',
      ...(options || {}),
    },
  );
}

export async function syncMessageTemplate(options?: { [key: string]: any }) {
  return request<API.SyncMessageTemplateResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/waba/message-templates/sync`,
    {
      method: 'POST',
      ...(options || {}),
    },
  );
}
