import { useState } from "react";

export default function useModal() {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const openModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };
  return {
    isModalVisible,
    openModal,
    closeModal,
  };
}
