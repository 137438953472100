// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getWebhookVendors(options?: { [key: string]: any }) {
  return request<API.GetWebhookVendorsResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/webhooks/vendors`,
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

export async function getWebhookVendor(
  body: API.GetWebhookVendorParams,
  options?: { [key: string]: any },
) {
  return request<API.GetWebhookVendorResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/webhooks/vendors/${body.vendor}`,
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

export async function enableVendor(
  { vendor, ...body }: API.EnableVendorParams,
  options?: { [key: string]: any },
) {
  return request<API.EnableVendorResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/webhooks/vendors/${vendor}/enable`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}
export async function disableVendor(
  { vendor, ...body }: API.DisableVendorParams,
  options?: { [key: string]: any },
) {
  return request<API.DisableVendorResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/webhooks/vendors/${vendor}/disable`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function updateVendorEventSetting(
  { vendor, ...body }: API.UpdateVendorEventSettingParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateVendorEventSettingResult>(
    `/users/channels/${localStorage.getItem(
      'x-channel-id',
    )}/webhooks/vendors/${vendor}/update-event-setting`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}
