// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getFlows(body?: API.GetFlowsParams, options?: { [key: string]: any }) {
  return request<API.GetFlowsResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getOtherFlows(body?: API.GetFlowsParams, options?: { [key: string]: any }) {
  return request<API.GetOtherFlowsResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/others`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function createFlow(body: API.CreateFlowParams, options?: { [key: string]: any }) {
  return request<API.CreateFlowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function createAbsentFlow(body: API.CreateAbsentFlowParams, options?: { [key: string]: any }) {
  return request<API.CreateAbsentFlowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/absent`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getFlow(
  { id, ...body }: API.GetFlowParams,
  options?: { [key: string]: any },
) {
  return request<API.GetFlowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/${id}?includes=conditions`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function updateFlow(
  { id, ...body }: API.UpdateFlowParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateFlowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/${id}`,
    {
      method: 'PUT',
      data: body,
      ...(options || {}),
    },
  );
}

export async function deleteFlow({ id }: API.DeleteFlowParams, options?: { [key: string]: any }) {
  return request<API.DeleteFlowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/${id}`,
    {
      method: 'DELETE',
      ...(options || {}),
    },
  );
}

export async function copyFlow(
  { id, to_channel_uuid, ...body }: API.CopyFlowParams,
  options?: { [key: string]: any },
) {
  return request<API.CopyFlowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/${id}/copy/${to_channel_uuid}`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function saveDraft(
  { id, ...body }: API.SaveDraftParams,
  options?: { [key: string]: any },
) {
  return request<API.SaveDraftResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/${id}/save-draft`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function activateFlow(
  { id }: API.ActivateFlowParams,
  options?: { [key: string]: any },
) {
  return request<API.ActivateFlowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/activate-flow/${id}`,
    {
      method: 'POST',
      ...(options || {}),
    },
  );
}

export async function deactivateFlow(
  { id }: API.DeactivateFlowParams,
  options?: { [key: string]: any },
) {
  return request<API.DeactivateFlowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/deactivate-flow/${id}`,
    {
      method: 'POST',
      ...(options || {}),
    },
  );
}

export async function getKeywords(body?: API.GetKeywordParams, options?: { [key: string]: any }) {
  return request<API.GetKeywordsResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/keywords`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function resetFlowStats(
  { id }: API.ResetFlowStatsParams,
  options?: { [key: string]: any },
) {
  return request<API.ResetFlowStatsResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/flows/${id}/reset-stat`,
    {
      method: 'POST',
      ...(options || {}),
    },
  );
}