import useModal from '@/hooks/useModal';
import { completions, editInput } from '@/services/luluchat/openai';
import { message } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const { isModalVisible, openModal, closeModal } = useModal();
  const [suggestion, setSuggestion] = useState('');
  const [originalContent, setOriginalContent] = useState('');
  const aiAsistantRef = useRef<{
    runReplaceContent: undefined | ((s: string) => void);
  }>({
    runReplaceContent: undefined,
  });

  const {
    data: editInputData,
    run: fetchEditInput,
    loading: isLoadingFetchEditInput,
  } = useRequest(editInput, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const {
    data: completionsData,
    run: fetchCompletions,
    loading: isLoadingFetchCompletions,
  } = useRequest(completions, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const onValidationContent = (content: string) => {
    if (!content) {
      message.error('Please complete your content before consulting with the AI Assistant.');
      return false;
    }
    setOriginalContent(content);
    openModal();
    return true;
  };

  const onImproveWriting = async ({ content }: { content: string }) => {
    onValidationContent(content);
    const res = await fetchCompletions({
      messages: [
        {
          role: 'system',
          content:
            'You are an expert in improving text. Refine the following message to enhance its clarity and style without adding any introductory text.',
        },
        { role: 'user', content: `Improve the following message: ${content}` },
      ],
    });
    if (res?.choices?.length > 0) {
      setSuggestion(res?.choices?.[0]?.message?.content.trim());
    }
  };

  const onFixSpelling = async ({ content }: { content: string }) => {
    onValidationContent(content);
    const res = await fetchCompletions({
      messages: [
        {
          role: 'system',
          content:
            'You are a specialist in correcting spelling and grammar. Correct the spelling and grammatical errors in the following message without any additional text.',
        },
        { role: 'user', content: `Fix the spelling and grammar in this message: ${content}` },
      ],
    });
    if (res?.choices?.length > 0) {
      setSuggestion(res?.choices?.[0]?.message?.content.trim());
    }
  };

  const onMakeShorter = async ({ content }: { content: string }) => {
    onValidationContent(content);
    const res = await fetchCompletions({
      messages: [
        {
          role: 'system',
          content:
            'You are an expert in concise writing. Shorten the following message while preserving its main meaning, and provide only the refined text.',
        },
        { role: 'user', content: `Shorten this message: ${content}` },
      ],
    });
    if (res?.choices?.length > 0) {
      setSuggestion(res?.choices?.[0]?.message?.content.trim());
    }
  };

  const onChangeTone = async ({ content, tone }: { content: string; tone: string }) => {
    onValidationContent(content);
    const res = await fetchCompletions({
      messages: [
        {
          role: 'system',
          content:
            'You are a tone adjustment expert. Rewrite the following message to match a more ${tone} tone. Provide only the adjusted text.',
        },
        { role: 'user', content: `Change the tone of this message to ${tone}: ${content}` },
      ],
    });
    if (res?.choices?.length > 0) {
      setSuggestion(res?.choices?.[0]?.message?.content.trim());
    }
  };

  const onMakeLonger = async ({ content }: { content: string }) => {
    onValidationContent(content);
    const res = await fetchCompletions({
      messages: [
        {
          role: 'system',
          content:
            'You are skilled in content expansion. Extend the following message to add more detail, and provide only the extended text.',
        },
        { role: 'user', content: `Expand this message to make it longer: ${content}` },
      ],
    });
    if (res?.choices?.length > 0) {
      setSuggestion(res?.choices?.[0]?.message?.content.trim());
    }
  };

  const onTranslate = async ({
    content,
    language,
  }: {
    content: string;
    language: 'chinese' | 'malay' | 'english';
  }) => {
    onValidationContent(content);
    const res = await fetchCompletions({
      messages: [
        {
          role: 'system',
          content:
            'You are a professional translator. Translate the following message into the specified language, and provide only the translated text.',
        },
        { role: 'user', content: `Translate this message into ${language}: ${content}` },
      ],
    });
    if (res?.choices?.length > 0) {
      setSuggestion(res?.choices?.[0]?.message?.content.trim());
    }
  };

  const onCloseModal = () => {
    setSuggestion('');
    closeModal();
  };

  const isLoading = useMemo(() => {
    return isLoadingFetchEditInput || isLoadingFetchCompletions;
  }, [isLoadingFetchEditInput, isLoadingFetchCompletions]);

  const onReplaceContent = (_suggestion: string) => {
    if (typeof aiAsistantRef.current?.runReplaceContent === 'function') {
      aiAsistantRef.current?.runReplaceContent(_suggestion?.trim());
    }
    closeModal();
  };

  return {
    aiAsistantRef,
    editInputData,
    completionsData,
    isLoading,
    originalContent,
    setOriginalContent,
    suggestion,
    isModalVisible,
    onMakeLonger,
    onFixSpelling,
    onImproveWriting,
    onMakeShorter,
    onChangeTone,
    onCloseModal,
    onTranslate,
    onReplaceContent,
  };
};
