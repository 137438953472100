import type { ModalFuncProps } from 'antd';
import { Modal, Typography } from 'antd';
import type { ReactNode } from 'react';
import styles from './index.less';

export interface IModalWithGraphic extends ModalFuncProps {
  icon?: any;
  iconBgColor: string;
  children: any;
  leftContentRenderer?: ReactNode;
}

export default ({
  icon,
  iconBgColor,
  title,
  children,
  leftContentRenderer,
  ...props
}: IModalWithGraphic) => {
  return (
    <Modal
      destroyOnClose
      footer={null}
      width={869}
      {...props}
      bodyStyle={{ padding: 0, backgroundColor: iconBgColor }}
      maskStyle={{
        background:
          'linear-gradient(29deg, rgba(104,214,224,1) 0%, rgba(141,195,200,0.5998774509803921) 52%, rgba(217,230,232,0.01724439775910369) 100%)',
      }}
    >
      <div className={styles.bodyWrapper}>
        <div className={styles.iconWraper}>
          {leftContentRenderer ? leftContentRenderer : <img src={icon} width="280" />}
        </div>
        <div className={styles.contentWrapper}>
          <Typography.Title level={2} className={styles.title}>
            {title}
          </Typography.Title>
          <div>{children}</div>
        </div>
      </div>
    </Modal>
  );
};
