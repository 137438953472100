// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getCustomAttributes(
  body?: API.GetCustomAttributesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetCustomAttributesResult>(`/users/custom-attributes`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createCustomAttribute(
  body: API.CreateCustomAttributeParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateCustomAttributeResult>(`/users/custom-attributes`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function getCustomAttribute(
  { id, ...body }: API.GetCustomAttributeParams,
  options?: { [key: string]: any },
) {
  return request<API.GetCustomAttributeResult>(`/users/custom-attributes/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function updateCustomAttribute(
  { id, ...body }: API.UpdateCustomAttributeParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateCustomAttributeResult>(`/users/custom-attributes/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function deleteCustomAttribute(
  { id }: API.DeleteCustomAttributeParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteCustomAttributeResult>(`/users/custom-attributes/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}
