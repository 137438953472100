import type { INotification } from '@/declarations/notification';
import { List, Typography } from 'antd';

import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { useModel } from 'umi';
import styles from './NoticeList.less';

export type NoticeListProps = {
  showViewMore?: boolean;
  style?: React.CSSProperties;
  onClick?: (item: INotification) => void;
  emptyText?: string;
  viewMoreText?: string;
  list: INotification[];
  onViewMore?: (e: any) => void;
  isLoading?: boolean;
  tabKey: string;
  title: string;
};
const NoticeList: React.FC<NoticeListProps> = ({
  list = [],
  onClick,
  onViewMore,
  emptyText,
  viewMoreText,
  showViewMore = false,
  isLoading = false,
}) => {
  const { initialState } = useModel('@@initialState');
  if ((!list || list.length === 0) && !isLoading) {
    return (
      <div className={styles.notFound}>
        <img
          src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
          alt="not found"
        />
        <div>{emptyText}</div>
      </div>
    );
  }

  const getChannelNameById = (id: number) => {
    const found = initialState?.channels.find((channel) => channel?.id === id);
    return found?.status === 'pending'
      ? 'Not Connected'
      : `${found?.whatsapp?.name || found?.contact_number || found?.uuid}`;
  };

  return (
    <div>
      <List<INotification>
        className={styles.list}
        loading={isLoading}
        dataSource={list}
        renderItem={(item, i) => {
          const itemCls = classNames(styles.item, {
            [styles.read]: item.is_read,
          });
          return (
            <List.Item
              className={itemCls}
              key={item.id || i}
              onClick={() => {
                onClick?.(item);
              }}
            >
              <List.Item.Meta
                className={styles.meta}
                title={
                  <div className={styles.title}>
                    {item.title}
                    {/* <div className={styles.extra}>{item.extra}</div> */}
                  </div>
                }
                description={
                  <div>
                    <Typography.Paragraph type="secondary" ellipsis={{ rows: 2, expandable: true }}>
                      <small>{item.description}</small>
                    </Typography.Paragraph>
                    <small>at {getChannelNameById(item?.info?.channel_id)} Channel</small>
                    <div className={styles.datetime}>
                      {dayjs(item.meta.notify).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      {showViewMore ? (
        <div className={styles.bottomBar}>
          <div
            onClick={(e) => {
              if (onViewMore) {
                onViewMore(e);
              }
            }}
          >
            {viewMoreText}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NoticeList;
