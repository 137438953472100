// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getFlowTemplates(
  body?: API.GetFlowTemplatesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetFlowTemplatesResult>(`/users/templates`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function useFlowTemplate(
  { id }: API.CreateFlowTemplateParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateFlowTemplateResult>(
    `/users/templates/${id}/apply-to/${localStorage.getItem('x-channel-id')}`,
    {
      method: 'POST',
      ...(options || {}),
    },
  );
}

export async function getFlowTemplate(
  { id, ...body }: API.GetFlowTemplateParams,
  options?: { [key: string]: any },
) {
  return request<API.GetFlowTemplateResult>(`/users/templates/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}
