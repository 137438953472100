// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getTags(body?: API.GetTagsParams, options?: { [key: string]: any }) {
  return request<API.GetTagsResult>(`/users/tags`, {
    method: 'GET',
    data: {
      sort_direction: 'desc',
      sort_field: 'priority',
      ...body,
    },
    ...(options || {}),
  });
}

export async function createTag(body: API.CreateTagParams, options?: { [key: string]: any }) {
  return request<API.CreateTagResult>(`/users/tags`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function getTag({ id, ...body }: API.GetTagParams, options?: { [key: string]: any }) {
  return request<API.GetTagResult>(`/users/tags/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function updateTag(
  { id, ...body }: API.UpdateTagParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateTagResult>(`/users/tags/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function deleteTag({ id }: API.DeleteTagParams, options?: { [key: string]: any }) {
  return request<API.DeleteTagResult>(`/users/tags/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}
