import { getServices } from '@/services/luluchat/services';
import { useRequest } from 'umi';

export default () => {
  const {
    data: services,
    run: fetchGetServices,
    loading: isLoadingFetchGetServices,
  } = useRequest(getServices, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  return {
    services,
    fetchGetServices,
    isLoadingFetchGetServices,
  };
};
