export interface Flow {
  id: number;
  uuid: string;
  channel_id: number;
  title: string;
  status: 'active' | 'inactive';
  is_support_group: boolean;
  statistic: Statistic;
  meta: {
    created: Date;
    updated: Date;
  };
  type: FlowType;
  drawing_data?: DrawingData;
  conditions: FlowCondition[];
}

export const enum FlowType {
  DEFAULT = 'default',
  FLOW = 'flow',
  AWAY = 'away',
  TEMPLATE = 'template',
  OPT_IN = 'opt-in',
  OPT_OUT = 'opt-out'
}

export interface AbsentFlow {
  id: number;
  channel_id: number;
  message: string;
  flow_data: { label: string; flow_id: number }[];
  status: 'active' | 'inactive';
  meta: {
    created: Date;
    updated: Date;
  };
}

export interface Statistic {
  total_responded: number;
  total_sent: number;
}

export interface FlowKeyword {
  id: number;
  flow?: Flow;
  flow_id: number;
  keywords: string[];
  condition: string;
  statistic: {
    total_triggered: number;
  };
  meta: {
    created: Date;
    updated: Date;
  };
}

export interface DrawingData {
  nodes: any[];
  edges: any[];
  viewport: {
    x: number;
    y: number;
    zoom: number;
  };
}

export type FlowTemplateKey = 'welcome' | 'offline';

export type FlowCondition =
  | FlowConditionCustomSchedule
  | FlowConditionTag
  | FlowConditionContact
  | FlowConditionSchedule
  | FlowConditionTriggerLimit;

export type FlowConditionCustomSchedule = {
  id?: number;
  type: 'custom_schedule';
  condition_value?: {
    value: 'custom';
    start: Date;
    end: Date;
  };
};

export type FlowConditionTag = {
  id?: number;
  type: 'tag';
  condition_value: {
    tags: [];
  };
};

export type FlowConditionContact = {
  id?: number;
  type: 'contact';
  condition_value: {
    contacts: [];
  };
};

export type FlowConditionSchedule = {
  id?: number;
  type: 'schedule';
  condition_value: {
    value: 'outside_hour' | 'inside_hour';
  };
  working_days?: IWorkingDay[];
};

export interface IWorkingDay {
  weekday: number;
  is_opened: boolean;
  hours: {
    start_time: string;
    end_time: string;
  }[];
}

export type FlowConditionTriggerLimit = {
  id?: number;
  type: 'trigger_limit' | 'def_trigger_limit';
  condition_value: {
    duration?: number;
    duration_type?: string;
    enabled?: boolean;
    trigger_limit?: number;
  };
};
