import type { Campaign } from '@/declarations/campaign';
import {
  createCampaign,
  getCampaign,
  getCampaigns,
  toggleCampaign,
  countContact
} from '@/services/luluchat/campaigns';
import type { API } from '@/services/luluchat/typings.d';
import { useRequest } from 'umi';

export default () => {
  const {
    data: campaigns = {
      data: [] as Campaign[],
    },
    run: fetchGetCampaigns,
    loading: isLoadingFetchGetCampaigns,
  } = useRequest(getCampaigns, {
    manual: true,
    formatResult(res): API.GetCampaignsResult {
      return res;
    },
  });
  const {
    data: totalContactToBeProcess,
    run: fetchCountContact,
    loading: isLoadingFetchCountContact,
  } = useRequest(countContact, {
    manual: true,
  });

  const { run: fetchCreateCampaign, loading: isLoadingFetchCreateCampaign } = useRequest(
    createCampaign,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const {
    data: campaign = {} as Campaign,
    run: fetchGetCampaign,
    loading: isLoadingFetchGetCampaign,
  } = useRequest(getCampaign, {
    manual: true,
  });

  const { run: fetchPauseCampaign, loading: isLoadingFetchPauseCampaign } = useRequest(
    (id) =>
      toggleCampaign({
        id,
        action: 'pause',
      }),
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchResumeCampaign, loading: isLoadingFetchResumeCampaign } = useRequest(
    (id) =>
      toggleCampaign({
        id,
        action: 'resume',
      }),
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchDeleteCampaign, loading: isLoadingFetchDeleteCampaign } = useRequest(
    (id) =>
      toggleCampaign({
        id,
        action: 'delete',
      }),
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  return {
    campaigns,
    fetchGetCampaigns,
    isLoadingFetchGetCampaigns,
    fetchCreateCampaign,
    isLoadingFetchCreateCampaign,
    campaign,
    fetchGetCampaign,
    isLoadingFetchGetCampaign,
    fetchPauseCampaign,
    isLoadingFetchPauseCampaign,
    fetchResumeCampaign,
    isLoadingFetchResumeCampaign,
    fetchDeleteCampaign,
    isLoadingFetchDeleteCampaign,
    totalContactToBeProcess,
    fetchCountContact,
    isLoadingFetchCountContact
  };
};
