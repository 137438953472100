export interface MessageTemplate {
  id: number;
  fb_id: string;
  language: string;
  name: string;
  category: MessageTemplateCategory;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  quality_score: string;
  rejected_reason: 'ABUSIVE_CONTENT' | 'INCORRECT_CATEGORY' | 'INVALID_FORMAT' | 'NONE' | 'SCAM';
  components: Component[];
  meta: {
    updated: Date;
  };
}

export interface Component {
  id: number;
  fb_id: string;
  type: string;
  format: keyof typeof MessageTemplateHeaderType;
  text: string;
  example: string[];
  data: any[];
}

export interface ExampleClass {
  body_text: string[];
}

export interface ICTAButton {
  type: MessageTemplateButtonType.PHONE_NUMBER | MessageTemplateButtonType.URL;
  text: string;
  url_type?: ICTAButtonURLType; // frontend usage
  url?: string;
  dial_code?: string;
  phone_number?: string;
  id?: string;
  example?: string[];
}

export enum MessageTemplateCategory {
  AUTHENTICATION = 'AUTHENTICATION',
  MARKETING = 'MARKETING',
  UTILITY = 'UTILITY',
}

export enum MessageTemplateHeaderType {
  NONE = 'NONE', // frontend usage
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
  LOCATION = 'LOCATION',
}

export enum ICTAButtonURLType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}

export enum MessageTemplateButtonType {
  NONE = 'NONE', // frontend usage
  CALL_TO_ACTION = 'CALL_TO_ACTION', // frontend usage
  QUICK_REPLY = 'QUICK_REPLY',
  PHONE_NUMBER = 'PHONE_NUMBER',
  URL = 'URL',
}
