// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getServices(
  body?: API.GetServicesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetServicesResult>(
    `/users/services?sort_field=sequence&sort_direction=desc`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function createService(
  body: API.CreateServiceParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateServiceResult>(
    `/users/services`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getService(
  { id, ...body }: API.GetServiceParams,
  options?: { [key: string]: any },
) {
  return request<API.GetServiceResult>(
    `/users/services/${id}`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function updateService(
  { id, ...body }: API.UpdateServiceParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateServiceResult>(
    `/users/services/${id}`,
    {
      method: 'PUT',
      data: body,
      ...(options || {}),
    },
  );
}

export async function deleteService(
  { id }: API.DeleteServiceParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteServiceResult>(
    `/users/services/${id}`,
    {
      method: 'DELETE',
      ...(options || {}),
    },
  );
}

export async function duplicateService(
  { id }: API.DuplicateServiceParams,
  options?: { [key: string]: any },
) {
  return request<API.DuplicateServiceResult>(`/users/services/${id}/duplicate`, {
    method: 'POST',
    ...(options || {}),
  });
}
