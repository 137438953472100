// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getNotifications(
  body?: API.GetNotificationsParams,
  options?: { [key: string]: any },
) {
  return request<API.GetNotificationsResult>(`/users/notifications`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function markNotificationAsRead(options?: { [key: string]: any }) {
  return request<API.MarkNotificationAsReadResult>(`/users/notifications/mark-read`, {
    method: 'POST',
    ...(options || {}),
  });
}
