// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getGrowthTools(
  body?: API.GetGrowthToolsParams,
  options?: { [key: string]: any },
) {
  return request<API.GetGrowthToolsResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/growth-tools`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function createGrowthTool(
  body: API.CreateGrowthToolParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateGrowthToolResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/growth-tools`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function updateGrowthTool(
  { id, ...body }: API.UpdateGrowthToolParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateGrowthToolResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/growth-tools/${id}`,
    {
      method: 'PUT',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getGrowthTool(
  { id, ...body }: API.GetGrowthToolParams,
  options?: { [key: string]: any },
) {
  return request<API.GetGrowthToolResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/growth-tools/${id}`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}
