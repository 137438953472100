// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getTeams(body?: API.GetTeamsParams, options?: { [key: string]: any }) {
  return request<API.GetTeamsResult>('/users/teams', {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function getTeamUsers(
  body?: API.GetTeamUsersParams,
  options?: { [key: string]: any },
) {
  return request<API.GetTeamUsersResult>('/users/teams/users', {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function updateTeamUser(
  { id, ...body }: API.UpdateTeamUserParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateTeamUserResult>(`/users/teams/users/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function deleteTeamUser(
  { id }: API.DeleteTeamUserParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteTeamUserResult>(`/users/teams/users/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}

export async function updateTeam(body: API.UpdateTeamParams, options?: { [key: string]: any }) {
  return request<API.UpdateTeamResult>(`/users/teams/${body.id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function sendInvitation(
  body: API.SendInvitationParams,
  options?: { [key: string]: any },
) {
  return request<API.SendInvitationResult>('/users/teams/send-invitation', {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function createOnboarding(
  body: API.CreateOnboardingParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateOnboardingResult>('/users/teams/onboarding', {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function createBillingPortalSession(options?: { [key: string]: any }) {
  return request<API.CreateBillingPortalSessionResult>('/users/teams/create-portal-session', {
    method: 'POST',
    ...(options || {}),
  });
}

export async function extendInvitation(
  { id, ...body }: API.ExtendInvitationParams,
  options?: { [key: string]: any },
) {
  return request<API.ExtendInvitationResult>(`/users/teams/extend-invitation/${id}`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function getInvitations(options?: { [key: string]: any }) {
  return request<API.GetInvitationsResult>('/users/teams/invitations', {
    method: 'GET',
    ...(options || {}),
  });
}

export async function getBillings(options?: { [key: string]: any }) {
  return request<API.GetBillingsResult>('/users/teams/billings', {
    method: 'GET',
    ...(options || {}),
  });
}

export async function responseInvitation(
  { token, type }: API.ResponseInvitationParams,
  options?: { [key: string]: any },
) {
  return request<API.ResponseInvitationResult>(`/users/teams/${type}/${token}`, {
    method: 'POST',
    ...(options || {}),
  });
}

export async function getCurrentBilling(
  body: API.GetCurrentBillingParams,
  options?: { [key: string]: any },
) {
  return request<API.GetCurrentBillingResult>('/users/teams/current-billing', {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function resetAPIKey(
  options?: { [key: string]: any },
) {
  return request<API.ResetAPIKeyResult>('/users/teams/reset-api-key', {
    method: 'POST',
    ...(options || {}),
  });
}