import type { FlowTemplateCategory } from '@/declarations/flowTemplate';
import {
  getFlowTemplateCategories,
  getFlowTemplateCategory,
} from '@/services/luluchat/flowTemplateCategories';
import { useRequest } from 'umi';
export default () => {
  const {
    data: flowTemplateCategories = [] as FlowTemplateCategory[],
    run: fetchGetFlowTemplateCategories,
    loading: isLoadingFetchGetFlowTemplateCategories,
  } = useRequest(getFlowTemplateCategories, { manual: true });

  const {
    data: flowTemplateCategory = {} as FlowTemplateCategory,
    run: fetchGetFlowTemplateCategory,
    loading: isLoadingFetchGetFlowTemplateCategory,
  } = useRequest(getFlowTemplateCategory, {
    manual: true,
  });

  return {
    flowTemplateCategories,
    fetchGetFlowTemplateCategories,
    isLoadingFetchGetFlowTemplateCategories,
    flowTemplateCategory,
    fetchGetFlowTemplateCategory,
    isLoadingFetchGetFlowTemplateCategory,
  };
};
