// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getWorkflows(
  body?: API.GetWorkflowsParams,
  options?: { [key: string]: any },
) {
  return request<API.GetWorkflowsResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/workflows?includes=conditions,actions`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function createWorkflow(
  body: API.CreateWorkflowParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateWorkflowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/workflows`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getWorkflow(
  { id, ...body }: API.GetWorkflowParams,
  options?: { [key: string]: any },
) {
  return request<API.GetWorkflowResult>(
    `/users/channels/${localStorage.getItem(
      'x-channel-id',
    )}/workflows/${id}?includes=conditions,actions`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function updateWorkflow(
  { id, ...body }: API.UpdateWorkflowParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateWorkflowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/workflows/${id}`,
    {
      method: 'PUT',
      data: body,
      ...(options || {}),
    },
  );
}

export async function activateWorkflow(
  { id }: API.ActivateWorkflowParams,
  options?: { [key: string]: any },
) {
  return request<API.ActivateWorkflowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/workflows/activate-flow/${id}`,
    {
      method: 'POST',
      ...(options || {}),
    },
  );
}

export async function deactivateWorkflow(
  { id }: API.DeactivateWorkflowParams,
  options?: { [key: string]: any },
) {
  return request<API.DeactivateWorkflowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/workflows/deactivate-flow/${id}`,
    {
      method: 'POST',
      ...(options || {}),
    },
  );
}

export async function getMatchedContacts(
  body: API.DeactivateWorkflowParams,
  options?: { [key: string]: any },
) {
  return request<API.DeactivateWorkflowResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/workflows/matched-contacts`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}
