import { CloseOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import cx from 'classnames';
import styles from './index.less';
export default ({
  onRemoveButtonType,
  title,
  image,
  content = '',
  onClick,
  clickable,
  canRemove = true,
}: {
  onRemoveButtonType: () => void;
  title: string;
  image: string;
  content: string;
  onClick: () => void;
  clickable: boolean;
  canRemove?: boolean;
}) => {
  return (
    <div className={cx(styles.root, { [styles.rootClickable]: clickable })} onClick={onClick}>
      <div className={styles.leftContent}>
        <Space>
          <img src={image} height="20" />
          <Typography>{title}</Typography>
        </Space>
        <Typography>{content}</Typography>
      </div>
      {canRemove ? (
        <div
          className={styles.rightContent}
          onClick={(e) => {
            e.stopPropagation();
            onRemoveButtonType();
          }}
        >
          <CloseOutlined />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
