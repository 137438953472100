import { toSelectData } from '@/adapters/select';
import ProForm, {
  ProFormDatePicker,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormTimePicker,
} from '@ant-design/pro-form';
import type { FormInstance } from 'antd';
import { Button, message, Spin } from 'antd';
import dayjs from 'dayjs';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useRef } from 'react';
import { useModel } from 'umi';

type ReminderFormProps = {
  wa_contact_id?: string;
  id?: number | undefined;
  onSuccess: () => void;
};

const ReminderForm: React.FC<ReminderFormProps> = (props) => {
  const { id, wa_contact_id, onSuccess } = props;
  const formRef = useRef<FormInstance>();
  const { teamUsers, fetchGetTeamUsers } = useModel('team');
  const { selectedChat, showChatDetails } = useModel('inbox');
  const { initialState } = useModel('@@initialState');
  const currentUser = initialState?.currentUser;

  useEffect(() => {
    fetchGetTeamUsers({
      perpage: 'all',
    });
  }, []);

  useEffect(() => {
    return () => {
      formRef.current?.resetFields();
    };
  }, []);

  const {
    reminder,
    fetchGetReminder,
    fetchCreateReminder,
    fetchUpdateReminder,
    isLoadingFetchGetReminder,
    closeReminderFormModal,
  } = useModel('reminder');

  useEffect(() => {
    if (id) {
      fetchGetReminder({ id });
    }
    return () => {
      formRef.current?.resetFields();
    };
  }, [id]);

  useEffect(() => {
    if (id && !_isEmpty(reminder)) {
      formRef.current?.setFieldsValue({
        user_ids: reminder?.user_ids,
        description: reminder?.description,
        title: reminder.title,
        date: dayjs(reminder?.meta?.remind).format('YYYY-MM-DD'),
        time: dayjs(reminder.meta?.remind).format('HH:mm'),
      });
    } else {
      const tomorrow = dayjs().add(1, 'd').format('YYYY-MM-DD');
      const time = '09:00';
      formRef.current?.setFieldsValue({
        user_ids: [initialState?.currentUser.id],
        title: `Follow up with ${selectedChat?.display_name || selectedChat?.name}`,
        date: tomorrow,
        time,
      });
    }
  }, [reminder]);

  const getReminderAt = (values: { date: string; time: string }) => {
    return `${dayjs(`${values.date} ${values.time}`).format('YYYY-MM-DD HH:mm:ss')}`;
  };

  const onFinishCreate = async (values: any) => {
    if (!wa_contact_id) return;
    const remind_at = getReminderAt(values);
    const payload = {
      title: values.title,
      wa_contact_id,
      remind_at: getReminderAt(values),
      description: values?.description,
      user_ids: values?.user_ids,
    };
    const res = await fetchCreateReminder(payload);
    if (res.status) {
      message.success(`A Reminder has been created for "${values.title}" at ${remind_at}.`);
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    }
  };

  const onFinishUpdate = async (values: any) => {
    if (!id || !wa_contact_id) return;
    const res = await fetchUpdateReminder({
      id,
      title: values.title,
      wa_contact_id,
      remind_at: getReminderAt(values),
      description: values?.description,
      user_ids: values?.user_ids,
    });
    if (res.status) {
      message.success('Reminder updated successfully.');
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    }
  };

  const teamUsersOptions = useMemo(
    () =>
      toSelectData({
        data: teamUsers,
        label: 'fullname',
        value: 'id',
        disabledCheck: (user) => user?.is_deleted,
      }),
    [teamUsers],
  );

  const canEdit = useMemo(() => {
    if (id) {
      if (reminder && currentUser) {
        if (currentUser?.id !== reminder?.meta?.created_by_id) return false;
      }
    }
    return {};
  }, [id, reminder, currentUser]);

  const onGoToChat = () => {
    if (reminder?.wa_contact_id) {
      //@todo: if not from the same channel will have problem
      showChatDetails(reminder?.wa_contact_id);
      closeReminderFormModal();
    }
  };

  if (id && isLoadingFetchGetReminder) return <Spin />;

  return (
    <ProForm
      formRef={formRef}
      scrollToFirstError
      name="basic"
      layout="vertical"
      onFinish={id ? onFinishUpdate : onFinishCreate}
      submitter={canEdit}
    >
      <ProFormSelect
        width="lg"
        name="user_ids"
        label="Notify to Team User"
        options={teamUsersOptions}
        placeholder="Please select a team user"
        fieldProps={{
          mode: 'multiple',
        }}
        rules={[
          {
            required: true,
            message: 'Please select team user to notify.',
          },
        ]}
        readonly={!canEdit}
      />
      <ProFormText
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input title.',
          },
        ]}
        width="lg"
        name="title"
        fieldProps={{
          maxLength: 100,
          showCount: true,
        }}
        readonly={!canEdit}
      />
      <ProFormDatePicker
        rules={[
          {
            required: true,
            message: 'Please select date.',
          },
        ]}
        width="lg"
        name="date"
        label="Date"
        readonly={!canEdit}
      />
      <ProFormTimePicker
        fieldProps={{
          showTime: { format: 'HH:mm' },
          format: 'HH:mm',
          minuteStep: 15,
        }}
        rules={[
          {
            required: true,
            message: 'Please select time.',
          },
        ]}
        width="lg"
        name="time"
        label="Time"
        readonly={!canEdit}
      />
      <ProFormTextArea
        label="Description (optional)"
        width="lg"
        name="description"
        readonly={!canEdit}
      />
      {id && (
        <Button
          onClick={() => {
            onGoToChat();
          }}
          style={{ marginRight: 8 }}
        >
          Go to Chat
        </Button>
      )}
    </ProForm>
  );
};

export default ReminderForm;
