import { Button, notification } from 'antd';
import defaultSettings from '../config/defaultSettings';

const { pwa } = defaultSettings;
const isHttps = document.location.protocol === 'https:';

const clearCache = async () => {
  if (window.caches) {
    try {
      const keys = await caches.keys();
      for (const key of keys) {
        await caches.delete(key);
      }
    } catch (e) {
      console.error('Failed to clear cache:', e);
    }
  }
};

// if pwa is true
if (pwa) {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('Service Worker registered: ', registration);
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker) {
              console.log('installingWorker', installingWorker);
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                    // Optionally, notify the user
                    notification.info({
                      message: 'New version available',
                      description:
                        'Hello! We have an exciting update ready for you. Please refresh to enjoy the latest features and improvements!',
                      btn: (
                        <Button
                          type="primary"
                          onClick={async () => {
                            await clearCache();
                            window.location.reload();
                          }}
                        >
                          Refresh
                        </Button>
                      ),
                      duration: 30000,
                      closeIcon: <></>,
                      key: 'updateAvailable',
                    });
                  } else {
                    // Content is cached for offline use.
                    console.log('Content is cached for offline use.');
                  }
                }
              };
            }
          };
        })
        .catch((error) => {
          console.error('Service Worker registration failed: ', error);
        });
    });
  }

  // // Listen for updates and show the prompt
  // navigator.serviceWorker.addEventListener('controllerchange', () => {
  //   if (confirm('A new version of the app is available. Do you want to use the latest version?')) {
  //     window.location.reload();
  //   }
  // });
  // // Notify user if offline now
  // window.addEventListener('sw.offline', () => {
  //   message.warning(useIntl().formatMessage({ id: 'app.pwa.offline' }));
  // });

  // // Pop up a prompt on the page asking the user if they want to use the latest version
  // window.addEventListener('sw.updated', (event: Event) => {
  //   const e = event as CustomEvent;
  //   const reloadSW = async () => {
  //     // Check if there is sw whose state is waiting in ServiceWorkerRegistration
  //     // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration
  //     const worker = e.detail && e.detail.waiting;
  //     if (!worker) {
  //       return true;
  //     }
  //     // Send skip-waiting event to waiting SW with MessageChannel
  //     await new Promise((resolve, reject) => {
  //       const channel = new MessageChannel();
  //       channel.port1.onmessage = (msgEvent) => {
  //         if (msgEvent.data.error) {
  //           reject(msgEvent.data.error);
  //         } else {
  //           resolve(msgEvent.data);
  //         }
  //       };
  //       worker.postMessage({ type: 'skip-waiting' }, [channel.port2]);
  //     });

  //     clearCache();
  //     window.location.reload();
  //     return true;
  //   };
  //   const key = `open${Date.now()}`;
  //   const btn = (
  //     <Button
  //       type="primary"
  //       onClick={() => {
  //         notification.close(key);
  //         reloadSW();
  //       }}
  //     >
  //       {useIntl().formatMessage({ id: 'app.pwa.serviceworker.updated.ok' })}
  //     </Button>
  //   );
  //   notification.open({
  //     message: useIntl().formatMessage({ id: 'app.pwa.serviceworker.updated' }),
  //     description: useIntl().formatMessage({ id: 'app.pwa.serviceworker.updated.hint' }),
  //     btn,
  //     key,
  //     onClose: async () => null,
  //   });
  // });
} else if ('serviceWorker' in navigator && isHttps) {
  // unregister service worker
  const { serviceWorker } = navigator;
  if (serviceWorker.getRegistrations) {
    serviceWorker.getRegistrations().then((sws) => {
      sws.forEach((sw) => {
        sw.unregister();
      });
    });
  }
  serviceWorker.getRegistration().then((sw) => {
    if (sw) sw.unregister();
  });

  clearCache();
}
