// @ts-ignore
/* eslint-disable */
import request from "./request";
import { API } from "./typings";

export async function getPlans(
  options?: { [key: string]: any }
) {
  return request<API.GetPlansResult>(`/users/plans?includes=prices`, {
    method: "GET",
    ...(options || {}),
  });
}

export async function checkoutPlan(
  body: API.CheckoutPlanParams,
  options?: { [key: string]: any }
) {
  return request<API.CheckoutPlanResult>(`/users/plans/checkout-v2`, {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}
