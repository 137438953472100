// @ts-ignore
/* eslint-disable */
import request from "./request";
import { API } from "./typings";

export async function getCampaigns(
  body?: API.GetCampaignsParams,
  options?: { [key: string]: any }
) {
  return request<API.GetCampaignsResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/campaigns`,
    {
      method: "GET",
      data: body,
      ...(options || {}),
    }
  );
}

export async function createCampaign(
  body: API.CreateCampaignParams,
  options?: { [key: string]: any }
) {
  return request<API.CreateCampaignResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/campaigns`,
    {
      method: "POST",
      data: body,
      ...(options || {}),
    }
  );
}

export async function countContact(
  body: API.CountContactParams,
  options?: { [key: string]: any }
) {
  return request<API.CountContactResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/campaigns/count-contact`,
    {
      method: "POST",
      data: body,
      ...(options || {}),
    }
  );
}


export async function getCampaign(
  { id, ...body }: API.GetCampaignParams,
  options?: { [key: string]: any }
) {
  return request<API.GetCampaignResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/campaigns/${id}`,
    {
      method: "GET",
      data: body,
      ...(options || {}),
    }
  );
}

export async function importCampaign(
  body: API.ImportCampaignParams,
  options?: { [key: string]: any }
) {
  return request<API.ImportCampaignResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/campaigns/import`,
    {
      method: "POST",
      data: body,
      ...(options || {}),
    }
  );
}

export async function getCampaignQueue(
  { id, ...body }: API.GetCampaignQueueParams,
  options?: { [key: string]: any }
) {
  return request<API.GetCampaignQueueResult>(
    `/users/channels/${localStorage.getItem(
      'x-channel-id'
    )}/campaigns/${id}/queues`,
    {
      method: "GET",
      data: body,
      ...(options || {}),
    }
  );
}

export async function toggleCampaign(
  { id, action }: API.ToggleCampaignParams,
  options?: { [key: string]: any }
) {
  return request<API.ToggleCampaignResult>(
    `/users/channels/${localStorage.getItem(
      'x-channel-id'
    )}/campaigns/${id}/${action}`,
    {
      method: "POST",
      ...(options || {}),
    }
  );
}
