// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getWidget(options?: { [key: string]: any }) {
  return request<API.GetWidgetResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/widget`,
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

export async function updateWidget(body: API.UpdateWidgetParams, options?: { [key: string]: any }) {
  return request<API.UpdateWidgetResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/widget`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}
