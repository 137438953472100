import type { InboxTab } from '@/declarations/inbox';
import {
  addContactsToTab,
  bulkUpdateInboxTab,
  closeConversation,
  createInboxTab,
  deleteInboxTab,
  getInboxTab,
  getInboxTabs,
  removeContactsFromTab,
  updateInboxTab,
} from '@/services/luluchat/inboxTabs';
import { useState } from 'react';
import { useRequest } from 'umi';
import type { API } from '@/services/luluchat/typings.d';

export default () => {
  const [activeTab, setActiveTab] = useState<InboxTab | null>(null);
  const {
    data: inboxTabs,
    run: fetchGetInboxTabs,
    loading: isLoadingFetchGetInboxTabs,
  } = useRequest(getInboxTabs, {
    manual: true,
    formatResult(res: API.GetInboxTabsResult) {
      return res;
    },
  });

  const { run: fetchCreateInboxTab, loading: isLoadingFetchCreateInboxTab } = useRequest(
    createInboxTab,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const {
    data: inboxTab = {} as InboxTab,
    run: fetchGetInboxTab,
    loading: isLoadingFetchGetInboxTab,
  } = useRequest(getInboxTab, {
    manual: true,
  });

  const { run: fetchUpdateInboxTab, loading: isLoadingFetchUpdateInboxTab } = useRequest(
    updateInboxTab,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchDeleteInboxTab, loading: isLoadingFetchDeleteInboxTab } = useRequest(
    deleteInboxTab,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchBulkUpdateInboxTab, loading: isLoadingFetchBulkUpdateInboxTab } = useRequest(
    bulkUpdateInboxTab,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchAddContactsToTab, loading: isLoadingFetchAddContactsToTab } = useRequest(
    addContactsToTab,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchRemoveContactsFromTab, loading: isLoadingFetchRemoveContactsFromTab } =
    useRequest(removeContactsFromTab, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const { run: fetchCloseConversation, loading: isLoadingFetchCloseConversation } = useRequest(
    closeConversation,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  return {
    activeTab,
    setActiveTab,
    inboxTabs,
    fetchGetInboxTabs,
    isLoadingFetchGetInboxTabs,
    fetchCreateInboxTab,
    isLoadingFetchCreateInboxTab,
    inboxTab,
    fetchGetInboxTab,
    isLoadingFetchGetInboxTab,
    fetchUpdateInboxTab,
    isLoadingFetchUpdateInboxTab,
    fetchDeleteInboxTab,
    isLoadingFetchDeleteInboxTab,
    fetchBulkUpdateInboxTab,
    isLoadingFetchBulkUpdateInboxTab,
    fetchAddContactsToTab,
    isLoadingFetchAddContactsToTab,
    fetchRemoveContactsFromTab,
    isLoadingFetchRemoveContactsFromTab,
    fetchCloseConversation,
    isLoadingFetchCloseConversation,
  };
};
