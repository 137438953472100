import AIAssistantActions from '@/components/AIAssistantActions';
import { Button, Input, Modal, Spin, Typography } from 'antd';
import { useModel } from 'umi';
import styles from './style.less';

export default () => {
  const {
    originalContent,
    isModalVisible,
    onCloseModal,
    suggestion,
    isLoading,
    onReplaceContent,
    setOriginalContent,
  } = useModel('aiAssistant');

  return (
    <Modal
      title="AI Assistant"
      open={isModalVisible}
      onCancel={() => {
        onCloseModal();
      }}
      footer={[
        <Button
          key={0}
          loading={isLoading}
          disabled={!suggestion || isLoading}
          type="primary"
          onClick={() => onReplaceContent(suggestion)}
        >
          Use AI Suggestion
        </Button>,
      ]}
      destroyOnClose
      width={1200}
    >
      <div className={styles.root}>
        <div className={styles.aIAssistantActionsWrapper}>
          <AIAssistantActions
            contentText={originalContent}
            dropdownProps={{ open: isModalVisible, overlayClassName: styles.aIAssistantActions }}
            buttonProps={{ style: { visibility: 'hidden' } }}
          />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.contentItemWrapper}>
            <Typography>Content</Typography>
            <Input.TextArea
              value={originalContent}
              onChange={(e) => setOriginalContent(e.target.value)}
              rows={20}
            />
          </div>
          <div className={styles.contentItemWrapper}>
            <Typography>AI Suggestion: </Typography>
            {isLoading ? (
              <Spin />
            ) : (
              <>
                <Typography.Paragraph
                  copyable={{
                    text: suggestion,
                    format: 'text/plain',
                    icon: [
                      <Button size="small" key={0} type="dashed">
                        Copy to clipboard
                      </Button>,
                      <Button size="small" key={1} type="dashed">
                        Copied
                      </Button>,
                    ],
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: suggestion.trim().replace(/(\r\n|\r|\n)/g, '<br />'),
                    }}
                  />
                </Typography.Paragraph>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
