import { getProfile, updateProfile } from '@/services/luluchat/users';
import { adminLogin } from '@/services/luluchat/usersAuth';

import { useRequest } from 'umi';

export default () => {
  const {
    data: currentUser = {},
    run: fetchGetProfile,
    loading: isLoadingFetchGetProfile,
  } = useRequest(getProfile, { manual: true });
  const { run: fetchUpdateProfile, loading: isLoadingFetchUpdateProfile } = useRequest(
    updateProfile,
    { manual: true },
  );
  const { run: fetchAdminLogin, loading: isLoadingFetchAdminLogin } = useRequest(adminLogin, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  return {
    currentUser,
    fetchGetProfile,
    isLoadingFetchGetProfile,
    fetchUpdateProfile,
    isLoadingFetchUpdateProfile,
    fetchAdminLogin,
    isLoadingFetchAdminLogin,
  };
};
