// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/angyeowchuan/Documents/luluchat-app/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/Users/angyeowchuan/Documents/luluchat-app/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/vendor",
        "layout": false,
        "routes": [
          {
            "path": "/vendor/shopify",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Vendor__Shopify' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Vendor/Shopify'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/vendor/shopify-oauth",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Vendor__Shopify-OAuth' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Vendor/Shopify-OAuth'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/vendor/chatgpt",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Vendor__ChatGPT' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Vendor/ChatGPT'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "redirect": "/user/login",
            "exact": true
          },
          {
            "path": "/user/login",
            "layout": false,
            "name": "login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/user/Login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/register",
            "layout": false,
            "name": "register",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/user/Login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/onboarding",
            "layout": false,
            "name": "onboarding",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__onboarding' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/user/onboarding'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "team-invitation",
            "path": "/user/team-invitation",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__team-invitation' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/user/team-invitation'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "auto-login",
            "path": "/user/auto-login",
            "layout": false,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__auto-login' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/user/auto-login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/dashboard",
        "name": "dashboard",
        "icon": "icon-dashboard",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashboard__workplace' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/dashboard/workplace'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/result/success",
        "name": "dashboard",
        "footerRender": false,
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__result__success' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/result/success'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/result/in-progress",
        "name": "dashboard",
        "footerRender": false,
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__result__in-progress' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/result/in-progress'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/result/failed",
        "name": "dashboard",
        "footerRender": false,
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__result__fail' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/result/fail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/templates",
        "name": "flow-templates",
        "footerRender": false,
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FlowTemplates__Categories' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/FlowTemplates/Categories'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/templates/category/:id",
        "name": "flow-templates",
        "footerRender": false,
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FlowTemplates__Category' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/FlowTemplates/Category'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/result/cancelled",
        "name": "dashboard",
        "footerRender": false,
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__result__cancel' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/result/cancel'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/plans",
        "name": "plans",
        "footerRender": false,
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Plan' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Plan'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/inbox",
        "name": "inbox",
        "icon": "icon-inbox",
        "footerRender": false,
        "access": "hasInbox",
        "routes": [
          {
            "path": "/inbox",
            "redirect": "/inbox/list",
            "exact": true
          },
          {
            "path": "/inbox/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Inbox' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Inbox'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/automations",
        "name": "automations",
        "icon": "icon-automations",
        "routes": [
          {
            "path": "/automations",
            "redirect": "/automations/flows",
            "access": "hasAutomationFlow",
            "exact": true
          },
          {
            "name": "flows",
            "path": "/automations/flows",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Automations__FlowList' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Automations/FlowList'), loading: LoadingComponent}),
            "footerRender": false,
            "access": "hasAutomationFlow",
            "exact": true
          },
          {
            "name": "flow-details",
            "path": "/automations/flow/details/preview/:key",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Automations__FlowDetails' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Automations/FlowDetails'), loading: LoadingComponent}),
            "hideInMenu": true,
            "footerRender": false,
            "access": "hasAutomationFlow",
            "exact": true
          },
          {
            "name": "flow-details",
            "path": "/automations/flow/details/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Automations__FlowDetails' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Automations/FlowDetails'), loading: LoadingComponent}),
            "hideInMenu": true,
            "footerRender": false,
            "access": "hasAutomationFlow",
            "exact": true
          },
          {
            "name": "keywords",
            "path": "/automations/keywords",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Automations__KeywordList' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Automations/KeywordList'), loading: LoadingComponent}),
            "access": "hasAutomationKeyword",
            "exact": true
          },
          {
            "name": "growth-tools",
            "path": "/automations/growth-tools",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Automations__GrowthTools__List' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Automations/GrowthTools/List'), loading: LoadingComponent}),
            "access": "hasGrowthTool",
            "exact": true
          },
          {
            "name": "workflows",
            "path": "/automations/workflows",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Automations__Workflows' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Automations/Workflows'), loading: LoadingComponent}),
            "footerRender": false,
            "access": "hasWorkflow",
            "exact": true
          },
          {
            "name": "create-workflow",
            "path": "/automations/workflows/create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Automations__WorkflowDetails' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Automations/WorkflowDetails'), loading: LoadingComponent}),
            "footerRender": false,
            "hideInMenu": true,
            "access": "hasWorkflow",
            "exact": true
          },
          {
            "name": "workflow-details",
            "path": "/automations/workflows/details/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Automations__WorkflowDetails' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Automations/WorkflowDetails'), loading: LoadingComponent}),
            "footerRender": false,
            "hideInMenu": true,
            "access": "hasWorkflow",
            "exact": true
          },
          {
            "name": "web-widget",
            "path": "/automations/web-widget",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Automations__WebWidget' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Automations/WebWidget'), loading: LoadingComponent}),
            "access": "hasWebWidget",
            "exact": true
          }
        ]
      },
      {
        "path": "/broadcasts",
        "name": "broadcasts",
        "icon": "icon-broadcasts",
        "access": "hasBroadcast",
        "footerRender": false,
        "routes": [
          {
            "path": "/broadcasts",
            "redirect": "/broadcasts/list",
            "exact": true
          },
          {
            "path": "/broadcasts/list",
            "name": "list",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Broadcasts__List' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Broadcasts/List'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/broadcasts/create",
            "name": "create",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Broadcasts__Create' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Broadcasts/Create'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/broadcasts/details/:id",
            "name": "details",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Broadcasts__Details' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Broadcasts/Details'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/forms/create",
        "name": "forms.create",
        "access": "hasForm",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Forms__Create' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Forms/Create'), loading: LoadingComponent}),
        "hideInMenu": true,
        "footerRender": false,
        "exact": true
      },
      {
        "path": "/forms/details/:id",
        "name": "forms.details",
        "access": "hasForm",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Forms__Create' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Forms/Create'), loading: LoadingComponent}),
        "hideInMenu": true,
        "footerRender": false,
        "exact": true
      },
      {
        "path": "/contacts",
        "name": "contacts",
        "icon": "icon-contacts",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Contacts__List' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Contacts/List'), loading: LoadingComponent}),
        "access": "hasContact",
        "exact": true
      },
      {
        "path": "/message-templates",
        "name": "message-templates",
        "icon": "icon-message-template",
        "routes": [
          {
            "name": "message-templates",
            "path": "/message-templates",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MessageTemplates__List' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/MessageTemplates/List'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "create",
            "path": "/message-templates/create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MessageTemplates__Details' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/MessageTemplates/Details'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "details",
            "path": "/message-templates/details/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MessageTemplates__Details' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/MessageTemplates/Details'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/reports",
        "name": "reports",
        "icon": "icon-reports",
        "access": "hasReport",
        "footerRender": false,
        "routes": [
          {
            "path": "/reports",
            "redirect": "/reports/logs",
            "exact": true
          },
          {
            "name": "conversations",
            "icon": "smile",
            "path": "/reports/conversations",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Reports__Conversations' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Reports/Conversations'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "tags",
            "icon": "smile",
            "path": "/reports/tags",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Reports__Tags' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Reports/Tags'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "team-users",
            "icon": "smile",
            "path": "/reports/team-users",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Reports__TeamUsers' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Reports/TeamUsers'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "logs",
            "icon": "smile",
            "path": "/reports/logs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Reports__Logs' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Reports/Logs'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/forms",
        "name": "forms",
        "access": "hasForm",
        "icon": "icon-survey",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Forms__List' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Forms/List'), loading: LoadingComponent}),
        "footerRender": false,
        "exact": true
      },
      {
        "path": "/bookings",
        "name": "bookings",
        "access": "hasBooking",
        "icon": "icon-survey",
        "routes": [
          {
            "path": "/bookings",
            "redirect": "/bookings/calendars/list",
            "exact": true
          },
          {
            "path": "/bookings/calendars/list",
            "name": "calendars.list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Bookings__Calendars__List' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Bookings/Calendars/List'), loading: LoadingComponent}),
            "footerRender": false,
            "exact": true
          },
          {
            "path": "/bookings/calendars/details/:id",
            "name": "calendars.details",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Bookings__Calendars__Create' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Bookings/Calendars/Create'), loading: LoadingComponent}),
            "hideInMenu": true,
            "footerRender": false,
            "exact": true
          },
          {
            "path": "/bookings/calendars/create",
            "name": "calendars.create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Bookings__Calendars__Create' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Bookings/Calendars/Create'), loading: LoadingComponent}),
            "hideInMenu": true,
            "footerRender": false,
            "exact": true
          },
          {
            "path": "/bookings/appointments/list",
            "name": "appointments.list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Bookings__List' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Bookings/List'), loading: LoadingComponent}),
            "footerRender": false,
            "exact": true
          }
        ]
      },
      {
        "name": "create-loyalty-card",
        "path": "/loyalty/loyalty-card/create",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Loyalty__LoyaltyCards__Create' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Loyalty/LoyaltyCards/Create'), loading: LoadingComponent}),
        "footerRender": false,
        "exact": true
      },
      {
        "name": "loyalty-card-details",
        "path": "/loyalty/loyalty-card/details/:id",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Loyalty__LoyaltyCards__Create' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Loyalty/LoyaltyCards/Create'), loading: LoadingComponent}),
        "footerRender": false,
        "exact": true
      },
      {
        "name": "create-reward",
        "path": "/loyalty/loyalty-card/details/:id/rewards/create",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Loyalty__Rewards__Create' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Loyalty/Rewards/Create'), loading: LoadingComponent}),
        "footerRender": false,
        "exact": true
      },
      {
        "name": "reward-details",
        "path": "/loyalty/loyalty-card/details/:id/rewards/:rewardId",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Loyalty__Rewards__Create' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Loyalty/Rewards/Create'), loading: LoadingComponent}),
        "footerRender": false,
        "exact": true
      },
      {
        "path": "/apps/details/:id",
        "name": "apps.details",
        "icon": "icon-apps",
        "access": "hasApp",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Apps__Details' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/Apps/Details'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/settings",
        "name": "settings",
        "icon": "icon-settings",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__settings' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/account/settings'), loading: LoadingComponent}),
        "footerRender": false,
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/index.html",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/angyeowchuan/Documents/luluchat-app/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
