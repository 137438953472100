import type { INotification } from '@/declarations/notification';
import { BellOutlined } from '@ant-design/icons';
import { Badge, message, Spin, Tabs } from 'antd';
import classNames from 'classnames';
import React from 'react';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import type { NoticeListProps } from './NoticeList';
import NoticeList from './NoticeList';

const { TabPane } = Tabs;

export type NoticeIconProps = {
  count?: number;
  bell?: React.ReactNode;
  title?: string;
  className?: string;
  loading?: boolean;
  onItemClick?: (item: INotification, tabProps: NoticeIconTabChildProps) => void;
  onViewMore?: (tabProps: NoticeIconTabChildProps, e: MouseEvent) => void;
  onTabChange?: (tabTile: string) => void;
  style?: React.CSSProperties;
  viewMoreText?: string;
  emptyImage?: string;
  children?: React.ReactElement<NoticeIconTabChildProps>[];
  visible?: boolean;
  onPopupVisibleChange?: (visible: boolean) => void;
};

interface NoticeIconTabChildProps extends NoticeListProps {
  title: string;
  tabKey: string;
}

const NoticeIcon: React.FC<NoticeIconProps> & {
  Tab: typeof NoticeList;
} = (props) => {
  const getNotificationBox = (): React.ReactNode => {
    const { children, loading, onTabChange, onItemClick, onViewMore, viewMoreText } = props;
    if (!children) {
      return null;
    }
    const panes: React.ReactNode[] = [];
    React.Children.forEach(children, (child: React.ReactElement<NoticeIconTabChildProps>): void => {
      if (!child) {
        return;
      }
      if (child.key === 'reminder') {
        panes.push(child);
      } else {
        const { list, title, tabKey, showViewMore, isLoading } = child.props;
        const len = list && list.length ? list.length : 0;
        const msgCount = count || count === 0 ? count : len;
        const tabTitle: string = msgCount > 0 ? `${title} (${msgCount})` : title;
        panes.push(
          <TabPane tab={tabTitle} key={tabKey}>
            <NoticeList
              title={tabTitle}
              tabKey={tabKey}
              viewMoreText={viewMoreText}
              list={list}
              onClick={(item): void => onItemClick && onItemClick(item, child.props)}
              onViewMore={(event): void => onViewMore && onViewMore(child.props, event)}
              showViewMore={showViewMore}
              isLoading={isLoading}
            />
          </TabPane>,
        );
      }
    });

    return (
      <>
        <Spin spinning={loading} delay={300}>
          <Tabs className={styles.tabs} onChange={onTabChange}>
            {panes}
          </Tabs>
        </Spin>
      </>
    );
  };

  const { className, count, bell, visible, onPopupVisibleChange } = props;
  const noticeButtonClass = classNames(className, styles.noticeButton);
  const notificationBox = getNotificationBox();
  const NoticeBellIcon = bell || <BellOutlined style={{ fontSize: 18 }} className={styles.icon} />;
  const trigger = (
    <span className={classNames(noticeButtonClass, { opened: visible })}>
      <Badge count={count && count > 0 ? count : 0} style={{ boxShadow: 'none' }} className={styles.badge}>
        {NoticeBellIcon}
      </Badge>
    </span>
  );

  const onChangeEnableBrowserNotification = () => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        console.log('requestPermission', permission);
        if (permission === 'granted') {
          message.success(
            'You will be receiving luluchat notifications from the browser in the future.',
          );
        }
      });
    }
  };

  if (!notificationBox) {
    return trigger;
  }

  return (
    <HeaderDropdown
      placement="bottomRight"
      overlay={notificationBox}
      overlayClassName={styles.popover}
      trigger={['click']}
      open={visible}
      onOpenChange={(e) => {
        onChangeEnableBrowserNotification();
        if (typeof onPopupVisibleChange === 'function') {
          onPopupVisibleChange(e);
        }
      }}
    >
      {trigger}
    </HeaderDropdown>
  );
};

NoticeIcon.defaultProps = {
  emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg',
};

NoticeIcon.Tab = NoticeList;

export default NoticeIcon;
