// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getProfile(options?: { [key: string]: any }) {
  return request<API.GetProfileResult>('/users/me?includes=teams', {
    method: 'GET',
    ...(options || {}),
  });
}

export async function updateProfile(
  body: API.UpdateProfileParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateProfileResult>(`/users/me`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function deleteAccount(body: API.DeleteAccountParams) {
  return request<API.DeleteAccountResult>('/users/delete-account', {
    method: 'POST',
    data: body,
  });
}
