import type { Team, TeamUser } from '@/declarations/team';
import {
  createBillingPortalSession,
  createOnboarding,
  extendInvitation,
  getCurrentBilling,
  getInvitations,
  getTeams,
  getTeamUsers,
  responseInvitation,
  sendInvitation,
  updateTeam,
  updateTeamUser,
  resetAPIKey,
} from '@/services/luluchat/teams';
import { useRequest } from 'umi';

export default () => {
  const {
    data: currentBilling,
    run: fetchGetCurrentBilling,
    loading: isLoadingFetchGetCurrentBilling,
  } = useRequest(getCurrentBilling, { manual: true });

  const {
    data: teams = [] as Team[],
    run: fetchGetTeams,
    loading: isLoadingFetchGetTeams,
  } = useRequest(getTeams, { manual: true });

  const {
    data: teamUsers = [] as TeamUser[],
    run: fetchGetTeamUsers,
    loading: isLoadingFetchGetTeamUsers,
  } = useRequest(getTeamUsers, { manual: true });

  const {
    data: invitations = [],
    run: fetchGetInvitations,
    loading: isLoadingFetchGetInvitations,
  } = useRequest(getInvitations, { manual: true });

  const { run: fetchAcceptInvitation, loading: isLoadingFetchAcceptInvitation } = useRequest(
    (token: string) =>
      responseInvitation({
        token,
        type: 'accept-invitation',
      }),
    {
      manual: true,
      formatResult(res) {
        return res;
      },
      onSuccess: (res: Team) => {
        if (res?.uuid) {
          localStorage.setItem('x-team-id', res?.uuid);
        }
      },
    },
  );

  const {
    run: fetchCreateBillingPortalSession,
    loading: isLoadingFetchCreateBillingPortalSession,
  } = useRequest(() => createBillingPortalSession(), {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchRejectInvitation, loading: isLoadingFetchRejectInvitation } = useRequest(
    (token: string) =>
      responseInvitation({
        token,
        type: 'reject-invitation',
      }),
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchVerifyInvitation, loading: isLoadingFetchVerifyInvitation } = useRequest(
    (token: string) =>
      responseInvitation({
        token,
        type: 'verify-invitation',
      }),
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateTeam, loading: isLoadingFetchUpdateTeam } = useRequest(updateTeam, {
    manual: true,
  });

  const { run: fetchUpdateTeamUser, loading: isLoadingFetchUpdateTeamUser } = useRequest(
    updateTeamUser,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchSendInvitation, loading: isLoadingSendInvitation } = useRequest(
    sendInvitation,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
      onSuccess: () => {
        fetchGetInvitations();
      },
    },
  );

  const { run: fetchCreateOnboarding, loading: isLoadingCreateOnboarding } = useRequest(
    createOnboarding,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchExtendInvitation, loading: isLoadingExtendInvitation } = useRequest(
    extendInvitation,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
      onSuccess: () => {
        fetchGetInvitations();
      },
    },
  );

  const { run: fetchResetAPIKey, loading: isLoadingResetAPIKey } = useRequest(
    resetAPIKey,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );


  return {
    teamUsers,
    fetchGetTeamUsers,
    isLoadingFetchGetTeamUsers,
    teams,
    fetchGetTeams,
    isLoadingFetchGetTeams,
    invitations,
    fetchGetInvitations,
    isLoadingFetchGetInvitations,
    fetchUpdateTeam,
    isLoadingFetchUpdateTeam,
    fetchSendInvitation,
    isLoadingSendInvitation,
    fetchExtendInvitation,
    isLoadingExtendInvitation,
    fetchAcceptInvitation,
    isLoadingFetchAcceptInvitation,
    fetchRejectInvitation,
    isLoadingFetchRejectInvitation,
    fetchVerifyInvitation,
    isLoadingFetchVerifyInvitation,
    fetchCreateBillingPortalSession,
    isLoadingFetchCreateBillingPortalSession,
    fetchCreateOnboarding,
    isLoadingCreateOnboarding,
    fetchUpdateTeamUser,
    isLoadingFetchUpdateTeamUser,
    currentBilling,
    fetchGetCurrentBilling,
    isLoadingFetchGetCurrentBilling,
    fetchResetAPIKey,
    isLoadingResetAPIKey,
  };
};
