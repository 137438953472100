import { Button, Result } from 'antd';
import type { ErrorInfo } from 'react';
import React from 'react';
import * as Sentry from '@sentry/react';

const clearCache = async () => {
  if (window.caches) {
    try {
      const keys = await caches.keys();
      for (const key of keys) {
        await caches.delete(key);
      }
    } catch (e) {
      console.error('Failed to clear cache:', e);
    }
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
class ErrorBoundary extends React.Component<
  { children?: React.ReactNode },
  { hasError: boolean; errorInfo: string }
> {
  state = { hasError: false, errorInfo: '' };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorInfo: error.message };
  }

  componentDidCatch(error: any, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
    // Log error to Sentry
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Result
          status="error"
          title="Something went wrong. Please try reload the page."
          subTitle={this.state.errorInfo}
          extra={[
            <Button
              key={0}
              type="primary"
              onClick={async () => {
                await clearCache();
                window.location.reload();
              }}
            >
              Reload Page
            </Button>,
          ]}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
