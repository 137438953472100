import type {
  ChannelStatus,
  ChatUpdate,
  ChatUpdateType,
  Message,
  MessageUpdate,
} from '@/declarations/channel';
import type { INotification } from '@/declarations/notification';
import type { CurrentUser } from '@/declarations/user';
import * as Sentry from '@sentry/react';
import _get from 'lodash/get';

interface SubscribeMessageProps {
  channel_uuid: string;
  user_uuid: string;
  isLimited: boolean;
  onMessageReceived: (data: Message) => void;
}
interface SubscribeChannelProps {
  channel_uuid: string;
  onQrChanged: (data: { url: string; timestamp: number }) => void;
  onStatusChanged: (status: ChannelStatus) => void;
  onWindowRefreshed: (data: { chat_id: string; last_reply: Date }) => void;
  onMessageUpdate: (data: { updates: MessageUpdate[] }) => void;
  onChatUpdate: (data: { type: ChatUpdateType; updates: ChatUpdate[] }) => void;
  onTabUpdate: (data: { type: 'tab-update' }) => void;
}

interface SubscribeNotificationProps {
  uuid: CurrentUser['uuid'];
  onReceivesNotification: (notification: INotification) => void;
}
const subscribedChannels = new Set();

export const AblySubscription = (function () {
  async function init() {
    if (!ABLY_API_KEY) return null;
    const Ably = await import(/* webpackChunkName: "ably" */ 'ably');
    const realtime = new Ably.Realtime({
      key: ABLY_API_KEY,
    });
    await realtime.connection.once('connected');
    return {
      realtime,
      subscribeNotification: ({ uuid, onReceivesNotification }: SubscribeNotificationProps) => {
        if (!instance) return;
        const channelName = `push-notification:n-${uuid}`;
        const channel = instance.realtime.channels.get(channelName);
        if (subscribedChannels.has(channelName)) {
          if (channel.state === 'attached') {
            console.log(`subscribeNotification Already subscribed to ${channelName}`);
            return;
          } else {
            // console.log('subscribeNotification ably_duplicate_subscribed_channel', channel);
            // Sentry.setExtras({
            //   channel,
            //   channelState: channel?.state,
            // });
            // Sentry.setTag('custom_event', 'ably_duplicate_subscribed_channel');
            // Sentry.captureException('Ably duplicate subscribed channel');
          }
        }
        channel.on((stateChange) => {
          if (stateChange?.current === 'attached') {
            subscribedChannels.add(channelName);
          }
        });
        channel.subscribe((response) => {
          if (typeof onReceivesNotification === 'function') {
            onReceivesNotification(response?.data?.data);
          }
        });
      },
      subscribeChannel: async ({
        channel_uuid,
        onQrChanged,
        onStatusChanged,
        onWindowRefreshed,
        onMessageUpdate,
        onChatUpdate,
        onTabUpdate,
      }: SubscribeChannelProps) => {
        if (!instance) return;
        const channelName = `push-notification:${channel_uuid}`;
        const channel = instance.realtime.channels.get(channelName);
        if (subscribedChannels.has(channelName)) {
          if (channel.state === 'attached') {
            console.log(`subscribeChannel Already subscribed to ${channelName}`);
            return;
          } else {
            // console.log('subscribeChannel ably_duplicate_subscribed_channel', channel);
            // Sentry.setExtras({
            //   channel,
            //   channelState: channel?.state,
            // });
            // Sentry.setTag('custom_event', 'ably_duplicate_subscribed_channel');
            // Sentry.captureException('Ably duplicate subscribed channel');
          }
        }
        channel.on((stateChange) => {
          if (stateChange?.current === 'attached') {
            subscribedChannels.add(channelName);
          }
          if (stateChange?.current !== 'attached' && stateChange?.current !== 'attaching') {
            // console.log('Channel State changed on subscribeChannel', stateChange);
            // Sentry.setExtras({
            //   stateChange,
            // });
            // Sentry.setTag('custom_event', 'ably_state_changed');
            // Sentry.captureException('Ably state changed');
          }
        });
        return await channel.subscribe((response) => {
          console.log('@from ably - subscribeChannel:', response);
          const key:
            | 'tab-update'
            | 'qr'
            | 'status-changed'
            | 'message-update'
            | 'chat-update'
            | 'window-refreshed' = _get(response, 'data.key');
          const data = _get(response, 'data.data');
          switch (key) {
            case 'tab-update':
              // affect tab list
              onTabUpdate(data);
              break;

            case 'chat-update':
              // affect chat list, change chat list info when tag, read status, archive and etc
              onChatUpdate(data);
              break;

            case 'qr':
              //  affect qr refresh, change page qr
              onQrChanged(data);
              break;

            case 'window-refreshed':
              //  affect WABA chatroom refresh, change WABA chatroom conversation open
              onWindowRefreshed(data);
              break;

            case 'message-update':
              // affect chatroom message, change single message status, deleted, edited, read ....
              onMessageUpdate(data);
              break;

            case 'status-changed':
              // affect page refresh, channel status changed
              const status = data.status;
              onStatusChanged(status);
              break;

            default:
              break;
          }
        });
      },
      subscribeMessage: async ({
        channel_uuid,
        user_uuid,
        isLimited,
        onMessageReceived,
      }: SubscribeMessageProps) => {
        if (!instance) return;
        const channelName = isLimited
          ? `push-notification:${channel_uuid}-${user_uuid}`
          : `push-notification:${channel_uuid}-all`;
        const channel = instance.realtime.channels.get(channelName);
        if (subscribedChannels.has(channelName)) {
          if (channel.state === 'attached') {
            console.log(`subscribeMessage Already subscribed to ${channelName}`);
            return;
          } else {
            // console.log('subscribeMessage ably_duplicate_subscribed_channel', channel);
            // Sentry.setExtras({
            //   channel,
            //   channelState: channel?.state,
            // });
            // Sentry.setTag('custom_event', 'ably_duplicate_subscribed_channel');
            // Sentry.captureException('Ably duplicate subscribed channel');
          }
        }
        channel.on((stateChange) => {
          if (stateChange?.current === 'attached') {
            subscribedChannels.add(channelName);
          }
          if (stateChange?.current !== 'attached' && stateChange?.current !== 'attaching') {
            // console.log('Channel State changed on subscribeMessage', stateChange);
            // Sentry.setExtras({
            //   stateChange,
            // });
            // Sentry.setTag('custom_event', 'ably_state_changed');
            // Sentry.captureException('Ably state changed');
          }
        });
        return await channel.subscribe((response) => {
          console.log('@from ably - subscribeMessage:', response);
          const key: 'message' = _get(response, 'data.key');
          const data = _get(response, 'data.data');
          switch (key) {
            case 'message':
              onMessageReceived(data);
              break;

            default:
              break;
          }
        });
      },
    };
  }
  let instance: null | {
    realtime: any;
    subscribeChannel: (a: SubscribeChannelProps) => void;
    subscribeNotification: (a: SubscribeNotificationProps) => void;
    subscribeMessage: (a: SubscribeMessageProps) => void;
  } = null;
  return {
    getInstance: async () => {
      if (instance === null) {
        instance = await init();
      }
      return instance;
    },
  };
})();
