import type { FlowTemplate } from '@/declarations/flowTemplate';
import {
  getFlowTemplate,
  getFlowTemplates,
  useFlowTemplate,
} from '@/services/luluchat/flowTemplates';
import { useRequest } from 'umi';
export default () => {
  const {
    data: flowTemplates = [] as FlowTemplate[],
    run: fetchGetFlowTemplates,
    loading: isLoadingFetchGetFlowTemplates,
  } = useRequest(getFlowTemplates, { manual: true });

  const { run: fetchUseFlowTemplate, loading: isLoadingFetchUseFlowTemplate } = useRequest(
    useFlowTemplate,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const {
    data: flowTemplate = {} as FlowTemplate,
    run: fetchGetFlowTemplate,
    loading: isLoadingFetchGetFlowTemplate,
  } = useRequest(getFlowTemplate, {
    manual: true,
  });

  return {
    flowTemplates,
    fetchGetFlowTemplates,
    isLoadingFetchGetFlowTemplates,
    fetchUseFlowTemplate,
    isLoadingFetchUseFlowTemplate,
    flowTemplate,
    fetchGetFlowTemplate,
    isLoadingFetchGetFlowTemplate,
  };
};
