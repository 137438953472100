import { message, Modal, Segmented, Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Link, useModel } from 'umi';

import ReminderForm from '@/components/ReminderForm';
import ReminderList from '@/components/ReminderList';
import type { CurrentUser } from '@/declarations/user';
import styles from './index.less';
import NoticeIcon from './NoticeIcon';
const { TabPane } = Tabs;

export type GlobalHeaderRightProps = {
  fetchingNotices?: boolean;
  onNoticeVisibleChange?: (visible: boolean) => void;
  onNoticeClear?: (tabName?: string) => void;
};

const NoticeIconView = () => {
  const {
    fetchGetNotifications,
    notifications,
    isLoadingFetchGetNotifications,
    fetchMarkNotificationAsRead,
    onClickNotification,
  } = useModel('notification');
  const {
    setCurrentReminder,
    currentReminder,
    closeReminderFormModal,
    isReminderFormOpen,
    fetchGetReminders,
    reminders,
    isLoadingFetchGetReminders,
  } = useModel('reminder');
  const onFetchGetNotifications = async () => await fetchGetNotifications({ perpage: 50 });
  const [reminderType, setReminderType] = useState<'upcoming' | 'overdue' | 'done'>('upcoming');
  const [visible, setVisible] = useState<boolean>(false);
  const { initialState, setInitialState } = useModel('@@initialState');
  const { currentUser = {} as CurrentUser } = initialState || {};

  useEffect(() => {
    if (visible) {
      onFetchGetNotifications();
    }
  }, [visible]);

  const onFetchGetReminders = () => {
    fetchGetReminders({ type: reminderType, perpage: 50 });
  };

  useEffect(() => {
    if (visible) {
      onFetchGetReminders();
    }
  }, [visible, reminderType]);

  const isShowReminderShowMore = useMemo(() => {
    if (!reminders?.meta?.total) return false;
    if (reminders?.meta?.pages <= reminders?.meta?.page) return false;
    return true;
  }, [reminders]);

  const getEmptyText = useMemo(() => {
    switch (reminderType) {
      case 'done':
        return (
          <>
            Your reminder&apos;s done list is currently empty. This means you haven&apos;t completed
            any tasks yet. To populate this list, keep adding tasks and marking them as done once
            you&apos;ve completed them.
          </>
        );
      case 'overdue':
        return (
          <>
            Your reminder list does not have any overdue tasks at the moment. To keep it this way,
            make sure to complete your tasks before their deadlines.
          </>
        );
      case 'upcoming':
        return (
          <>
            Your reminder list is empty. Consider adding tasks from one of your{' '}
            <Link to="/inbox/list">chatrooms</Link> to stay organized.
          </>
        );
    }
  }, [reminderType]);

  return (
    <>
      <NoticeIcon
        className={styles.action}
        count={currentUser?.unread_notification || 0}
        onItemClick={(item) => {
          onClickNotification(item);
          setVisible(false);
        }}
        loading={false}
        viewMoreText="View More"
        onViewMore={() => message.info('Click on view more')}
        visible={visible}
        onPopupVisibleChange={async (_visible) => {
          setVisible(_visible);
          if (currentUser?.unread_notification > 0 && _visible === true) {
            await fetchMarkNotificationAsRead();
            setInitialState((prev) => {
              return {
                ...prev,
                currentUser: {
                  ...prev?.currentUser,
                  unread_notification: 0,
                },
              };
            });
          }
        }}
        onTabChange={(e) => {
          if (e === 'notification') {
            onFetchGetNotifications();
          } else if (e === 'reminder') {
            onFetchGetReminders();
          }
        }}
      >
        <NoticeIcon.Tab
          tabKey="notification"
          title="Notifications"
          count={currentUser?.unread_notification || 0}
          list={notifications?.data || []}
          emptyText="You have read all the Notifications"
          showViewMore={false}
          isLoading={isLoadingFetchGetNotifications}
        />

        <TabPane tab="Reminders" key="reminder">
          <div className={styles.reminderTypes}>
            <Segmented
              size="small"
              options={[
                { label: 'Upcoming', value: 'upcoming' },
                { label: 'Overdue', value: 'overdue' },
                { label: 'Done', value: 'done' },
              ]}
              onChange={(value) => {
                setReminderType(value as 'upcoming' | 'overdue' | 'done');
              }}
            />
          </div>
          <ReminderList
            viewMoreText="View More"
            list={reminders?.data || []}
            // onClick={(item): void => onItemClick && onItemClick(item, child.props)}
            onViewMore={(event): void => console.log(event)}
            showViewMore={isShowReminderShowMore}
            canEdit={reminderType !== 'done'}
            canMarkAsDone={reminderType !== 'done'}
            isLoading={isLoadingFetchGetReminders}
            setVisible={setVisible}
            onSuccessMarkReminderAsDone={() => {
              onFetchGetReminders();
            }}
            onSuccessDeleteReminder={() => {
              onFetchGetReminders();
            }}
            emptyText={getEmptyText}
          />
        </TabPane>
      </NoticeIcon>
      <Modal
        title="Reminder"
        open={isReminderFormOpen}
        onCancel={() => {
          setVisible(true);
          closeReminderFormModal();
          setCurrentReminder(null);
        }}
        destroyOnClose
        width={450}
        footer={false}
      >
        <ReminderForm
          id={currentReminder?.id}
          wa_contact_id={currentReminder?.wa_contact_id}
          onSuccess={() => {
            setVisible(true);
            closeReminderFormModal();
            setCurrentReminder(null);
          }}
        />
      </Modal>
    </>
  );
};

export default NoticeIconView;
