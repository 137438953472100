// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getMessageReport(
  body: API.GetMessageReportParams,
  options?: { [key: string]: any },
) {
  return request<API.GetMessageReportResult>(
    `/users/reports/channel-message-report/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getUserReport(
  body: API.GetUserReportParams,
  options?: { [key: string]: any },
) {
  return request<API.GetUserReportResult>(
    `/users/reports/channel-new-user-report/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getUserTimeAnalysisReport(
  body: API.GetUserTimeAnalysisReportParams,
  options?: { [key: string]: any },
) {
  return request<API.GetUserTimeAnalysisReportResult>(
    `/users/reports/channel-new-user-time-report/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getOpenCloseReport(
  body: API.GetOpenCloseReportParams,
  options?: { [key: string]: any },
) {
  return request<API.GetOpenCloseReportResult>(
    `/users/reports/channel-open-close-conversation-report/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getConversationDurationReport(
  body: API.GetConversationDurationParams,
  options?: { [key: string]: any },
) {
  return request<API.GetConversationDurationResult>(
    `/users/reports/channel-conversation-duration-report/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getChannelTagWonReport(
  body: API.GetChannelTagWonParams,
  options?: { [key: string]: any },
) {
  return request<API.GetChannelTagWonResult>(
    `/users/reports/channel-tag-won-contacts/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getChannelTagReport(
  body: API.GetChannelTagParams,
  options?: { [key: string]: any },
) {
  return request<API.GetChannelTagResult>(
    `/users/reports/channel-tag-report/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getChannelAccumulativeTagReport(
  body: API.GetChannelAccumulativeTagParams,
  options?: { [key: string]: any },
) {
  return request<API.GetChannelAccumulativeTagResult>(
    `/users/reports/channel-accumulative-tag-report/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getChannelTagChangesReport(
  body: API.GetChannelTagChangesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetChannelTagChangesResult>(
    `/users/reports/channel-tag-changes-report/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}
export async function getChannelTagChangesList(
  body: API.GetChannelTagChangesListParams,
  options?: { [key: string]: any },
) {
  return request<API.GetChannelTagChangesListResult>(
    `/users/reports/channel-tag-changes-list/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getChannelUserReport(
  body: API.GetChannelUserParams,
  options?: { [key: string]: any },
) {
  return request<API.GetChannelUserResult>(
    `/users/reports/channel-user-report/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getConversationReport(
  body: API.GetConversationParams,
  options?: { [key: string]: any },
) {
  return request<API.GetConversationResult>(
    `/users/channels/${localStorage.getItem('x-channel-id')}/conversations`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getMessageStatistic(
  body: API.GetMessageStatisticParams,
  options?: { [key: string]: any },
) {
  return request<API.GetMessageStatisticResult>(
    `/users/reports/channel-message-statistic/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getConversationStatistic(
  body: API.GetConversationStatisticParams,
  options?: { [key: string]: any },
) {
  return request<API.GetConversationStatisticResult>(
    `/users/reports/channel-conversation-statistic/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}

export async function getTagStatistic(
  body: API.GetTagStatisticParams,
  options?: { [key: string]: any },
) {
  return request<API.GetTagStatisticResult>(
    `/users/reports/channel-tag-statistic/${localStorage.getItem('x-channel-id')}.json`,
    {
      method: 'GET',
      data: body,
      ...(options || {}),
    },
  );
}