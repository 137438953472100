import { getWebhookVendor } from '@/services/luluchat/webhooksVendors';
import type { ButtonProps } from 'antd';
import { Button, Dropdown, Tooltip } from 'antd';
import type { DropdownProps } from 'antd/es/dropdown';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useModel } from 'umi';
import styles from './style.less';
import { BulbOutlined } from '@ant-design/icons';

interface IAIAssistantActions {
  contentText: string;
  buttonProps?: ButtonProps;
  buttonIconProps?: any;
  dropdownProps?: DropdownProps;
  onReplaceContent?: (text: string) => void;
}

export default ({
  contentText,
  buttonProps,
  dropdownProps,
  onReplaceContent,
}: IAIAssistantActions) => {
  const {
    onMakeLonger,
    onMakeShorter,
    onFixSpelling,
    onChangeTone,
    onImproveWriting,
    onTranslate,
    aiAsistantRef,
  } = useModel('aiAssistant');

  useEffect(() => {
    const run = async () => {
      const res = await getWebhookVendor({
        vendor: 'chatgpt',
      });
      if (res?.status && res?.data?.is_active) {
        Cookies.set('app.chatgpt.api-key', res?.data?.settings?.api_key);
      }
    };
    if (!Cookies.get('app.chatgpt.api-key')) {
      run();
    }
  }, []);

  useEffect(() => {
    if (aiAsistantRef?.current && typeof onReplaceContent === 'function') {
      aiAsistantRef.current.runReplaceContent = onReplaceContent;
    }
  }, [aiAsistantRef]);

  return Cookies.get('app.chatgpt.api-key') ? (
    <>
      <Tooltip title="AI Assistant">
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                type: 'group',
                label: 'Edit or review selection',
                children: [
                  {
                    key: '1-1',
                    label: (
                      <Button
                        block
                        size="small"
                        type="text"
                        className={styles.menuItem}
                        onClick={() => {
                          onImproveWriting({ content: contentText });
                        }}
                      >
                        Improve writing
                      </Button>
                    ),
                  },
                  {
                    key: '1-2',
                    label: (
                      <Button
                        block
                        size="small"
                        type="text"
                        className={styles.menuItem}
                        onClick={() => {
                          onFixSpelling({ content: contentText });
                        }}
                      >
                        Fix spelling & grammer
                      </Button>
                    ),
                  },
                  {
                    key: '1-3',
                    label: (
                      <Button
                        block
                        size="small"
                        type="text"
                        className={styles.menuItem}
                        onClick={() => {
                          onMakeShorter({ content: contentText });
                        }}
                      >
                        Make shorter
                      </Button>
                    ),
                  },
                  {
                    key: '1-4',
                    label: (
                      <Button
                        block
                        size="small"
                        type="text"
                        className={styles.menuItem}
                        onClick={() => {
                          onMakeLonger({ content: contentText });
                        }}
                      >
                        Make longer
                      </Button>
                    ),
                  },
                  {
                    key: '1-5',
                    label: (
                      <Button size="small" type="text">
                        Change tone
                      </Button>
                    ),
                    children: [
                      {
                        key: '1-5-1',
                        label: (
                          <Button
                            block
                            size="small"
                            type="text"
                            className={styles.menuItem}
                            onClick={() =>
                              onChangeTone({ content: contentText, tone: 'professional' })
                            }
                          >
                            Professional
                          </Button>
                        ),
                      },
                      {
                        key: '1-5-2',
                        label: (
                          <Button
                            block
                            size="small"
                            type="text"
                            className={styles.menuItem}
                            onClick={() => onChangeTone({ content: contentText, tone: 'casual' })}
                          >
                            Casual
                          </Button>
                        ),
                      },
                      {
                        key: '1-5-3',
                        label: (
                          <Button
                            block
                            size="small"
                            type="text"
                            className={styles.menuItem}
                            onClick={() =>
                              onChangeTone({ content: contentText, tone: 'straightforward' })
                            }
                          >
                            Straightforward
                          </Button>
                        ),
                      },
                      {
                        key: '1-5-4',
                        label: (
                          <Button
                            block
                            size="small"
                            type="text"
                            className={styles.menuItem}
                            onClick={() =>
                              onChangeTone({ content: contentText, tone: 'confident' })
                            }
                          >
                            Confident
                          </Button>
                        ),
                      },
                      {
                        key: '1-5-5',
                        label: (
                          <Button
                            block
                            size="small"
                            type="text"
                            className={styles.menuItem}
                            onClick={() => onChangeTone({ content: contentText, tone: 'friendly' })}
                          >
                            Friendly
                          </Button>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                key: '3',
                type: 'group',
                label: 'Generate from selection',
                children: [
                  {
                    key: '3-1',
                    label: (
                      <Button size="small" type="text">
                        Translate
                      </Button>
                    ),
                    children: [
                      {
                        key: '3-1-1',
                        label: (
                          <Button
                            block
                            size="small"
                            type="text"
                            className={styles.menuItem}
                            onClick={() =>
                              onTranslate({ content: contentText, language: 'english' })
                            }
                          >
                            English
                          </Button>
                        ),
                      },
                      {
                        key: '3-1-2',
                        label: (
                          <Button
                            block
                            size="small"
                            type="text"
                            className={styles.menuItem}
                            onClick={() => onTranslate({ content: contentText, language: 'malay' })}
                          >
                            Malay
                          </Button>
                        ),
                      },
                      {
                        key: '3-1-3',
                        label: (
                          <Button
                            block
                            size="small"
                            type="text"
                            className={styles.menuItem}
                            onClick={() =>
                              onTranslate({ content: contentText, language: 'chinese' })
                            }
                          >
                            Chinese
                          </Button>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          }}
          trigger={['click']}
          {...dropdownProps}
          overlayClassName={[styles.dropdown, dropdownProps?.overlayClassName || ''].join(' ')}
        >
          <Button
            type="text"
            icon={<BulbOutlined />}
            {...buttonProps}
          />
        </Dropdown>
      </Tooltip>
    </>
  ) : null;
};
