/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */

import type { CurrentUser } from '@/declarations/user';
export default function access(initialState: { currentUser?: CurrentUser | undefined }) {
  const { currentUser, currentChannel } = initialState || {};
  const modules = currentUser?.modules || [];
  const hasWABAAccess = currentChannel?.type === 'waba';
  const planName = currentUser?.current_team?.limit?.billing?.plan?.name;
  const isProPlan = planName === "Business" || planName === "Pro" || planName === "Free Trial";

  return {
    isProPlan,
    hasWABAAccess,
    hasLoyalty: true,
    hasInbox: modules.includes('inbox'),
    hasInboxDefault: modules.includes('inbox:default'),
    hasInboxAssignee: modules.includes('inbox:assignee'),
    hasAutomationFlow: modules.includes('automation_flow'),
    hasAutomationKeyword: modules.includes('automation_keyword'),
    hasGrowthTool: modules.includes('growth_tool'),
    hasWebWidget: modules.includes('web_widget'),
    hasBroadcast: modules.includes('broadcast') && currentUser?.current_team?.limit?.has_broadcast,
    hasForm: modules.includes('form') && currentUser?.current_team?.limit?.has_form,
    hasContact: modules.includes('contact'),
    hasExportContact: modules.includes('contact:export'),
    hasUpdateContact: modules.includes('contact:update'),
    hasTag: modules.includes('tag'),
    hasCustomAttribute: modules.includes('custom_attribute'),
    hasReport: modules.includes('report'),
    hasBooking: modules.includes('booking'),
    hasSetting: modules.includes('setting'),
    hasBilling: modules.includes('billing'),
    hasApp: modules.includes('app_integration') && currentUser?.current_team?.limit?.has_app,
    hasWorkflow: modules.includes('workflow'),
    hasFileStorage: currentUser?.current_team?.limit?.has_file_storage
  };
}