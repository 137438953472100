// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getForms(body?: API.GetFormsParams, options?: { [key: string]: any }) {
  return request<API.GetFormsResult>(`/users/forms`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createForm(body: API.CreateFormParams, options?: { [key: string]: any }) {
  return request<API.CreateFormResult>(`/users/forms`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function getForm(
  { id, ...body }: API.GetFormParams,
  options?: { [key: string]: any },
) {
  return request<API.GetFormResult>(`/users/forms/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function deleteForm(
  { id }: API.DeleteFormParams,
  options?: { [key: string]: any }
) {
  return request<API.DeleteFormResult>(`/users/forms/${id}`, {
    method: "DELETE",
    ...(options || {}),
  });
}

export async function duplicateForm(
  { id }: API.DuplicateFormParams,
  options?: { [key: string]: any }
) {
  return request<API.DuplicateFormResult>(`/users/forms/${id}/duplicate`, {
    method: "POST",
    ...(options || {}),
  });
}


export async function updateForm(
  { id, ...body }: API.UpdateFormParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateFormResult>(`/users/forms/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function getFormResponses(
  { id, ...body }: API.GetFormResponsesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetFormResponsesResult>(`/users/forms/${id}/responses`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function deleteFormResponses(
  { id, ids }: API.DeleteFormResponsesParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteFormResponsesResult>(`/users/forms/${id}/responses`, {
    method: 'DELETE',
    data: { ids },
    ...(options || {}),
  });
}

export async function bulkChangeResponseStatuses(
  { id, ...body }: API.BulkChangeResponseStatusesParams,
  options?: { [key: string]: any },
) {
  return request<API.BulkChangeResponseStatusesResult>(
    `/users/forms/${id}/responses/bulk-change-status`,
    {
      method: 'POST',
      data: body,
      ...(options || {}),
    },
  );
}
