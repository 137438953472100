import type { Tag } from '@/declarations/tag';
import { createTag, deleteTag, getTag, getTags, updateTag } from '@/services/luluchat/tags';
import { useRequest } from 'umi';
export default () => {
  const {
    data: tags = [] as Tag[],
    run: fetchGetTags,
    loading: isLoadingFetchGetTags,
  } = useRequest(getTags, { manual: true });

  const { run: fetchCreateTag, loading: isLoadingFetchCreateTag } = useRequest(createTag, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const {
    data: tag = {} as Tag,
    run: fetchGetTag,
    loading: isLoadingFetchGetTag,
  } = useRequest(getTag, {
    manual: true,
  });

  const { run: fetchUpdateTag, loading: isLoadingFetchUpdateTag } = useRequest(updateTag, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchDeleteTag, loading: isLoadingFetchDeleteTag } = useRequest(deleteTag, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  return {
    tags,
    fetchGetTags,
    isLoadingFetchGetTags,
    fetchCreateTag,
    isLoadingFetchCreateTag,
    tag,
    fetchGetTag,
    isLoadingFetchGetTag,
    fetchUpdateTag,
    isLoadingFetchUpdateTag,
    fetchDeleteTag,
    isLoadingFetchDeleteTag,
  };
};
