import type { CustomAttribute } from '@/declarations/customAttribute';
import {
  createCustomAttribute,
  deleteCustomAttribute,
  getCustomAttribute,
  getCustomAttributes,
  updateCustomAttribute,
} from '@/services/luluchat/customAttributes';
import { useRequest } from 'umi';
export default () => {
  const {
    data: customAttributes = [] as CustomAttribute[],
    run: fetchGetCustomAttributes,
    loading: isLoadingFetchGetCustomAttributes,
  } = useRequest(getCustomAttributes, { manual: true });

  const { run: fetchCreateCustomAttribute, loading: isLoadingFetchCreateCustomAttribute } =
    useRequest(createCustomAttribute, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const {
    data: customAttribute = {} as CustomAttribute,
    run: fetchGetCustomAttribute,
    loading: isLoadingFetchGetCustomAttribute,
  } = useRequest(getCustomAttribute, {
    manual: true,
  });

  const { run: fetchUpdateCustomAttribute, loading: isLoadingFetchUpdateCustomAttribute } =
    useRequest(updateCustomAttribute, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const { run: fetchDeleteCustomAttribute, loading: isLoadingFetchDeleteCustomAttribute } =
    useRequest(deleteCustomAttribute, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  return {
    customAttributes,
    fetchGetCustomAttributes,
    isLoadingFetchGetCustomAttributes,
    fetchCreateCustomAttribute,
    isLoadingFetchCreateCustomAttribute,
    customAttribute,
    fetchGetCustomAttribute,
    isLoadingFetchGetCustomAttribute,
    fetchUpdateCustomAttribute,
    isLoadingFetchUpdateCustomAttribute,
    fetchDeleteCustomAttribute,
    isLoadingFetchDeleteCustomAttribute,
  };
};
