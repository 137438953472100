// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getFlowTemplateCategories(
  body?: API.GetFlowTemplateCategoriesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetFlowTemplateCategoriesResult>(`/users/categories`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function getFlowTemplateCategory(
  { id, ...body }: API.GetFlowTemplateCategoryParams,
  options?: { [key: string]: any },
) {
  return request<API.GetFlowTemplateCategoryResult>(`/users/categories/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}