import type { IVendor, WebhookVendor } from '@/declarations/webhookVendor';
import {
  disableVendor,
  enableVendor,
  getWebhookVendor,
  getWebhookVendors,
  updateVendorEventSetting,
} from '@/services/luluchat/webhooksVendors';
import { useRequest } from 'umi';
export default () => {
  const {
    data: webhookVendors = [] as IVendor[],
    run: fetchGetWebhookVendors,
    loading: isLoadingFetchGetWebhookVendors,
  } = useRequest(getWebhookVendors, { manual: true });

  const {
    data: webhookVendor = {} as WebhookVendor,
    run: fetchGetWebhookVendor,
    loading: isLoadingFetchGetWebhookVendor,
  } = useRequest(getWebhookVendor, {
    manual: true,
  });

  const { run: fetchUpdateVendorEventSetting, loading: isLoadingFetchUpdateVendorEventSetting } =
    useRequest(updateVendorEventSetting, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const { run: fetchEnableVendor, loading: isLoadingFetchEnableVendor } = useRequest(enableVendor, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchDisableVendor, loading: isLoadingFetchDisableVendor } = useRequest(
    disableVendor,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  return {
    webhookVendors,
    fetchGetWebhookVendors,
    isLoadingFetchGetWebhookVendors,
    webhookVendor,
    fetchGetWebhookVendor,
    isLoadingFetchGetWebhookVendor,
    fetchUpdateVendorEventSetting,
    isLoadingFetchUpdateVendorEventSetting,
    fetchEnableVendor,
    isLoadingFetchEnableVendor,
    fetchDisableVendor,
    isLoadingFetchDisableVendor,
  };
};
