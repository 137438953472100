import { ContentFactory } from '@/factories/ContentFactory';
import type { StepNode, StepType } from '@/models/diagram.d';
import { v4 as uuidv4 } from 'uuid';

export const StepFactory = ({
  type,
  position,
  initialValues,
}: {
  type: StepType;
  position?: { x: number; y: number };
  initialValues?: any;
}) => {
  const id = uuidv4();
  const step: StepNode = {
    id, // same id for diagram to render
    type, // same type for diagram to render
    position: { x: position?.x || 100, y: position?.y || 150 },
    data: {
      id, // same id for backend to keep track with
      type, // same type for backend to keep track with
      title: '',
      contents: [],
    },
  };
  switch (type) {
    case 'action':
      step.data.title = 'Action';
      break;
    case 'template':
      step.data.title = 'Send Message Template';
      step.data.contents = [ContentFactory({ type: 'template' })];
      break;
    case 'message':
      step.data.title = 'Send Message';
      step.data.contents = [ContentFactory({ type: 'text' })];
      break;
    case 'start-flow':
      step.data.title = 'Starting Step';
      break;
    case 'starting':
      step.data.title = 'Starting Step';
      break;
    case 'round-robin':
      step.data.title = 'Round Robin';
      step.data.contents = [ContentFactory({ type: 'round-robin-steps' })];
      break;
    case 'smart-delay':
      step.data.title = 'Smart Delay';
      step.data.contents = [ContentFactory({ type: 'smart-delay' })];
      break;
    case 'condition':
      step.data.title = 'Condition';
      step.data.contents = [ContentFactory({ type: 'condition' })];
      break;
    case 'randomizer':
      step.data.title = 'Randomizer';
      step.data.contents = [ContentFactory({ type: 'randomizer' })];
      break;
    default:
      break;
  }
  step.data = {
    ...step.data,
    ...initialValues,
  };
  return step;
};
