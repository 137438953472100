import type { Contact, ContactActiveFlow, ContactInfo } from '@/declarations/contact';
import {
  assignAttributes,
  createContact,
  getContact,
  getContactActiveFlow,
  getContactInfo,
  getContacts,
  stopFlow,
  updateContact,
} from '@/services/luluchat/contacts';
import { useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const [contact, setContact] = useState<Contact>({} as Contact);
  const {
    data: contacts,
    run: fetchGetContacts,
    loading: isLoadingFetchGetContacts,
  } = useRequest(getContacts, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchGetContact, loading: isLoadingFetchGetContact } = useRequest(getContact, {
    manual: true,
    formatResult(res) {
      setContact(res?.data);
      return res?.data;
    },
  });

  const { run: fetchCreateContact, loading: isLoadingFetchCreateContact } = useRequest(
    createContact,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateContact, loading: isLoadingFetchUpdateContact } = useRequest(
    updateContact,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const {
    data: contactActiveFlow = {} as ContactActiveFlow,
    run: fetchGetContactActiveFlow,
    loading: isLoadingFetchGetContactActiveFlow,
  } = useRequest(getContactActiveFlow, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const {
    data: contactInfo = {} as ContactInfo,
    run: fetchGetContactInfo,
    loading: isLoadingFetchGetContactInfo,
  } = useRequest(getContactInfo, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchAssignAttributes, loading: isLoadingFetchAssignAttributes } = useRequest(
    assignAttributes,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchStopFlow, loading: isLoadingFetchStopFlow } = useRequest(stopFlow, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  return {
    contactInfo,
    fetchGetContactInfo,
    isLoadingFetchGetContactInfo,
    contact,
    fetchGetContact,
    isLoadingFetchGetContact,
    contacts,
    fetchGetContacts,
    isLoadingFetchGetContacts,
    fetchCreateContact,
    isLoadingFetchCreateContact,
    fetchUpdateContact,
    isLoadingFetchUpdateContact,
    fetchAssignAttributes,
    isLoadingFetchAssignAttributes,
    setContact,
    contactActiveFlow,
    fetchGetContactActiveFlow,
    isLoadingFetchGetContactActiveFlow,
    fetchStopFlow,
    isLoadingFetchStopFlow,
  };
};
