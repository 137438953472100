// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getReminders(
  { type, ...body }: API.GetRemindersParams,
  options?: { [key: string]: any },
) {
  return request<API.GetRemindersResult>(`/users/reminders/${type}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createReminder(
  body: API.CreateReminderParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateReminderResult>(`/users/reminders`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function getReminder(
  { id, ...body }: API.GetReminderParams,
  options?: { [key: string]: any },
) {
  return request<API.GetReminderResult>(`/users/reminders/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function updateReminder(
  { id, ...body }: API.UpdateReminderParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateReminderResult>(`/users/reminders/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function deleteReminder(
  { id }: API.DeleteReminderParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteReminderResult>(`/users/reminders/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}

export async function markReminderAsDone(
  body: API.MarkReminderAsDoneParams,
  options?: { [key: string]: any },
) {
  return request<API.MarkReminderAsDoneResult>(`/users/reminders/mark-done`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}
