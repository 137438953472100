import SelectedButtonType from '@/components/SelectedButtonType';
import SelectStepsModal from '@/components/SelectStepsModal';
import useModal from '@/hooks/useModal';
import type { IMessageButton, StepNode } from '@/models/diagram.d';
import { ButtonTypes } from '@/models/diagram.d';
import { Button, Form, Input, Space, Typography } from 'antd';
import _get from 'lodash/get';
import { useEffect, useMemo, useState } from 'react';
import { useModel } from 'umi';
import styles from './index.less';

export const buttonTypes: {
  label: string;
  type: ButtonTypes;
  image: string;
  description: string;
}[] = [
  {
    label: 'Send a Message',
    type: ButtonTypes.MESSAGE,
    image: '/images/svg/whatsapp-2.svg',
    description: '',
  },
  {
    label: 'Start Flow',
    type: ButtonTypes.START_FLOW,
    image: '/images/img/flow.png',
    description: '',
  },
  {
    label: 'Open Website',
    type: ButtonTypes.WEBSITE,
    image: '/images/svg/website.svg',
    description: 'Enter url below',
  },
  {
    label: 'Call Number',
    type: ButtonTypes.CALL_OUT,
    image: '/images/svg/call.svg',
    description: 'Enter number below',
  },
  {
    label: 'Perform Actions',
    type: ButtonTypes.ACTION,
    image: '/images/svg/action.svg',
    description: '',
  },
  {
    label: 'Select Existing Step',
    type: ButtonTypes.GO_TO_STEP,
    image: '/images/svg/select-step.svg',
    description: '',
  },
];

export const getLabelByType = (type: ButtonTypes) => {
  const res = buttonTypes.filter((button) => button.type === type);
  if (res.length > 0) {
    return res[0].label;
  }
  return;
};

export const getIconByType = (type: ButtonTypes) => {
  const res = buttonTypes.filter((button) => button.type === type);
  if (res.length > 0) {
    return res[0].image;
  }
  return;
};

export default ({
  canRemove = true,
  contentId,
  button,
  type = 'button',
  canEditWebsiteAddress = true,
  title,
}: {
  canRemove?: boolean;
  contentId: string;
  button: IMessageButton;
  type?: 'button' | 'step';
  canEditWebsiteAddress?: boolean;
  title?: string
}) => {
  const {
    flow,
    steps,
    edges,
    onUpdateContentButton,
    onUpdateContentSteps,
    onDeleteLinkBySourceHandle,
    onAddMessageAndLinkToIt,
    onAddStartFlowAndLinkToIt,
    onAddActionAndLinkToIt,
    onShowStep,
    onConnectToStep,
    getLinkedTargetBySourceHandle,
  } = useModel('diagram');
  const [connectedStep, setConnectedStep] = useState<StepNode | null>(null);

  const { isModalVisible, openModal, closeModal } = useModal();

  const buttonType = useMemo(() => {
    const target = getLinkedTargetBySourceHandle(button?.id);
    return target?.type;
  }, [button]);

  useEffect(() => {
    if (
      buttonType === ButtonTypes.GO_TO_STEP ||
      buttonType === ButtonTypes.MESSAGE ||
      buttonType === ButtonTypes.START_FLOW ||
      buttonType === ButtonTypes.ACTION
    ) {
      const foundEdge = edges.find((edge) => edge.sourceHandle === button.id);
      if (foundEdge && steps.length > 0) {
        const res = steps.find(({ id }) => id === foundEdge.target);
        if (res) {
          setConnectedStep(res);
        }
      } else {
        setConnectedStep(null);
      }
    }
  }, [buttonType, edges, steps]);

  const onUpdateContent = (params: { contentId: string; button: any }) => {
    if (type === 'step') {
      onUpdateContentSteps({
        step: params?.button,
        contentId: params?.contentId,
      });
    } else {
      onUpdateContentButton(params);
    }
  };

  const onRemoveButtonType = () => {
    const newButton = { ...button, type: '' };
    onDeleteLinkBySourceHandle(button.id);
    onUpdateContent({ contentId, button: newButton });
  };

  const onSelectContentButtonType = (_type: ButtonTypes) => {
    const _buttonType =
      _type === ButtonTypes.MESSAGE || _type === ButtonTypes.ACTION
        ? ButtonTypes.GO_TO_STEP
        : _type;
    const newButton = { ...button, type: _buttonType };
    switch (_type) {
      case ButtonTypes.GO_TO_STEP:
        openModal();
        break;
      case ButtonTypes.START_FLOW:
        onUpdateContent({ contentId, button: newButton });
        onAddStartFlowAndLinkToIt({ buttonId: button.id, title });
        break;
      case ButtonTypes.MESSAGE:
        onUpdateContent({ contentId, button: newButton });
        onAddMessageAndLinkToIt({ buttonId: button.id, title });
        break;
      case ButtonTypes.ACTION:
        onUpdateContent({ contentId, button: newButton });
        onAddActionAndLinkToIt({ buttonId: button.id, title });
        break;
      default:
        onUpdateContent({ contentId, button: newButton });
        break;
    }
  };

  const onChangeContentButtonPhoneNo = (e: any) => {
    if (buttonType !== ButtonTypes.CALL_OUT) return;
    const newButton = { ...button, phone_no: e.target.value };
    onUpdateContent({ contentId, button: newButton });
  };

  const onChangeContentButtonUrl = (e: any) => {
    if (buttonType !== ButtonTypes.WEBSITE) return;
    const newButton = { ...button, url: e.target.value };
    onUpdateContent({ contentId, button: newButton });
  };

  const getSelectedButtonContent = (): string => {
    if (
      buttonType === ButtonTypes.GO_TO_STEP ||
      buttonType === ButtonTypes.MESSAGE ||
      buttonType === ButtonTypes.START_FLOW ||
      buttonType === ButtonTypes.ACTION
    ) {
      if (connectedStep) {
        return _get(connectedStep, 'data.title', '');
      }
      return buttonType === ButtonTypes.GO_TO_STEP ? 'Click button below to choose Step' : '';
    } else {
      const res = buttonTypes.filter((data) => data.type === buttonType);
      return res.length > 0 ? _get(res, '0.description', '') : '';
    }
  };

  const getSelectedButtonTitle = (): string => {
    const res = buttonTypes.filter((data) => data.type === buttonType);
    if (buttonType === ButtonTypes.GO_TO_STEP) {
      if (connectedStep) {
        switch (connectedStep.type) {
          case 'start-flow':
            return 'Start Flow';
          case 'message':
            return 'Message';
          case 'action':
            return 'Perform Actions';
          default:
            break;
        }
      }
    }
    return res.length > 0 ? _get(res, '0.label', '') : '';
  };

  const getSelectedButtonImage = (): string => {
    const res = buttonTypes.filter((data) => data.type === buttonType);
    return res.length > 0 ? _get(res, '0.image', '') : '';
  };

  const connectButtonToStep = (selectedStepId: string) => {
    const newButton = { ...button, type: ButtonTypes.GO_TO_STEP };
    onConnectToStep({ contentId, buttonId: button.id, target: selectedStepId });
    onUpdateContent({ contentId, button: newButton });
    closeModal();
  };

  return (
    <div>
      {buttonType ? (
        <SelectedButtonType
          canRemove={canRemove}
          title={getSelectedButtonTitle()}
          image={getSelectedButtonImage()}
          content={getSelectedButtonContent()}
          onRemoveButtonType={onRemoveButtonType}
          onClick={() => {
            if (connectedStep) {
              onShowStep(connectedStep);
            }
          }}
          clickable={!!connectedStep}
        />
      ) : (
        <Space direction="vertical" style={{ width: '100%' }}>
          {buttonTypes.map((_button, i: number) => {
            if (
              (flow?.type === 'default' && _button.type !== ButtonTypes.START_FLOW) ||
              _button.type === ButtonTypes.WEBSITE ||
              _button.type === ButtonTypes.CALL_OUT
            )
              return;
            return (
              <Button
                onClick={onSelectContentButtonType.bind(this, _button.type)}
                type="dashed"
                block
                key={i}
                icon={
                  <div className={styles.buttonIconWrapper}>
                    <img src={_button.image} height="20" className={styles.buttonIcon} />
                  </div>
                }
                className={styles.buttonType}
              >
                {_button.label}
              </Button>
            );
          })}
        </Space>
      )}
      <br />
      {buttonType === ButtonTypes.CALL_OUT ? (
        <Form.Item>
          <Typography>Call Phone No</Typography>
          <Input defaultValue={button.phone_no} onBlur={onChangeContentButtonPhoneNo} />
          <Typography.Text type="secondary">
            <small>ex: 601245678910</small>
          </Typography.Text>
        </Form.Item>
      ) : null}
      {buttonType === ButtonTypes.WEBSITE ? (
        <Form.Item>
          <Typography>Website Address</Typography>
          <Input
            defaultValue={button.url}
            onBlur={onChangeContentButtonUrl}
            disabled={!canEditWebsiteAddress}
          />

          {canEditWebsiteAddress ? (
            <>
              <Typography.Text type="secondary">
                <small>ex: https://www.example.com</small>
              </Typography.Text>
            </>
          ) : (
            <Typography.Text type="secondary">
              Please edit Website Address in Message Template
            </Typography.Text>
          )}
        </Form.Item>
      ) : null}
      {buttonType === ButtonTypes.GO_TO_STEP && !connectedStep ? (
        <Form.Item>
          <Button block type="dashed" onClick={openModal}>
            Select a Step
          </Button>
        </Form.Item>
      ) : null}
      <SelectStepsModal
        isModalVisible={isModalVisible}
        onCancel={closeModal}
        onSelectStep={connectButtonToStep}
      />
    </div>
  );
};
