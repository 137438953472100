import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Menu, message, Radio, Space, Spin } from 'antd';
import React, { useCallback } from 'react';
import { history, useAccess, useModel } from 'umi';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
// import { outLogin } from '@/services/ant-design-pro/api';
import { db } from '@/services/indexedDB/db';
import Cookies from 'js-cookie';
import type { MenuInfo } from 'rc-menu/lib/interface';
export type GlobalHeaderRightProps = {
  menu?: boolean;
};

/**
 * 退出登录，并且将当前的 url 保存
 */
export const loginOut = async () => {
  // clear indexeddb messages.
  try {
    await db.messages.clear().catch((e) => {
      console.error('Error: ' + (e?.stack || e));
    });
  } catch (error) {
    console.error(error);
  }
  try {
    await db.avatars.clear().catch((e) => {
      console.error('Error: ' + (e?.stack || e));
    });
  } catch (error) {
    console.error(error);
  }
  Cookies.remove('access_token');
  Cookies.remove('app.chatgpt.api-key');
  Cookies.remove('fb_access_token');
  // await outLogin();
  const { query = {}, pathname } = history.location;
  const { redirect } = query;
  localStorage.removeItem('x-team-id');
  // Note: There may be security issues, please note
  if (window.location.pathname !== '/user/login' && !redirect) {
    localStorage.removeItem('x-channel-id');
    window.location.href = `/user/login?redirect=${pathname}`;
  }
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu }) => {
  const access = useAccess();
  const { initialState, setInitialState } = useModel('@@initialState');

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      if (key === 'logout') {
        setInitialState((s) => {
          return { ...s, currentUser: undefined };
        });
        loginOut();
        return;
      }
      if (key === 'personal' || key === 'company' || key === 'billing') {
        history.push(`/settings?view=${key}`);
        return;
      }
    },
    [setInitialState],
  );

  const { currentUser } = initialState || {};

  const onChangeTeam = async (uuid: string) => {
    if (localStorage.getItem('x-team-id') === uuid) {
      message.info('You are currently in this team.');
      return;
    }
    Cookies.remove('app.chatgpt.api-key');
    message.success('Switching Team in 3 seconds...');
    try {
      await db.messages.clear().catch((e) => {
        console.error('Error: ' + (e?.stack || e));
      });
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      localStorage.setItem('x-team-id', uuid);
      window.location.reload();
    }, 3000);
  };

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      {!initialState || !currentUser || !currentUser.fullname ? (
        <Menu.Item key="loading">
          <Spin size="small" />
        </Menu.Item>
      ) : (
        <Menu.ItemGroup title={currentUser?.teams?.length === 1 ? 'Current Team' : 'Switch Teams'}>
          {currentUser?.teams && currentUser?.teams.length > 0
            ? currentUser?.teams.map(({ name, uuid }) => {
                return (
                  <Menu.Item key={uuid}>
                    <Radio
                      checked={uuid === localStorage.getItem('x-team-id')}
                      onClick={() => onChangeTeam(uuid)}
                    >
                      {name}
                    </Radio>
                  </Menu.Item>
                );
              })
            : null}
        </Menu.ItemGroup>
      )}
      <Menu.Divider />
      {access.hasSetting && (
        <>
          {menu && (
            <Menu.Item key="personal">
              <UserOutlined />
              Personal Setting
            </Menu.Item>
          )}
          {menu && (
            <Menu.Item key="company">
              <SettingOutlined />
              Company Setting
            </Menu.Item>
          )}
          {menu && (
            <Menu.Item key="billing">
              <SettingOutlined />
              Billing
            </Menu.Item>
          )}
          {menu && <Menu.Divider />}
        </>
      )}
      <Menu.Item key="logout">
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        <Space>
          <span className={styles.fullname}>{currentUser?.fullname || ''}</span>
          <Avatar
            size="small"
            className={styles.avatar}
            src="/images/svg/logo_icon.svg"
            alt="avatar"
          />
        </Space>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
