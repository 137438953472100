import type { ScheduleMessage } from '@/declarations/channel';
import type { Contact } from '@/declarations/contact';
import useModal from '@/hooks/useModal';
import { getUnsentScheduleMessages } from '@/services/luluchat/channels';
import type { API } from '@/services/luluchat/typings';
import { useState } from 'react';

export default () => {
  const {
    isModalVisible: isScheduleMessageModalVisible,
    openModal: openScheduleMessageModal,
    closeModal: closeScheduleMessageModal,
  } = useModal();
  const {
    isModalVisible: isEditScheduleMessage,
    openModal: openEditScheduleMessage,
    closeModal: closeEditScheduleMessage,
  } = useModal();
  const {
    isModalVisible: isScheduleMessagesVisible,
    openModal: openScheduleMessagesModal,
    closeModal: closeScheduleMessagesModal,
  } = useModal();
  const [editScheduleMessage, setEditScheduleMessage] = useState<ScheduleMessage | null>(null);
  const [topPinnedScheduledMessages, setTopPinnedScheduledMessages] = useState<ScheduleMessage[]>(
    [],
  );
  const [searchRecipient, setSearchRecipient] = useState('');

  const onGetScheduledMessages = async (contact: Contact) => {
    let name = '';
    if (contact?.type === 'individual') {
      name = contact?.iso_contact_number;
    } else {
      name = contact?.name;
    }
    if (name) {
      const res: API.GetUnsentScheduleMessagesResult = await getUnsentScheduleMessages({
        page: 1,
        perpage: 30,
        name,
      });
      if (res?.status) {
        setTopPinnedScheduledMessages(res?.data);
      } else {
        setTopPinnedScheduledMessages([]);
      }
    } else {
      setTopPinnedScheduledMessages([]);
    }
  };

  return {
    editScheduleMessage,
    setEditScheduleMessage,
    isScheduleMessageModalVisible,
    openScheduleMessageModal,
    closeScheduleMessageModal,
    isEditScheduleMessage,
    openEditScheduleMessage,
    closeEditScheduleMessage,
    isScheduleMessagesVisible,
    openScheduleMessagesModal,
    closeScheduleMessagesModal,
    searchRecipient,
    setSearchRecipient,
    onGetScheduledMessages,
    topPinnedScheduledMessages,
    setTopPinnedScheduledMessages,
  };
};
