import type { Form } from '@/declarations/form';
import { createForm, getFormResponses, getForm, getForms, updateForm } from '@/services/luluchat/form';
import { useRequest } from 'umi';
export default () => {
  const {
    data: forms = [] as Form[],
    run: fetchGetForms,
    loading: isLoadingFetchGetForms,
  } = useRequest(getForms, { manual: true });

  const { run: fetchCreateForm, loading: isLoadingFetchCreateForm } = useRequest(createForm, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const {
    data: form = {} as Form,
    run: fetchGetForm,
    loading: isLoadingFetchGetForm,
  } = useRequest(getForm, {
    manual: true,
  });

  const { run: fetchUpdateForm, loading: isLoadingFetchUpdateForm } = useRequest(updateForm, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const {
    data: formResponses = [] as any[],
    run: fetchGetFormResponses,
    loading: isLoadingFetchGetFormResponses,
  } = useRequest(getFormResponses, { manual: true });

  return {
    forms,
    fetchGetForms,
    isLoadingFetchGetForms,
    fetchCreateForm,
    isLoadingFetchCreateForm,
    form,
    fetchGetForm,
    isLoadingFetchGetForm,
    fetchUpdateForm,
    isLoadingFetchUpdateForm,
    formResponses,
    fetchGetFormResponses,
    isLoadingFetchGetFormResponses,
  };
};
