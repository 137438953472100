import type { StaffServiceProvider } from '@/declarations/calendar';
import {
  createStaffServiceProvider,
  deleteStaffServiceProvider,
  getStaffServiceProvider,
  getStaffServiceProviders,
  updateStaffServiceProvider,
} from '@/services/luluchat/staff-service-provider';
import { useRequest } from 'umi';
export default () => {
  const {
    data: staffServiceProviders = [] as StaffServiceProvider[],
    run: fetchGetStaffServiceProviders,
    loading: isLoadingFetchGetStaffServiceProviders,
  } = useRequest(getStaffServiceProviders, { manual: true });

  const {
    run: fetchCreateStaffServiceProvider,
    loading: isLoadingFetchCreateStaffServiceProvider,
  } = useRequest(createStaffServiceProvider, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const {
    data: staffServiceProvider = {} as StaffServiceProvider,
    run: fetchGetStaffServiceProvider,
    loading: isLoadingFetchGetStaffServiceProvider,
  } = useRequest(getStaffServiceProvider, {
    manual: true,
  });

  const {
    run: fetchUpdateStaffServiceProvider,
    loading: isLoadingFetchUpdateStaffServiceProvider,
  } = useRequest(updateStaffServiceProvider, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const {
    run: fetchDeleteStaffServiceProvider,
    loading: isLoadingFetchDeleteStaffServiceProvider,
  } = useRequest(deleteStaffServiceProvider, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  return {
    staffServiceProviders,
    fetchGetStaffServiceProviders,
    isLoadingFetchGetStaffServiceProviders,
    fetchCreateStaffServiceProvider,
    isLoadingFetchCreateStaffServiceProvider,
    staffServiceProvider,
    fetchGetStaffServiceProvider,
    isLoadingFetchGetStaffServiceProvider,
    fetchUpdateStaffServiceProvider,
    isLoadingFetchUpdateStaffServiceProvider,
    fetchDeleteStaffServiceProvider,
    isLoadingFetchDeleteStaffServiceProvider,
  };
};
